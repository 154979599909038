import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

// Very specific component we reuse in multiple spots, possibly make it generic or part of a Header/toolbar
const BackToMerchantsButton = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const goHome = useCallback(() => {
        navigate('/giftcards/shop');
    }, []);

    return (
        <Button sx={{ textTransform: 'none', display: 'flex', alignItems: 'center', paddingLeft: 0, marginBottom: '4px' }} onClick={goHome} >
            <Box display="flex" justifyContent="center">
                <ArrowBackIosNewIcon htmlColor="white" fontSize="small" />
                <Typography mt={'1px'} component="span" variant="body2" fontWeight="bold" color={'white'}>{t('giftCards:giftCardsList')}</Typography>
            </Box>
        </Button>
    )
}

export default BackToMerchantsButton;