import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, ListItem, styled, Typography } from '@mui/material';
import PurchasedGiftCardImage from './PurchasedGiftCardImage';
import { currencyFormatter } from '../../../utils/helpers';
import { useStores } from '../../../hooks/use-stores';
import MerchantInfo from '../Confirmation/components/MerchantInfo';

const GiftCardButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    color: "white",
    justifyContent: "center",
    borderRadius: '22px',
    "&:hover": {
        backgroundColor: theme.palette.primary.main,
        filter: 'brightness(.9)'
    },
}));

const CopyToClipboardButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: "white",
    borderRadius: '22px',
    "&:hover": {
        color: "white",
        backgroundColor: theme.palette.primary.main,
    },
}));

interface PurchasedCardInfoProps {
    logo: string;
    backgroundColor: string;
    merchantName: string;
    amount: number;
    fontColor: string;
    pin: string;
    orderNumber: string;
    currency?: string;
    barcode?: string;
    securityCode?: string;
    deliveryURL?: string;
    disclaimerHtml?: string;
    redemptionInstructionsHtml?: string;
}

const PurchasedCardInfo = (props: PurchasedCardInfoProps) => {
    const { t } = useTranslation();
    const { commonStore } = useStores();

    const copyToClipboard = () => {
        navigator.clipboard.writeText(props.securityCode || '').then(() => {
            commonStore.success("Link copied");
        }, () => {
            commonStore.warn("Failed to copy Lightning Invoice");
        })
    }

    return (
        <>
            <Box sx={{ padding: !props?.barcode ? '24px' : '0px', marginTop: '8px', border: !props.barcode ? '1px solid #888888' : 0, borderRadius: "20px" }}>
                {/* If there's no barcode or pin, this is not a newer style card, so show the merchant info */}
                {!props.barcode && !props.pin &&
                    <MerchantInfo
                        logo={props.logo}
                        backgroundColor={props.backgroundColor}
                        name={props.merchantName}
                        disableImagePadding={true}
                        /* Making this area of the component generic */
                        rightSide={
                            () => <Box sx={{ width: '100%', textAlign: 'right' }}>
                                <Typography variant="h4" fontWeight="bold">{currencyFormatter(props.amount)}</Typography>
                            </Box>
                        }
                    />
                }

                {/* If there is a gift card code, show a formatted Gift Card Image (optional pin) */}
                {props.barcode &&
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        {
                            props.barcode && <PurchasedGiftCardImage
                                backgroundColor={props.backgroundColor}
                                fontColor={props.fontColor}
                                merchantName={props.merchantName}
                                logo={props.logo || ''}
                                amount={currencyFormatter(props.amount * 100)}
                                useGiftCardNumber
                                giftCardNumber={props.barcode}
                                currency={props.currency}
                                pin={props.pin}
                            />
                        }
                    </Box>
                }
                {/* There are cases where there is no barcode and there IS a pin, so use the PIN as the barcode  */}
                {!props.barcode && props.pin &&
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        {
                            <PurchasedGiftCardImage
                                backgroundColor={props.backgroundColor}
                                fontColor={props.fontColor}
                                merchantName={props.merchantName}
                                logo={props.logo || ''}
                                amount={currencyFormatter(props.amount * 100)}
                                useGiftCardNumber
                                giftCardNumber={props.pin} // use the pin as the cardNumber (and leave the real pin 'undefined' so it doesn't show)
                                currency={props.currency}
                            />
                        }
                    </Box>
                }
                {/* If there is a security code, show it */}
                {!!props.securityCode && <>
                    <Typography variant="caption" sx={{ color: 'common.midgrey' }}>
                        {t('giftCards:purchasedCardInfo.securityCode')}
                    </Typography>
                    <Box display="flex" flexDirection="row" justifyContent='center' alignItems={'center'} sx={{ width: '100%', marginBottom: '10px', mt: .5 }}>
                        <Box onClick={copyToClipboard} display="flex" justifyContent="center" sx={{ cursor: 'pointer', backgroundColor: 'white', color: 'black', borderRadius: '22px', padding: '12px', width: '100%' }}>
                            <Typography variant="h4" sx={{ fontSize: '1.25rem' }}>{props.securityCode}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" sx={{ padding: '4px', height: '100%' }}>
                            <CopyToClipboardButton onClick={copyToClipboard} fullWidth color="primary" variant="contained" disabled={!props.securityCode}>
                                <Typography textTransform="none" fontSize="12px">{t('giftCards:purchasedCardInfo.copyCode')}</Typography>
                            </CopyToClipboardButton>
                        </Box>
                    </Box>
                </>
                }
                {props.deliveryURL &&
                    <ListItem
                        key={props.orderNumber + props.amount}
                        component={GiftCardButton}
                        size="large"
                        onClick={() => window.open(props.deliveryURL, "_blank")}
                    >
                        <Typography textAlign="center" width="100%" textTransform="none" fontWeight="bold">
                            {t('giftCards:purchasedCardInfo.viewCard')}
                        </Typography>
                    </ListItem>
                }
                {!!props.securityCode && <>
                    <Box mt={2}>
                        <Typography variant="body2" sx={{ color: 'common.goldenrod' }}>
                            {t('giftCards:purchasedCardInfo.securityCodeInfo')}
                        </Typography>
                    </Box>
                </>
                }
                {!!props.redemptionInstructionsHtml && <Box sx={{ marginTop: '20px', '& a': { color: 'primary.main' } }}>
                    <Typography variant="h6" component="h2" sx={{ mb: -1, textDecoration: 'underline' }}>{t('giftCards:redemption')}</Typography>
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: props.redemptionInstructionsHtml }}></Typography>
                </Box>
                }
                {/* Check here for the disclaimer and show if it exists */}
                {!!props.disclaimerHtml && <Box sx={{ marginTop: '20px', '& a': { color: 'primary.main' } }}>
                    <Typography variant="h6" component="h2" sx={{ mb: -1, textDecoration: 'underline' }}>{t('global:termsAndConditions')}</Typography>
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: props.disclaimerHtml }}></Typography>
                </Box>
                }
            </Box>
        </>
    );
}
export default PurchasedCardInfo;