import {
    Box,
    styled,
} from '@mui/material';

interface MainContainerProps {
    minHeight?: string;
}

// Todo - put these in a layout component? Is that too restrictive?
export const MainContainer = styled(Box)<MainContainerProps>(({ theme, minHeight }) => ({
    minHeight: minHeight || '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative'
}));

export const BottomContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    position: 'absolute',
    bottom: '5%',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.up('sm')]: {
        position: 'initial',
        transform: 'initial',
        marginTop: '60px'
    }
}));


interface TopBottomLayoutProps {
    minHeight?: string;
    topComponent: any;
    bottomComponent: any;
}

// A responsive way to layout something into a Top/Bottom layout, where the bottom is 
// aligned to the bottom of the screen on mobile devices
export const TopBottomLayout = (props: TopBottomLayoutProps) => {
    <MainContainer minHeight={props.minHeight}>
        {props.topComponent}
        <BottomContainer>
            {props.bottomComponent}
        </BottomContainer>
    </MainContainer>
}