import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress, styled, Typography } from '@mui/material';
import { createAsyncPaymentListener } from './createAsyncPaymentListener';
import { useStores } from '../../../../hooks/use-stores';
import PurchasedCard from '../../../../stores/models/PurchasedCard';

const Wrapper = styled(Box)(({ theme }) => ({
    width: '100%',
}));

const SparkCash = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { authStore, giftcardStore } = useStores();

    let unsub: (() => void) | null = null;
    const [error, setError] = useState('');
    const [paymentPending, setPaymentPending] = useState(true);

    const onPaymentSuccess = useCallback((purchasedCard: PurchasedCard) => {
        // giftcard was paid successfully
        giftcardStore.purchasedCardInfo = purchasedCard;
        navigate('/giftcards/confirmation');
    }, []);

    const onPaymentPending = useCallback(() => {
        // The payment was sent, so start the spinner to show the user it's in progress
        setPaymentPending(true);
    }, [paymentPending]);

    const onPaymentFailed = useCallback(() => {
        // The payment failed for some reason
        setError(t('giftCards:finalize.paymentFailure'));
        setPaymentPending(false);
    }, []);

    const onPaymentError = useCallback((error?: any) => {
        setError(t('global:networkError'));
        setPaymentPending(false);
    }, []);


    const sparkCashCheckout = async () => {
        try {
            await giftcardStore.sparkCashCheckout();
            const asyncCheckoutInfo = giftcardStore.asyncCheckoutInfo?.paymentInfo[0];

            if (!asyncCheckoutInfo?.invoiceId) {
                setError(t('giftCards:finalize.invoiceFetchError'));
                return;
            }

            // Start listening on collection
            unsub = createAsyncPaymentListener({
                invoiceId: asyncCheckoutInfo.invoiceId,
                userId: authStore.firebaseUser?.uid!,
                onPaymentSuccess,
                onPaymentPending,
                onPaymentFailed,
                onPaymentError
            });
        } catch (e) {
            setError(t("giftCards:finalize.paymentFailure"))
        } finally {
            setPaymentPending(false);
        }
    }

    useEffect(() => {
        sparkCashCheckout()

        return (() => {
            if (unsub) {
                unsub();
            }
        })

    }, [])

    return (
        <Wrapper>
            {error && <Box>
                <Alert severity="error">{error}</Alert>
            </Box>
            }
            {true && <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding="10px 4px">
                <CircularProgress />
                <Typography fontWeight="bold" marginTop="10px">{t("giftCards:finalize.inProgress")}</Typography>
            </Box>
            }
            {error && <Box>
                <Button onClick={() => navigate(-1)}>{t('giftCards:finalize.goBack')}</Button>
            </Box>
            }
        </Wrapper>
    )
}

export default SparkCash;
