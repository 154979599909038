import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Container, Divider, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import FullPageLoader from '../../components/FullPageLoader';
import { useStores } from '../../../../hooks/use-stores';
import { observer } from 'mobx-react-lite';
import ACHTransaction, { ACHPaymentStatus } from '../../../../stores/models/ACHTransaction';
import { currencyFormatter } from '../../../../utils/helpers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ErrorIcon from '@mui/icons-material/Error';
import Lottie from 'lottie-react';
import successAnimation from '../../../../lottie/success.json';
import { tableCellClasses } from '@mui/material/TableCell';

const PaymentStatusCell = ({ icon, text }: { icon: any, text: string }) => (
    <Box display="flex" alignItems="center">
        <Box marginRight="4px">{icon}</Box> <Box>{text}</Box>
    </Box>
)

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        border: 0,
        fontWeight: 'normal',
        padding: '8px',
        paddingLeft: 0
    },
    [`&.${tableCellClasses.body}`]: {
        paddingLeft: 0
    },
}));


const StyledPaper = styled('div')(({ theme }) => ({
    padding: '16px',
    paddingTop: 0
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Transactions = () => {
    const { t } = useTranslation();
    const { plaidStore } = useStores();

    const { error, transactions } = plaidStore;

    const [loading, setLoading] = useState(!plaidStore.successfulPayment);

    const paymentStatus = (status: string) => {
        // Support ACHQ and Dwolla
        if (status == ACHPaymentStatus.SCHEDULED || status == ACHPaymentStatus.SUBMITTED || status == ACHPaymentStatus.CREATED) {
            return <PaymentStatusCell icon={<HourglassBottomIcon />} text={t("profile:transactions.inProgress")} />
        } else if (status == ACHPaymentStatus.CLEARED) {
            return <PaymentStatusCell icon={<CheckCircleIcon />} text={t("profile:transactions.cleared")} />
        } else if (status == ACHPaymentStatus.CANCELLED) {
            return <PaymentStatusCell icon={<ErrorIcon />} text={t("profile:transactions.cancelled")} />
        } else {
            return <PaymentStatusCell icon={<ErrorIcon />} text={t("profile:transactions.failed")} />
        }
    }

    useEffect(() => {
        if (!plaidStore.successfulPayment) {
            // Load all the user's previous transactions, including the newest one (duh)
            plaidStore.loadTransactions(() => setLoading(false));
        }

        return () => {
            // Clear the successful payment id so user doesn't see it again
            if (plaidStore.successfulPayment) {
                plaidStore.successfulPayment = null;
            }
        }
    }, []);

    return (
        <Container>
            {
                loading ? <FullPageLoader /> :
                    <Box>
                        {plaidStore.successfulPayment ?
                            <Box width="100%" display="flex" flexDirection="column" marginBottom="10px">
                                <>
                                    <Alert severity="success">
                                        <Typography>
                                            {t('profile:transactions.successfulPayment')}
                                        </Typography>
                                        <Typography color={'common.goldenrod'}>
                                            {t('profile:transactions.paymentClearWarning')}
                                        </Typography>
                                    </Alert>
                                    <Box display="flex" alignItems="center" justifyContent="center">
                                        <Box sx={{ maxWidth: { xs: '100%', md: '500px' } }}>
                                            <Lottie animationData={successAnimation} loop={false} />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Paper sx={{ borderRadius: '20px' }}>
                                            <Box display="flex" alignItems="center" justifyContent="center" padding="18px">
                                                <Typography padding={0} fontSize="18px" letterSpacing={'.5px'}>{t('profile:transactions.details')}</Typography>
                                            </Box>
                                            <Divider sx={{ color: 'white', width: '100%', marginBottom: '7px' }} />
                                            <Box padding="16px">
                                                <Box display="flex" justifyContent="space-between" marginBottom="8px">
                                                    <Typography sx={{ fontSize: '12px', color: 'common.midgrey' }}>{t('profile:transactions.id')}</Typography>
                                                    <Typography sx={{ fontSize: '12px', color: 'common.midgrey' }}>{t('profile:transactions.amount')}</Typography>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography color={'common.midgrey'}>{plaidStore.successfulPayment._id}</Typography>
                                                    <Typography fontSize="26px" fontWeight="bold">{currencyFormatter(plaidStore.successfulPayment.amount)}</Typography>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between" marginTop="10px" marginBottom="8px">
                                                    <Typography sx={{ fontSize: '12px', color: 'common.lightgrey' }}>{t('profile:transactions.date')}</Typography>
                                                </Box>
                                                <Box>
                                                    <Typography sx={{ fontSize: '15px', color: 'common.lightgrey' }}>{moment(plaidStore.successfulPayment.createdAt).format('MMMM Do YYYY')}</Typography>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </>
                            </Box>
                            :
                            <Paper sx={{ borderRadius: '10px', width: "100%", padding: '0px' }}>
                                <Box display="flex" alignItems="center" justifyContent="center" padding="18px">
                                    <Typography padding={0} fontSize="18px" letterSpacing={'.5px'}>{t('profile:transactions.details')}</Typography>
                                </Box>
                                <Divider sx={{ color: 'white', width: '100%', marginBottom: '7px' }} />
                                {
                                    error ? <Alert>

                                    </Alert>
                                        :

                                        <TableContainer component={StyledPaper}>
                                            <Table sx={{ minWidth: { xs: '100%', 'md': '800px' } }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="left">{t('profile:transactions.id')}</StyledTableCell>
                                                        <StyledTableCell align="left">{t('profile:transactions.amount')}</StyledTableCell>
                                                        <StyledTableCell align="left">{t('profile:transactions.method')}</StyledTableCell>
                                                        <StyledTableCell align="left">{t('profile:transactions.date')}</StyledTableCell>
                                                        <StyledTableCell align="left">{t('profile:transactions.status')}</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                    {transactions.length > 0 ? transactions.map((row: ACHTransaction) => (
                                                        <TableRow
                                                            key={row._id}
                                                            sx={{ marginBottom: '-5px', '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <StyledTableCell component="th" scope="row">
                                                                {row._id}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left"><Typography letterSpacing="1px" fontSize="22px" fontWeight="bold">{currencyFormatter(row.amount)}</Typography></StyledTableCell>
                                                            <StyledTableCell align="left">{row.accountName}</StyledTableCell>
                                                            <StyledTableCell align="left">{moment(row.createdAt).format('MMMM Do YYYY')}</StyledTableCell>
                                                            <StyledTableCell align="left">{paymentStatus(row.paymentStatus)}</StyledTableCell>
                                                        </TableRow>
                                                    )) :
                                                        <TableRow>
                                                            <TableCell colSpan={5} align="center">
                                                                {t('profile:transactions.noTransactions')}
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                }
                            </Paper>
                        }
                    </Box>
            }
        </Container>
    )
}
export default observer(Transactions);