import React, { useState, useLayoutEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
import { Merchant } from '../../../../stores/models/Merchant';
import { useStores } from '../../../../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import MerchantDisplay from '../../shared/MerchantDisplay';

const FEATURED_LIST_CATEGORY = 'featured';
const CONTAINER_SCROLL_AMOUNT = 500;
const FEATURED_ITEM_WIDTH = 260;
const NORMAL_ITEM_WIDTH = 150;

const Wrapper = styled(Container)(({ theme }) => ({
    padding: '0px !important',
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
}));

const ScrollButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "black",
    borderRadius: '20px',
    '&:hover': {
        backgroundColor: '#222222'
    }
}));

const SeeAllButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 'bold'
}));

interface FeaturedMerchantProps {
    title?: string;
    subtitle?: string;
    merchants: Merchant[];
    category: string; // we need to be able to filter by merchants; include the category so we can filter; can also be 'featured'
    largeImages?: boolean; // if true, the merchants images will be large on non-mobile screens
    onSelectMerchant: (merchant: Merchant) => void;
}

const FeaturedMerchant = (props: FeaturedMerchantProps) => {
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const { giftcardStore } = useStores();
    const navigate = useNavigate();
    const [scrollPosition, setScrollPosition] = useState(0);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const isFeatured = props.category == FEATURED_LIST_CATEGORY;

    const navigateToCategory = useCallback(() => {
        giftcardStore.category = props.category;
        navigate('/giftcards/category');
    }, [props.category]);

    const scrollContainer = (scrollAmount: number) => {
        if (containerRef.current) {
            // @ts-ignore
            containerRef.current.scrollLeft += scrollAmount;
        }
    }

    const scrollForward = useCallback(() => {
        scrollContainer(CONTAINER_SCROLL_AMOUNT);
    }, []);

    const scrollBackward = useCallback(() => {
        scrollContainer(-CONTAINER_SCROLL_AMOUNT);
    }, []);

    const atStart = useMemo(() => {
        if (containerRef.current) {
            //@ts-ignore
            return scrollPosition <= 50;
        }
        return true;
    }, [scrollPosition])

    const atEnd = useMemo(() => {
        if (containerRef.current) {
            //@ts-ignore
            return scrollPosition >= containerRef.current.scrollWidth - containerRef.current.clientWidth;;
        }
        return false;
    }, [scrollPosition])

    useLayoutEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const updatePosition = () => {
            //@ts-ignore
            setScrollPosition(containerRef.current.scrollLeft);
        }

        //@ts-ignore
        containerRef.current.addEventListener('scroll', updatePosition);
        updatePosition();

        //@ts-ignore
        return () => containerRef.current.removeEventListener('scroll', updatePosition);
    }, []);


    if (!props.category || props.merchants?.length == 0) {
        return null;
    }

    return (
        <Wrapper>
            <Grid container>
                <Grid item container xs={8} md={6}>
                    {props.title && <Grid item xs={12}>
                        <Typography sx={{ fontWeight: 'bold' }} variant="h5">{props.title}</Typography>
                    </Grid>
                    }
                    <Grid item xs={12} justifyContent="space-between" mt={-.25}>
                        {props.subtitle && <Typography variant="body2" sx={{ color: 'gray' }}>{props.subtitle}</Typography>}
                    </Grid>
                </Grid>
                {!isFeatured && <Grid item container xs={4} md={6} justifyContent="flex-end" >
                    <SeeAllButton color="primary" onClick={navigateToCategory}>{t('giftCards:featuredMerchants.seeAllMerchants')}</SeeAllButton>
                </Grid>
                }
            </Grid>
            {false && <>
                {!atStart && <Box sx={{ position: 'absolute', top: '50%', left: '10px', zIndex: 1000 }}>
                    <ScrollButton onClick={scrollBackward} sx={{ minWidth: '0px' }}>
                        <ArrowBackIosOutlined />
                    </ScrollButton>
                </Box>
                }
                <Box sx={{ position: 'absolute', top: '50%', right: '10px', zIndex: 1000 }}>
                    <ScrollButton disabled={atEnd} onClick={scrollForward} sx={{ minWidth: '0px' }}>
                        <ArrowForwardIosOutlined />
                    </ScrollButton>
                </Box>
            </>
            }
            <Box
                ref={containerRef}
                sx={{
                    display: 'grid',
                    width: '100%',
                    overflowX: 'scroll',
                    scrollSnapType: 'x mandatory',
                    scrollBehavior: 'smooth',
                    gridAutoFlow: "column",
                    gridTemplateColumns: 'none',
                    gridAutoColumns: {
                        xs: `${props.largeImages ? `${FEATURED_ITEM_WIDTH}px` : `${NORMAL_ITEM_WIDTH}px`} !important`,
                        md: `${props.largeImages ? `${FEATURED_ITEM_WIDTH * 1.4}px` : `${NORMAL_ITEM_WIDTH * 1.4}px`} !important`,
                    },
                    gap: '15px !important',
                    marginTop: '14px',
                    scrollbarWidth: "none",
                    "&::-webkit-scrollbar": {
                        display: "none"
                    }
                }}
            >
                {props.merchants.map((merchant: Merchant) => (
                    <Box key={merchant.slug}>
                        <MerchantDisplay
                            largeImage={props.category == 'featured'}
                            backgroundColor={merchant.backgroundColor}
                            logoImageUrl={merchant.logoImageUrl}
                            name={merchant.name}
                            cashbackPercentage={merchant.cashbackPercentage}
                            showMerchantInfo={!isFeatured}
                            onSelect={() => props.onSelectMerchant(merchant)}
                        />
                    </Box>
                ))}
            </Box >
        </Wrapper >
    );
}

export default FeaturedMerchant;