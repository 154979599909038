
import {
    makeAutoObservable, runInAction,
} from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';
import SparkTransaction from './models/SparkTransaction';
import WalletBalances from './models/WalletBalances';

interface Wallet {
    message: string;
    btcDepositAddress: string;
    lnurl: string;
    username: string;
}

class WalletStore {
    public transactions: SparkTransaction[] = [];
    public balances: WalletBalances | null = null;
    public createdWallet: Wallet | null = null;

    public loading: boolean = true;
    public error: Error | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.transactions = [];
        this.balances = null;
        this.createdWallet = null;
        this.loading = false;
        this.error = null;
    }

    setError(error: Error | string | null) {
        this.error = extractErrorMessage(error);
    }

    async loadTransactions() {
        try {
            this.error = null;

            const result = await services.Wallet.getTransactions();
            runInAction(() => {
                this.transactions = result.payments;
            })
        } catch (e: any) {
            runInAction(() => {
                this.setError(e)
            })
        }
    }

    async loadBalances() {
        try {
            this.error = null;
            const result = await services.Wallet.getWalletBalances();

            runInAction(() => {
                this.balances = result;
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e)
            });
        }
    }

    async createWallet() {
        try {
            this.error = null;
            this.createdWallet = null;

            const result = await services.Wallet.createWallet();

            if (result.message !== 'success') {
                throw new Error('')
            }

            runInAction(() => {
                this.createdWallet = result;
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e)
            });
        }
    }

    async createUsername(username: string, referralCode: string) {
        try {
            this.error = null;

            const result = await services.UserProfile.createUsername(username, referralCode);

            if (result.message !== 'success') {
                throw new Error('')
            }
        } catch (e: any) {
            runInAction(() => {
                this.setError(e)
            });
        }
    }
}

export default WalletStore;