import React, { ChangeEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import {
    Typography,
    Grid,
    SelectChangeEvent,
    MenuItem,
    TextField,
    Box,
    InputLabel,
    FormControl,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ErrorDialog from './ErrorDialog';
import Select from '../../components/Select';
import MaskedMaterialTextField from '../../components/MaskedMaterialTextField';
import SparkButton from '../../components/SparkButton';
import { useStores } from '../../hooks/use-stores';

// Step 2
const AddressInput = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { commonStore, identityStore } = useStores();

    const { regionsByCountry } = commonStore;
    const USRegions = regionsByCountry['US'] || [];

    const { identityResult } = identityStore;
    const [dob, setDOB] = useState<Date | null>(identityResult?.dob ? moment(moment.tz(identityResult.dob, 'UTC').startOf('day').format('LL')).startOf('day').toDate() : null);
    const [last4, setLast4] = useState<string>(identityResult?.last4 || '');

    const [firstName, setFirstName] = useState<string>(identityResult?.firstName || '');
    const [lastName, setLastName] = useState<string>(identityResult?.lastName || '');
    const [address, setAddress] = useState<string>(identityResult?.address || '');
    const [city, setCity] = useState<string>(identityResult?.city || '');
    const [postalCode, setPostalCode] = useState<string>(identityResult?.postalCode || '');
    const [region, setRegion] = useState<string>(identityResult?.region || '');

    const handleChangeLast4 = (e: ChangeEvent<HTMLInputElement>) => {
        setLast4(e.target.value);
    };

    const handleChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };

    const handleChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    };

    const handleChangeAddress = (e: ChangeEvent<HTMLInputElement>) => {
        setAddress(e.target.value);
    };

    const handleChangeCity = (e: ChangeEvent<HTMLInputElement>) => {
        setCity(e.target.value);
    };

    const handleChangePostalCode = (e: ChangeEvent<HTMLInputElement>) => {
        setPostalCode(e.target.value);
    };

    const handleChangeRegion = (event: SelectChangeEvent<any>) => {
        setRegion(event.target.value);
    };

    const handleVerifyIdentity = async () => {
        await identityStore.verifyIdentity(firstName, lastName, dob!, last4, city, address, region, postalCode);
        if (identityStore.finalizeResult?.verified) {
            navigate('/giftcards/profile/banks?verification=true');
        } else {
            // this is handled in the error dialog
        }
    };

    const isFormValid = useMemo(() => {
        return last4 && last4.length === 4 && dob != null && lastName && firstName && city && address && postalCode && region;
    }, [last4, dob, city, region, postalCode, address, firstName, lastName]);

    const showErrorDialog = useMemo(() => !!identityStore.identityCheckError || !!identityStore.identityError,
        [identityStore.identityCheckError, identityStore.identityError]);

    return (
        <Box>
            <Typography variant="body2" mb={1}>
                {t('auth:identity.infoFill')}
            </Typography>
            <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start" >
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label={t('auth:identity.legalFirstName')}
                        margin={'normal'}
                        value={firstName}
                        onChange={handleChangeFirstName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label={t('auth:identity.legalLastName')}
                        margin={'normal'}
                        value={lastName}
                        onChange={handleChangeLastName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label={t('auth:identity.selectDate')}
                            value={dob}
                            openTo={"year"}
                            views={["year", "month", "day"]}
                            onChange={(newValue) => {
                                setDOB(
                                    newValue &&
                                    moment.tz(newValue, 'UTC').endOf('day').toDate()
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    id="dob"
                                    name={'dob'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin={'normal'}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <MaskedMaterialTextField
                        format="####"
                        fullWidth
                        id="last4"
                        name={'last4'}
                        InputLabelProps={{ shrink: true }}
                        label={t('auth:identity.last4')}
                        margin={'normal'}
                        value={last4}
                        onChange={handleChangeLast4}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label={t('auth:identity.address')}
                        margin={'normal'}
                        value={address}
                        onChange={handleChangeAddress}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        InputLabelProps={{ shrink: true }}
                        label={t('auth:identity.city')}
                        margin={'none'}
                        value={city}
                        onChange={handleChangeCity}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel shrink id="regionLabel">
                            {t('auth:identity.region')}
                        </InputLabel>
                        <Select
                            labelId="regionLabel"
                            fullWidth
                            value={region}
                            onChange={handleChangeRegion}>
                            {USRegions.map((x: any) => {
                                return (
                                    <MenuItem key={x.shortCode} value={x.shortCode}>
                                        {x.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label={t('auth:identity.postalCode')}
                        margin={'normal'}
                        value={postalCode}
                        onChange={handleChangePostalCode}
                    />
                </Grid>
                <SparkButton
                    sx={{ ml: 2, mt: 1 }}
                    fullWidth
                    onClick={handleVerifyIdentity}
                    disabled={!isFormValid}
                    loading={!!identityStore.finalizing}
                >
                    {t('global:continue')}
                </SparkButton>
                <Typography mt={1} width="100%" textAlign="center" color="common.goldenrod" variant="body2">{t('auth:identity.ensureCorrect')}</Typography>
            </Grid>
            <ErrorDialog
                isOpen={showErrorDialog}
                showFailedContent={Boolean(identityStore.identityError) || identityStore.verificationsAttempts > 2}
                showFailedButton={identityStore.verificationsAttempts > 2}
                step={2}
                firstName={firstName}
                lastName={lastName}
                last4={last4}
                dob={dob!}
                address={address}
                city={city}
                region={region}
                postalCode={postalCode}
            />
        </Box>
    );
}

export default observer(AddressInput);