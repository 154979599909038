import React, { useCallback, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import QRCode from 'easyqrcodejs';
import { Box, Paper, styled, Typography } from '@mui/material';
import Lightning from './paymentMethods/Lightning';
import SparkCash from './paymentMethods/SparkCash';
import GiftCardImage from '../components/GiftCardImage';
import { useStores } from '../../../hooks/use-stores';
import { SPARK_CASH } from '../../../constants';
import { currencyFormatter } from '../../../utils/helpers';

export const DEFAULT_QR_OPTIONS = {
    logo: `${process.env.PUBLIC_URL}/img/sparklogo.png`,
    logoWidth: 50,
    logoHeight: 50,
    logoBackgroundTransparent: true,
    width: 250,
    height: 250,
    colorDark: "#000000",
    colorLight: "#ffffff",
    correctLevel: QRCode.CorrectLevel.H,
    quietZone: 20,
    quietZoneColor: "white",
};

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '0px 10px',
    maxWidth: '500px',
    margin: '0 auto'
}))

const PaymentMethodWrapper = styled(Paper)(({ theme }) => ({
    gridArea: 'payment',
    borderRadius: '20px',
    padding: '14px',
    width: '100%',
    margin: '10px',
    marginTop: '16px',
    background: theme.palette.common.darkgrey,
}));

const Finish = () => {
    const { t } = useTranslation();
    const { giftcardStore } = useStores();
    const navigate = useNavigate();

    let unsub: (() => void) | null = null;

    const {
        selectedMerchantInfo
    } = giftcardStore;

    const goHome = useCallback(() => {
        navigate('/giftcards/shop');
    }, []);

    // Render only once
    const selectedPaymentMethod = useMemo(() => {
        if (giftcardStore.selectedPaymentMethod.name == SPARK_CASH) {
            return <SparkCash />;
        } else {
            return <Lightning />;
        }
    }, [giftcardStore.selectedPaymentMethod]);

    useEffect(() => {
        if (!giftcardStore.selectedMerchantId) {
            goHome();
        }

        return () => {
            if (unsub) {
                unsub();
            }
        }
    }, [])

    if (!giftcardStore.selectedMerchantId) {
        return null;
    }

    return (
        <Wrapper>
            <Box sx={{ width: '100%', gridArea: 'details' }}>
                <GiftCardImage
                    backgroundColor={selectedMerchantInfo?.backgroundColor}
                    fontColor={selectedMerchantInfo?.fontColor}
                    logo={selectedMerchantInfo?.logoImageUrl || ''}
                    currency={giftcardStore.selectedMerchantCurrency}
                    amount={currencyFormatter(giftcardStore.orderTotal)}
                    useGiftCardNumber
                />
            </Box>
            <PaymentMethodWrapper>
                <Typography fontSize="26px" fontWeight="bold">{t('giftCards:finalize.payment')}</Typography>
                {!giftcardStore.purchasedCardInfo && selectedPaymentMethod}
            </PaymentMethodWrapper>
        </Wrapper >
    );

}

export default observer(Finish);