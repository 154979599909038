import React, { ChangeEvent } from 'react';
import { observer } from "mobx-react-lite";
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

interface Props {
    searchQuery: string;
    onSearchQueryChanged: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    isSmall?: boolean
}

const SimpleSearch = observer((props: Props) => {
    const { t } = useTranslation();

    return (
        <FormControl fullWidth variant="outlined">
            <OutlinedInput
                sx={{ borderRadius: '10px' }}
                size={props.isSmall ? 'small' : 'medium'}
                placeholder={props.placeholder || t('global:search')}
                value={props.searchQuery}
                autoComplete={'off'}
                onChange={props.onSearchQueryChanged}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
            />
        </FormControl>
    )
})

export default SimpleSearch;