import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIosOutlined } from '@mui/icons-material';
import {
    Box,
    IconButton,
    styled,
} from '@mui/material';

const HeaderWrapper = styled(Box)(({ theme, }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "75px",
    position: 'relative'
}));

interface BackButtonProps {
    hideBackButton?: boolean;
    topComponent?: any;
}

const BackButton = (props: BackButtonProps) => {
    const navigate = useNavigate();
    
    return (
        <HeaderWrapper>
            {!props.hideBackButton && <Box position="absolute" left={0}>
                <IconButton color="inherit" onClick={() => navigate(-1)}>
                    <ArrowBackIosOutlined sx={{ fontSize: '14px' }} />
                </IconButton>
            </Box>
            }
            {props.topComponent && <Box display="flex" width="100%" alignItems="center" justifyContent="center">
                {props.topComponent}
            </Box>
            }
        </HeaderWrapper>
    );
}
export default BackButton;