import React from 'react';
import { Box, CircularProgress, styled, Typography, } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface WrapperProps {
    backgroundColor: string;
}

const Wrapper = styled(Box)<WrapperProps>((props) => ({
    display: "flex",
    alignItems: 'center',
    justifyContent: 'left',
    position: 'relative',
    backgroundColor: props.backgroundColor,
    padding: '20px',
    borderRadius: 20,
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    border: '1px solid #333333',
    userSelect: 'none', // don't allow user to copy (there is nothing useful to copy)
    [props.theme.breakpoints.up('sm')]: {
        maxWidth: '450px',
    }
}));

const Image = styled('div')(({ theme }) => ({
    width: '100%',
    height: '180px',
    maxWidth: '140px',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '160px',
    }
}));

interface CardNumberLabelProps {
    fontColor: string;
}
const CardNumberLabel = styled(Box, {
    shouldForwardProp: (prop) => prop !== "fontColor"
})<CardNumberLabelProps>((props) => ({
    color: props.fontColor,
    fontSize: '13px',
    position: 'absolute',
    fontWeight: 400,
    bottom: 60,
    left: 20
}));

interface GiftCardNumberProps {
    fontColor: string;
}
const GiftCardNumber = styled(Box, {
    shouldForwardProp: (prop) => prop !== "fontColor"
})<GiftCardNumberProps>((props) => ({
    color: props.fontColor,
    fontSize: '18px',
    fontWeight: 'bold',
    position: 'absolute',
    letterSpacing: '2px',
    left: '50%',
    bottom: '45px',
    transform: 'translate(-50%, 50%)',
    width: '100%',
    textAlign: 'center'
}));

const GiftCardNumberWarning = styled(Box, {
    shouldForwardProp: (prop) => prop !== "fontColor"
})<GiftCardNumberProps>((props) => ({
    color: props.fontColor,
    fontSize: '12px',
    position: 'absolute',
    left: '50%',
    bottom: '20px',
    transform: 'translate(-50%, 50%)',
    width: '100%',
    textAlign: 'center'
}));

interface Props {
    backgroundColor: string
    fontColor: string
    logo: string
    amount?: string
    currency?: string
    fullWidth?: boolean
    useGiftCardNumber?: boolean;
    giftCardNumber?: string;
    loadingCard?: boolean;
}

const GiftCardImage = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Wrapper backgroundColor={props.backgroundColor}>
            <Image sx={{
                width: '100%',
                backgroundImage: `url("${props.logo}")`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
            }}
            />
            {props.amount && props.currency &&
                <Box sx={{ color: props.fontColor, position: 'absolute', top: 10, right: 10 }}>
                    <Typography sx={{ fontSize: 34, fontWeight: 900, marginBottom: -1 }}>{props.amount}</Typography>
                    <Typography sx={{ fontSize: 16, fontWeight: 'light', textAlign: 'right' }}>{props.currency}</Typography>
                </Box>
            }
            {
                props.useGiftCardNumber &&
                <>
                    <CardNumberLabel fontColor={props.fontColor}>
                        {t('giftCards:cardImage.cardNumber')}
                    </CardNumberLabel>
                    <GiftCardNumber fontColor={props.fontColor}>
                        {props.loadingCard ? <CircularProgress size={24} /> : <span dangerouslySetInnerHTML={{ __html: props.giftCardNumber || "XXXX&nbsp;&nbsp;XXXX&nbsp;&nbsp;XXXX&nbsp;&nbsp;XXXX" }} />}
                    </GiftCardNumber>
                </>
            }
            {
                !props.giftCardNumber &&
                <GiftCardNumberWarning fontColor={props.fontColor}>
                    Gift card code will appear after payment is recieved
                </GiftCardNumberWarning>

            }
        </Wrapper>
    )
}

export default GiftCardImage;