import React from 'react';
import { Box, styled } from '@mui/material';

interface ImageWrapperProps {
    disableImagePadding?: boolean;
    largeImage?: boolean;
}

const ImageWrapper = styled('img', {
    shouldForwardProp: (prop) => prop !== "disableImagePadding" && prop !== "largeImage"
})<ImageWrapperProps>((props) => ({
    width: '100%',
    maxWidth: `${props.largeImage ? 185: 125}px`,
    zIndex: 1,
    [props.theme.breakpoints.up('sm')]: {
        fontSize: 18,
    },
    padding: props.disableImagePadding ? '0px' : '10px'
}));

interface Props {
    largeImage?: boolean;
    disableImagePadding?: boolean;
    backgroundColor: string;
    imageUrl: string;
    height?: string;
    disableBottomBorderRadius?: boolean; //don't use the border radius on the bottom of the image
}

const MerchantImage = (props: Props) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
                borderRadius: props.disableBottomBorderRadius ? '20px 20px 0px 0px' : '20px',
                backgroundColor: props.backgroundColor,
                height: props.height || '100%'
            }}>
            <ImageWrapper largeImage={props.largeImage} src={props.imageUrl} loading="lazy" disableImagePadding={props.disableImagePadding} />
        </Box>
    )
}
export default MerchantImage;
