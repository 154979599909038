
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { useStores } from '../../hooks/use-stores';
import { formatDOB } from '../../utils/helpers';

/* I hate this but this is the fastest way to make this component */
interface Props {
    isOpen: boolean;
    step: number;
    showFailedContent: boolean;
    showFailedButton: boolean;
    firstName?: string;
    lastName?: string;
    last4?: string
    dob?: Date;
    address?: string;
    city?: string;
    region?: string;
    postalCode?: string;
}

const ErrorDialog = (props: Props) => {
    const { identityStore } = useStores();

    const { t } = useTranslation();
    const [wrongDataConfirmed, setWrongDataConfirmed] = useState<boolean>(false);

    const closeDialog = () => {
        window.location.reload();
    }

    const resetIdentityError = () => {
        identityStore.resetIdentityError();
    }

    return (
        <Dialog open={props.isOpen} PaperProps={{ sx: { paddingBottom: '40px', width: '100%' } }}>
            {props.showFailedContent || wrongDataConfirmed ?
                <>
                    <DialogTitle>{t('auth:identity.validationFailed')}</DialogTitle>
                    <DialogContent>
                        <>
                            {t('auth:identity.contactSupport')}
                        </>
                    </DialogContent>
                </>
                :
                <>
                    <Stack padding={2} width="100%" alignItems="center">
                        {props.step === 2 && (
                            <>
                                <Typography>
                                    <strong>{t('auth:identity.legalFirstName')}:</strong> {props.firstName}
                                </Typography>

                                <Typography>
                                    <strong>{t('auth:identity.legalLastName')}:</strong> {props.lastName}
                                </Typography>
                            </>
                        )}
                        <Typography>
                            <strong>{t('auth:identity.dateOfBirth')}:</strong> {formatDOB(props.dob)}
                        </Typography>

                        <Typography>
                            <strong>{t('auth:identity.last4')}:</strong> {props.last4}
                        </Typography>

                        {props.step === 2 && (
                            <>
                                <Typography>
                                    <strong>{t('auth:identity.address')}:</strong> {props.address}
                                </Typography>

                                <Typography>
                                    <strong>{t('auth:identity.city')}:</strong> {props.city}
                                </Typography>

                                <Typography>
                                    <strong>{t('auth:identity.region')}:</strong> {props.region}
                                </Typography>

                                <Typography>
                                    <strong>{t('auth:identity.postalCode')}:</strong> {props.postalCode}
                                </Typography>
                            </>
                        )}

                        <Typography textAlign="center" sx={{ marginTop: 20 }}>{t('auth:identity.isThisCorrect')}</Typography>
                    </Stack>
                </>
            }
            <DialogActions sx={{ margin: '0 auto' }}>
                {props.showFailedButton || wrongDataConfirmed ?
                    <Button variant="contained" color="primary" onClick={closeDialog}>
                        {t('global:ok')}
                    </Button>
                    :
                    <Stack gap={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setWrongDataConfirmed(true);
                            }}
                        >
                            {t('auth:identity.yesItsCorrect')}
                        </Button>
                        <Button variant="contained" color="secondary" onClick={resetIdentityError}>
                            {t('auth:identity.noINeedToChange')}
                        </Button>
                    </Stack>
                }
            </DialogActions>
        </Dialog>
    )
}

export default ErrorDialog;