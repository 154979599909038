import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { observer } from 'mobx-react-lite';
import {
    Box,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ErrorDialog from './ErrorDialog';
import MaskedMaterialTextField from '../../components/MaskedMaterialTextField';
import SparkButton from '../../components/SparkButton';
import { useStores } from '../../hooks/use-stores';

interface Props {
    onNext: () => void;
}
// Step 1
const EnterDOB = (props: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { commonStore, identityStore } = useStores();

    const [loading, setLoading] = useState<boolean>(true);
    const [dob, setDOB] = useState<Date | null>(null);
    const [last4, setLast4] = useState<string>('');
    const [eligibilityError, setEligibilityError] = useState<boolean>(false);

    const handleChangeLast4 = (e: ChangeEvent<HTMLInputElement>) => {
        setLast4(e.target.value);
    };

    const handleVerifyIdentity = async () => {
        if (isFormValid) {
            if (identityStore.eligibilityForPrefill === true) {
                await identityStore.identity(dob, last4);
            }

            props.onNext();
        }
    }

    const onKeyPress = (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleVerifyIdentity();
        }
    }

    const isFormValid = useMemo(() => {
        if (!dob) return false;

        const isDOBValid = moment(dob).isBefore(moment());
        return Boolean(last4 && last4.length === 4 && isDOBValid)
    }, [last4, dob]);

    const showErrorDialog = useMemo(() => !!identityStore.identityCheckError || !!identityStore.identityError || eligibilityError,
        [identityStore.identityCheckError, identityStore.identityError, eligibilityError]);

    const load = async () => {
        commonStore.loadRegionsByCountry('US');
        identityStore.reset();

        // Kick things off here by getting the verify status of the user
        await identityStore.getVerifyStatus(true);
        // If possession checked is false, the user hasn't verified their phone
        // They should never get this far without verifying their phone
        if (identityStore.possessionChecked) {
            await identityStore.eligibility();

            // If the user's eligibility is null, then they are not allowed to continue
            if (identityStore.eligibilityForPrefill === null) {
                setEligibilityError(true);
            } else if (identityStore.eligibilityForPrefill === true) {
            } else {
                // If we're here, the prefill is false, which means the user must fill out
                // all of their information
            }
        } else {
            navigate('/verify_phone');
        }

        setLoading(false);
    }

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            load();
        }

        return () => {
            ignore = true;
        }
    }, []);

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            {loading || identityStore.eligibilityForPrefill === null ? <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box> :
                <>
                    <Typography textAlign="center" variant="body1" mb={1}>{t('auth:identity.enterDOB')}</Typography>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label={t('auth:identity.selectDate')}
                            value={dob}
                            openTo={"year"}
                            views={["year", "month", "day"]}
                            onChange={(newValue) => {
                                setDOB(
                                    newValue &&
                                    moment.tz(newValue, 'UTC').endOf('day').toDate()
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    id="dob"
                                    name={'dob'}
                                    value={dob}
                                    {...params}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    margin={'normal'}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <MaskedMaterialTextField
                        getInputRef={last4}
                        format="####"
                        fullWidth
                        id="last4"
                        name={'last4'}
                        InputLabelProps={{ shrink: true }}
                        label={t('auth:identity.last4')}
                        margin={'normal'}
                        value={last4}
                        onChange={handleChangeLast4}
                        onKeyPress={onKeyPress}
                    />
                    <SparkButton
                        sx={{ mt: 2, mb: 1 }}
                        color="primary"
                        fullWidth
                        variant="contained"
                        onClick={handleVerifyIdentity}
                        disabled={!isFormValid}
                        loading={!!identityStore.identityLoading}
                    >
                        {t('global:continue')}
                    </SparkButton>
                    <Typography textAlign="center" color="common.goldenrod" variant="body2">{t('auth:identity.ensureCorrect')}</Typography>
                </>
            }
            <ErrorDialog
                isOpen={showErrorDialog}
                showFailedContent={Boolean(identityStore.identityError) || eligibilityError || identityStore.verificationsAttempts > 2}
                showFailedButton={eligibilityError || identityStore.verificationsAttempts > 2}
                dob={dob!}
                last4={last4}
                step={1}
            />
        </Box>
    )
}

export default observer(EnterDOB);