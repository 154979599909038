import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box, Container, List, ListItem, styled, Typography } from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import SendIcon from '@mui/icons-material/Send';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import moment from 'moment';
import FullPageLoader from '../components/FullPageLoader';
import { useStores } from '../../../hooks/use-stores';

const Wrapper = styled(Container)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        maxWidth: '500px'
    }
}));

const RewardsContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(91.26deg,  #FF56A9 0%, #FFAA6C 85.28%)',
    borderRadius: '20px',
    padding: '14px',
    minHeight: '190px',
    maxWidth: '400px',
    margin: '0 auto'
}));

const TransactionList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

interface TransactionItemProps {
    amount: number;
    currency: string;
    transactionId: string;
    time: string;
    description: string;
    type: string;
}

const TransactionItem = (props: TransactionItemProps) => {
    let icon, typeSummary, typeColor;

    switch (props.type) {
        case 'deposit':
            typeColor = 'green'
            typeSummary = 'Gift Card Rewards'
            icon = <CallReceivedIcon fontSize="small" sx={{ verticalAlign: "middle" }} />;
            break;
        case 'withdrawal':
        default:
            typeColor = 'red'
            typeSummary = 'Sparks Redeemed'
            icon = <SendIcon fontSize="small" sx={{ verticalAlign: "middle" }} />;
    }

    return (<ListItem sx={{ padding: '20px', marginBottom: '8px', borderBottom: '1px solid #222222' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box>
                <Typography sx={{ fontSize: '12px' }}>{moment(props.time).format('lll')}</Typography>
                <Box display="flex" margin="0px 5px" alignItems="center">
                    <Box display="inline" color={typeColor} sx={{ marginRight: '4px' }}>{icon}</Box>
                    <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>{typeSummary}</Typography>
                </Box>
                <Typography sx={{ fontSize: '12px', marginTop: '4px' }}>{props.description}</Typography>
            </Box>
            <Box>
                <Typography sx={{ fontWeight: 'bold' }} color={typeColor}>{props.amount.toLocaleString('fullwide', { useGrouping: true, maximumSignificantDigits: 10 })} {props.currency}</Typography>
            </Box>
        </Box>
    </ListItem>
    );
}

const SparkRewards = () => {
    const { t } = useTranslation();
    const { walletStore } = useStores();
    const [loading, setLoading] = useState(true);

    const load = async () => {
        setLoading(true);
        await Promise.all([walletStore.loadTransactions(), walletStore.loadBalances()]);
        setLoading(false);
    }

    useEffect(() => {
        load();
    }, []);

    return (
        <Wrapper>
            {loading ? <FullPageLoader /> :
                <>
                    <RewardsContainer>
                        <Box display="flex" sx={{ marginBottom: 2 }}>
                            <Box sx={{ marginRight: '6px' }}><WorkspacePremiumIcon /></Box>
                            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{t('giftCards:rewards.sparkRewards')}</Typography>
                        </Box>
                        <Box sx={{ position: 'absolute', bottom: '20px' }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{t('giftCards:rewards.balance')}</Typography>
                            <Box display="flex">
                                <Typography sx={{ letterSpacing: '-1px', paddingRight: .5, fontWeight: 'bold', fontSize: '40px' }}>
                                    {walletStore.balances?.sparks ? Math.round(walletStore.balances?.sparks) : 0}
                                </Typography>
                            </Box>
                        </Box>
                    </RewardsContainer>
                    <Box padding="10px 0px">
                        <Typography paragraph fontSize="13px">
                            {t('giftCards:rewards.info')}
                        </Typography>
                    </Box>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{t('giftCards:rewards.transactions')}</Typography>
                    <TransactionList>
                        {walletStore.transactions.length > 0 ? walletStore.transactions.map((transaction) =>
                            <TransactionItem
                                amount={Math.round(transaction.amount)}
                                currency={transaction.currency}
                                transactionId={transaction.transactionId}
                                time={transaction.time}
                                description={transaction.description}
                                type={transaction.type}
                            />
                        ) :
                            <Typography variant="body2">{t('giftCards:rewards.noTransactions')}</Typography>
                        }
                    </TransactionList>
                </>
            }
        </Wrapper>
    )
}

export default observer(SparkRewards);