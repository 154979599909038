import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLottie } from 'lottie-react';
import successAnimation from '../../lottie/success.json';
import { BottomContainer, MainContainer } from '../../components/TopBottomLayout';
import GradientText from '../../components/GradientText';
import { BLUE_GRADIENT } from '../../constants';
import { Typography } from '@mui/material';
import SparkButton from '../../components/SparkButton';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../hooks/use-stores';

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const AccountCreationSuccess = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { authStore } = useStores();
    const { View } = useLottie(defaultOptions);

    const goHome = () => {
        authStore.signinCurrentUser();
        navigate('/giftcards/shop');
    }

    return (
        <MainContainer minHeight="550px">
            {View}
            <GradientText
                textAlign="center"
                variant="h3"
                fontWeight="bold"
                gradientColor={BLUE_GRADIENT}>
                {t('createAccount:success.title')}
            </GradientText>
            <Typography
                mt={1}
                textAlign="center"
            >
                {t('createAccount:success.description')}
            </Typography>
            <BottomContainer>
                <SparkButton
                    onClick={goHome}
                >
                    {t('global:next')}
                </SparkButton>
            </BottomContainer>
        </MainContainer>
    )
}

export default AccountCreationSuccess;