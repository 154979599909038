import React from 'react';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { Box, styled, Typography } from '@mui/material';

interface WrapperProps {
    isSmall?: boolean
}

const Wrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isSmall"
})<WrapperProps>(({ isSmall, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: isSmall ? '4px 8px' : '6px 8px',
    borderRadius: '10px',
    backgroundColor: theme.palette.common.darkgrey,
    color: 'white',
    boxShadow: 'rgba(255, 255, 255, .04) 0px 4px 12px'
}));

interface SparkRewardsButtonProps {
    rewards: number;
    size?: "small" | "medium"
}

const SparkRewardsButton = (props: SparkRewardsButtonProps) => {
    const isSmall = props.size == 'small';

    return (
        <Wrapper isSmall={isSmall}>
            <LoyaltyIcon sx={{ color: 'green', marginRight: '-2px' }} fontSize={props.size || 'small'} />
            <Typography fontWeight="bolder" marginLeft="6px" fontSize={isSmall ? 12 : 14} >{props.rewards}</Typography>
        </Wrapper>
    )
}

export default SparkRewardsButton;