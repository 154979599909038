import * as React from 'react';
import TextField from './TextField';
import { NumericFormat, NumericFormatProps} from 'react-number-format';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    prefix: string;
}

const NumberFormatCustom = React.forwardRef<NumericFormatProps<string>, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, ...other } = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                valueIsNumericString
                prefix={props.prefix}
            />
        );
    }
);

export default (props: any) => {
    return (
        <div>
            <TextField
                {...props}
                InputProps={{
                    inputComponent: NumberFormatCustom as any,
                }}
                inputProps={{
                    format: props.format,
                    mask: props.mask,
                    allowEmptyFormatting: props.allowEmptyFormatting,
                    prefix: props.prefix,
                }}
            />
        </div>
    );
};
