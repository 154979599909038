import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Paper, Typography } from '@mui/material';
import PaymentMethod from '../../../../stores/models/PaymentMethod';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../../../../utils/helpers';

const PaymentMethodsWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: '20px',
    padding: .5,
    margin: 0,
}));

const AllMethods = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'grid',
    gridAutoRows: '1fr',
    gridGap: '10px',
}));

const PaymentMethodItem = styled(Paper)(({ theme }) => ({
    width: '100%',
    cursor: 'pointer',
    padding: 10,
    background: theme.palette.common.darkgrey,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #333333'
}));

const LeftSideText = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold'
}));

interface Props {
    paymentMethods: PaymentMethod[];
    onSelectPaymentMethod: (method: PaymentMethod) => void;
}

const PaymentMethods = (props: Props) => {
    const { t } = useTranslation();

    return (
        <PaymentMethodsWrapper>
            <AllMethods>
                {
                    Object.values(props.paymentMethods).map(method =>
                        <PaymentMethodItem key={method.name} onClick={() => props.onSelectPaymentMethod(method)}>
                            <Box display="flex" alignItems="center" sx={{ width: '100%', position: 'relative' }}>
                                <Box sx={{ marginRight: 1, fontSize: 30 }}>
                                    <img src={method.logo} width="50" style={{ verticalAlign: 'middle' }} />
                                </Box>
                                <Box display="flex" flexDirection="column">
                                    <LeftSideText fontWeight="bold">{method.name}</LeftSideText>
                                    {method.balance && <Typography color={'common.midgrey'} fontSize="15px">{t('giftCards:checkout.availableBalance', { balance: currencyFormatter(method.balance) })}</Typography>}
                                    {method.promottext && <Typography fontSize="14px" fontWeight={'bold'} color={'common.mintgreen'}>{method.promottext}</Typography>}
                                </Box>
                            </Box>
                            <Box marginRight={'10px'}>
                                <Button>
                                    <Typography fontWeight="bold" textTransform="none">{t('global:select')}</Typography>
                                </Button>
                            </Box>
                        </PaymentMethodItem>
                    )
                }
            </AllMethods>
        </PaymentMethodsWrapper >
    )
}

export default PaymentMethods;