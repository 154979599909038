import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { NumericFormat } from "react-number-format";
import { Box, Container, Divider, List, ListItem, Typography } from "@mui/material";
import { styled, useTheme } from '@mui/material/styles';
import Keypad from "./Keypad";
import { currencyFormatter } from "../../../../utils/helpers";

const OutOfStockContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30%',
}));

const AmountRangeContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.darkgrey,
    borderRadius: 20,
    margin: '0 auto',
    width: 'max-content',
    padding: theme.spacing(1.25)
}));

const AmountWarningText = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.lightgrey,
    marginTop: '4px',
    textAlign: 'center',
}));

const StaticPriceList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
}));

interface StaticPriceItemProps {
}

const StaticPriceItem = styled(ListItem)<StaticPriceItemProps>(({ theme, }) => ({
    display: 'flex',
    width: 'calc(25% - 8px)',
    justifyContent: 'center',
    fontSize: '16px',
    borderRadius: '20px',
    fontWeight: 'bold',
    border: `1px solid rgba(255,255,255,.6)`,
    color: 'white',
    cursor: 'pointer',
    height: 'auto',
    margin: '4px',

    '&::before': {
        content: '""',
        display: 'block',
        paddingTop: '100%',
    },

    [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        margin: '8px',
        width: 'calc(25% - 16px)',
    }
}));

const NumberFormatCustom = React.forwardRef((props, ref) => {
    //@ts-ignore
    const { inputRef, onChange, currency, runShakeAnimation, color, ...other } = props

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            decimalScale={2}
            fixedDecimalScale
            onValueChange={(values: any) => {
                onChange({
                    target: {
                        value: values.value,
                        //@ts-ignore
                        name: other.name,
                    },
                })
            }}
            thousandSeparator
            prefix="$"
            displayType={'text'}
            renderText={(formattedValue: string) => <Box component="span" sx={{
                "@keyframes shake": {
                    '0%': { transform: 'translate(1px, 1px) rotate(0deg)' },
                    '10%': { transform: 'translate(-1px, -2px) rotate(- 1deg)' },
                    '20%': { transform: 'translate(-3px, 0px) rotate(1deg)' },
                    '30%': { transform: 'translate(3px, 2px) rotate(0deg)' },
                    '40%': { transform: 'translate(1px, -1px) rotate(1deg)' },
                    '50%': { transform: 'translate(-1px, 2px) rotate(- 1deg)' },
                    '60%': { transform: 'translate(-3px, 1px) rotate(0deg)' },
                    '70%': { transform: 'translate(3px, 1px) rotate(- 1deg)' },
                    '80%': { transform: 'translate(-1px, -1px) rotate(1deg)' },
                    '90%': { transform: 'translate(1px, 2px) rotate(0deg)' },
                    '100%': { transform: 'translate(1px, -2px) rotate(- 1deg)' }
                },
                "@keyframes pulsate": {
                    from: {
                        opacity: 1,
                        transform: "scale(1)"
                    },
                    to: {
                        opacity: 0,
                        transform: "scale(2)"
                    }
                },
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                fontSize: 50,
                lineHeight: '80px',
                padding: '6px',
                letterSpacing: 2,
                fontWeight: 'bold',
                margin: '0 auto',
                background: runShakeAnimation ? '#ff4747' : '-webkit-linear-gradient(45deg, #0077ea, #AFEEEE)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                animation: runShakeAnimation ? "shake .25s" : 'initial',
                animationIterationCount: 1,
            }}>
                {/* Renders the currency superscript */}
                < Box component="span" sx={{ fontWeight: 'bold', }}>
                    <Box component="span" sx={{ color }}>{formattedValue}</Box>
                    <Box component="span" sx={{
                        fontSize: 16,
                        verticalAlign: 'super',
                        marginLeft: 1,

                    }}>{currency || 'USD'}</Box>
                </Box >
            </Box >}
        />)

});

interface Props {
    isValidPrice: boolean
    max: string;
    min: string;
    valueInCents: string;
    setValueInCents: (newValue: string) => void;
    isStaticPrice?: boolean;
    staticPrices?: string[]; // a list of in stock static prices
    currency: string;
    isOutOfStock: boolean; // if true, ranged or static prices are out of stock and cannot be purchased
    incorrectPrice: boolean;
}

const FormattedInputs = (props: Props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const getColorOfInput = () => {
        let color = theme.palette.common.rose;
        if (props.valueInCents == '0') {
            color = '#666666';
        } else if (props.isValidPrice) {
            color = 'white';
        }

        return `${color}!important`;
    }

    const onStaticPriceSelected = (priceInDollars: any) => {
        props.setValueInCents(String(priceInDollars))
    }

    return (
        <Container disableGutters>
            {/* Show the amount input only if we have some stock (or are inputting an amount using the keypad) */}
            {!props.isOutOfStock && <TextField
                sx={{
                    width: '100%',
                    border: 0,
                    marginBottom: -1,
                }}
                value={parseFloat(props.valueInCents) / 100}
                disabled
                InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    disableUnderline: true,
                }}
                inputProps={{
                    currency: props.currency,
                    color: getColorOfInput(),
                    runShakeAnimation: props.incorrectPrice
                }}
                variant="standard"
            />
            }
            <Divider sx={{ marginBottom: 2, width: '100%' }} />
            {
                props.isOutOfStock ?
                    <OutOfStockContainer>
                        <Typography variant="h4" color="red" fontWeight="bold">{t('giftCards:review.outOfStock')}</Typography>
                        <Typography>{t('giftCards:review.checkBack')}</Typography>
                    </OutOfStockContainer>
                    :
                    props.isStaticPrice ?
                        <StaticPriceList>
                            {props.staticPrices?.map(
                                price => {
                                    const priceInCents = parseFloat(price) * 100;

                                    return <StaticPriceItem
                                        key={price}
                                        onClick={() => onStaticPriceSelected(priceInCents)}>
                                        ${price}
                                    </StaticPriceItem>
                                }
                            )
                            }
                        </StaticPriceList>
                        : <>
                            <AmountRangeContainer>
                                <AmountWarningText variant="body2">{t('giftCards:review.amountRange', { min: currencyFormatter(props.min), max: currencyFormatter(props.max) })}</AmountWarningText>
                            </AmountRangeContainer>
                            <div style={{ marginTop: '10px' }} />
                            <Keypad
                                onKeyPressed={props.setValueInCents}
                                value={props.valueInCents}
                            />
                        </>
            }
        </Container>
    );
}

export default FormattedInputs;