import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Box, Container, styled, TextField, Typography } from '@mui/material';
import { useStores } from '../../../../hooks/use-stores';

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold'
}));

const WarningHeading = styled(Heading)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold'
}));

const SectionWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));

const FormWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

const PrivacyAndSecurity = () => {
    const { t } = useTranslation();
    const { authStore } = useStores();

    return (
        <Container sx={{ maxWidth: { xs: '600px' } }}>
            <Typography fontSize="30px" fontWeight="bold">{t('profile:privacy.title')}</Typography>
            <Typography sx={{ fontSize: '13px', color: 'common.goldenrod' }}>{t('profile:infoChangeWarning')}</Typography>
            <SectionWrapper>
                <Heading>
                    {t('profile:privacy.email')}
                </Heading>
                <FormWrapper>
                    <TextField
                        disabled
                        value={authStore.userEmail}
                        type="email"
                    />
                </FormWrapper>
            </SectionWrapper>
            <SectionWrapper>
                <Heading>
                    {t('profile:privacy.password')}
                </Heading>
                <FormWrapper>
                    <TextField
                        disabled
                        value={'************'}
                        type="password"
                    />
                </FormWrapper>
            </SectionWrapper>
        </Container>
    )
}

export default observer(PrivacyAndSecurity);