
import RestService from './RestService';

export default class WalletService extends RestService {
    constructor() {
        super('/api/v1/wallet');
    }

    async getWalletBalances() {
        return this.get(`${this.baseUrl}/balance`);
    }

    async getTransactions() {
        return this.get(`${this.baseUrl}/paymentsAndPendingOrders?orderType=sparks`);
    }

    async createWallet() {
        return this.post(`${this.baseUrl}/account`);
    }
}
