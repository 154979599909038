import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Alert, Avatar, Box, Button, Container, Dialog, IconButton, List, ListItem, styled, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useStores } from '../../../hooks/use-stores';
import FullPageLoader from '../components/FullPageLoader';
import GiftCard from '../../../stores/models/GiftCard';
import Search from '../../../components/SimpleSearch';
import { currencyFormatter } from '../../../utils/helpers';
import PurchasedCardInfo from '../components/PurchasedCardInfo';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const GiftCardList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0 auto'
}))

const GiftCardListItemWrapper = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0px',
    borderBottom: '1px solid #333333',
    cursor: 'pointer',
}))

const GiftCardItemAvatar = styled(Avatar)(({ theme }) => ({
    width: 36,
    height: 36,
    marginRight: 8,
    [theme.breakpoints.up('sm')]: {
        width: 56,
        height: 56
    },
}));

interface GiftCardItemProps {
    logo: string;
    orderDate: string;
    name: string;
    amount: number;
    onMarkCard: (e: React.MouseEvent) => void;
    onMarkText: string;
    onCardClick: (e: React.MouseEvent) => void;
}

const GiftCardItem = (props: GiftCardItemProps) => (
    <GiftCardListItemWrapper onClick={(e: React.MouseEvent) => props.onCardClick(e)}>
        <Box display="flex" alignItems="center">
            <GiftCardItemAvatar src={props.logo} />
            <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography sx={{ fontSize: '11px' }}>{moment(props.orderDate).format('lll')}</Typography>
                <Typography sx={{ fontSize: { xs: '14px', md: '18px' }, fontWeight: 'bolder' }}>{props.name}</Typography>
            </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Typography sx={{ fontWeight: 'bolder', fontSize: '22px', marginBottom: '-4px' }}>{currencyFormatter(props.amount)}</Typography>
            <Button onClick={(e: React.MouseEvent) => props.onMarkCard(e)} sx={{ paddingRight: 0 }}>
                <Typography sx={{ textAlign: 'end', fontSize: '12px', textTransform: 'none', color: 'gold' }}>{props.onMarkText}</Typography>
            </Button>
        </Box>
    </GiftCardListItemWrapper>
)

const TAB_MAP = new Map<number, 'active' | 'archived'>([
    [0, 'active'],
    [1, 'archived']
]);

const MyGiftCards = () => {
    const { t } = useTranslation();
    const { giftcardStore } = useStores();

    const [loading, setLoading] = useState<boolean>(true);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedCard, setSelectedCard] = useState<GiftCard | null>(null);
    const [currentTab, setCurrentTab] = useState<number>(0);

    const onSearchQueryUpdated = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleTabSelect = (event: React.SyntheticEvent, newTab: number) => {
        setCurrentTab(newTab);
        loadGiftCards(newTab);
    };

    const handleModalOpen = useCallback(() => setModalOpen(true), [modalOpen]);
    const handleModalClose = useCallback(() => setModalOpen(false), [modalOpen]);

    const openCardInfo = (e: React.MouseEvent, giftCard: GiftCard) => {
        e.stopPropagation();
        setSelectedCard(giftCard);
        handleModalOpen();
    }

    const markCardAsUsed = async (e: React.MouseEvent, giftCardID: string) => {
        e.stopPropagation();
        await giftcardStore.markCardAsUsed(giftCardID);

        const updatedCard = giftcardStore.myCards.find(c => c.giftCardID == giftCardID);
        if (updatedCard) {
            updatedCard.archived = true;
        }
    }

    const markCardAsActive = async (e: React.MouseEvent, giftCardID: string) => {
        e.stopPropagation();

        await giftcardStore.markCardAsActive(giftCardID);
        const updatedCard = giftcardStore.myCards.find(c => c.giftCardID == giftCardID);
        if (updatedCard) {
            updatedCard.archived = false;
        }
    }

    const searchedCards = () => {
        const regex = new RegExp(searchQuery, 'i');
        return giftcardStore.myCards.filter((card: GiftCard) => card.merchantName.match(regex));
    }

    const activeCards = () => {
        const activeCards = giftcardStore.myCards.filter(card => !card.archived);
        return intersection(activeCards, searchedCards()); // we want to show all active cards that pass the search filter
    }

    const archivedCards = () => {
        const archivedCards = giftcardStore.myCards.filter(card => card.archived);
        return intersection(archivedCards, searchedCards()); // we want to show all archived cards that pass the search filter
    }

    const loadGiftCards = async (tab: number) => {
        setLoading(true);
        await giftcardStore.getMyGiftCards(TAB_MAP.get(tab) || 'active');
        setLoading(false);
    }

    useEffect(() => {
        loadGiftCards(currentTab);
    }, []);

    return (
        loading ? <FullPageLoader /> : (
            <Container sx={{ width: '100%', padding: 2, maxWidth: { xs: '600px' }, }}>
                <Search
                    searchQuery={searchQuery}
                    onSearchQueryChanged={onSearchQueryUpdated}
                    placeholder={t("myCards:searchBy")}
                />
                <Box sx={{ width: '100%', borderBottom: 1, marginTop: 1, borderColor: 'divider' }}>
                    <Tabs sx={{ width: '100%' }} value={currentTab} onChange={handleTabSelect}>
                        <Tab sx={{ minWidth: '50%', fontWeight: 'bold', textTransform: 'none' }} label={t('myCards:active')} />
                        <Tab sx={{ minWidth: '50%', fontWeight: 'bold', textTransform: 'none' }} label={t('myCards:used')} />
                    </Tabs>
                </Box>
                {/* Minor TODO: abstract TabPanel */}
                <TabPanel value={currentTab} index={0}>
                    {activeCards().length > 0 ? <GiftCardList>
                        {activeCards().map((card: GiftCard) =>
                            <GiftCardItem
                                key={card.orderID}
                                logo={card.logo}
                                orderDate={card.orderDate}
                                name={card.merchantName}
                                onMarkCard={(e: React.MouseEvent) => markCardAsUsed(e, card.giftCardID)}
                                onMarkText={t('myCards:markAsUsed')}
                                amount={card.amount}
                                onCardClick={(e: React.MouseEvent) => openCardInfo(e, card)}
                            />
                        )
                        }
                    </GiftCardList>
                        : <Alert severity="info">{t('myCards:noActive')}</Alert>
                    }
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                    {archivedCards().length > 0 ? <GiftCardList>
                        {archivedCards().map((card: GiftCard) =>
                            <GiftCardItem
                                key={card.orderID}
                                logo={card.logo}
                                orderDate={card.orderDate}
                                name={card.merchantName}
                                onMarkCard={(e: React.MouseEvent) => markCardAsActive(e, card.giftCardID)}
                                onMarkText={t('myCards:markAsActive')}
                                amount={card.amount}
                                onCardClick={(e: React.MouseEvent) => openCardInfo(e, card)}
                            />
                        )
                        }
                    </GiftCardList>
                        : <Alert severity="info">{t('myCards:noUsed')}</Alert>
                    }
                </TabPanel>
                <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                    PaperProps={{
                        sx: { borderRadius: '10px', backgroundColor: 'common.darkgrey', backgroundImage: 'none', maxHeight: '75%' }
                    }}
                >
                    <Toolbar sx={{ paddingTop: 2, paddingBottom: 2 }}>
                        <Typography sx={{ ml: 0, flex: 1, fontWeight: 'bold' }} fontSize={"16px"} component="div">
                            {selectedCard?.merchantName}
                        </Typography>
                        <IconButton
                            sx={{ marginLeft: 1 }}
                            edge="start"
                            color="inherit"
                            onClick={handleModalClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <Box padding="10px">
                        {selectedCard && <PurchasedCardInfo
                            backgroundColor={selectedCard.backgroundColor}
                            orderNumber={selectedCard.orderNumberDetail}
                            securityCode={selectedCard.securityCode}
                            logo={selectedCard.logo}
                            merchantName={selectedCard?.merchantName}
                            amount={selectedCard.amount}
                            barcode={selectedCard.barcode}
                            fontColor={selectedCard.fontColor}
                            pin={selectedCard.pin}
                            deliveryURL={selectedCard.deliveryURL}
                            redemptionInstructionsHtml={selectedCard.redemptionInstructionsHtml}
                        />
                        }
                    </Box>
                </Dialog>
            </Container>
        )
    )
}

export default observer(MyGiftCards);