import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    Slide,
    styled,
    Toolbar,
    Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Close, Warning } from '@mui/icons-material';
import PlaidLink from '../components/PlaidLink';
import { useStores } from '../../../../hooks/use-stores';
import FullPageLoader from '../../components/FullPageLoader';
import BankAccount from '../../../../stores/models/BankAccount';
import SparkButton, { BlueSparkButton } from '../../../../components/SparkButton';
import IDVerify from '../../../IDVerify';

const DarkBackgroundDialog = styled(Dialog, {
    shouldForwardProp: (prop) => prop !== "darkBackground"
})(({ theme }) => ({
    background: 'black',
    marginBottom: 40
}));

const BankDialog = (props: any) => {
    return <DarkBackgroundDialog {...props} PaperProps={{ sx: { borderRadius: "10px", margin: '20px', color: props.darkBackground ? 'white' : 'black', backgroundColor: props.darkBackground ? 'black' : 'white', width: '100%', minHeight: '150px' } }}>
        {props.children}
    </DarkBackgroundDialog>
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LinkedBanks = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { commonStore, identityStore, plaidStore } = useStores();

    const [identityModalOpen, setIdentityModalOpen] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>('')
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [plaidOpen, setPlaidOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(searchParams.get('forcePlaid') == 'true');

    const { accounts, attachingAccounts, removingAccount, error } = plaidStore;

    const updateAccount = searchParams.get('updateAccount');
    const inOauthFlow = searchParams.get('oauth_state_id');
    const hasCompletedIdentityVerification = searchParams.get('verification');

    const handleConfirmPlaidDialogOpen = useCallback(async () => {
        await identityStore.getVerifyStatus(true);

        if (!identityStore.proveVerified) {
            setIdentityModalOpen(true);
        } else {
            setConfirmDialogOpen(true);
        }
    }, [identityModalOpen, confirmDialogOpen]);

    const handleCloseIdentityModal = useCallback(() => {
        setIdentityModalOpen(false);
    }, [identityModalOpen]);

    const handleConfirmPlaidDialogClose = useCallback(() => {
        setConfirmDialogOpen(false);
    }, [confirmDialogOpen]);

    const openPlaidWindow = useCallback(async () => {
        setPlaidOpen(true);
    }, [plaidOpen]);

    const toggleTermsAgreement = useCallback(() => {
        setAgreedToTerms(!agreedToTerms);
    }, [agreedToTerms]);

    const load = async () => {
        if (hasCompletedIdentityVerification == 'true') {
            commonStore.success(t('auth:identity.verifySuccess'));
        }

        // This check ensures that the loading spinner won't turned off if we're in the ouath flow.
        // This is the behavior we want as we don't want the user to see the page while the flow is happening in the background
        if (!inOauthFlow) {
            // If updateAccount exists, we need to re-auth the users account in Plaid's update mode
            if (updateAccount) {
                await plaidStore.loadUpdateToken(updateAccount);
                setToken(plaidStore.storedLinkToken);

                // we want the update mode to open automatically and bypass the modal
                if (plaidStore.inUpdateMode) {
                    openPlaidWindow();
                }
            } else {
                await Promise.all([plaidStore.loadToken(), plaidStore.loadAccounts()]);
                setToken(plaidStore.storedLinkToken);
            }

            setLoading(false);
        }
    }

    useEffect(() => {
        // Reset the page to its defaults if we exit the plaid flow (reset is true onExit from PlaidLink)
        if (searchParams.get('reset') == 'true') {
            setConfirmDialogOpen(false);
            setAgreedToTerms(false);
            setPlaidOpen(false);
            setSearchParams({ reset: 'false' });
        }
    }, [searchParams])

    useEffect(() => {
        load();
    }, []);

    const handleAccountUnlink = async (accountId: string) => {
        await plaidStore.unlinkAccount(accountId);
        if (!plaidStore.error) {
            commonStore.success(t('auth:addBank.unlinkSuccess'))
        } else {
            commonStore.warn(t('auth:addBank.unlinkFailed'))
        }
    };

    const allAccounts = useMemo(() => {
        return accounts.flat();
    }, [accounts])

    const lookupError = (errorString: string) => {
        if (errorString == 'account_exists') {
            return t('auth:addBank.linkFailedExists')
        } else if (errorString == 'account_linked') {
            return t('auth:addBank.linkFailedMultiple')
        } else {
            return t('global:networkError');
        }
    }

    return (
        <Container sx={{ flexBasis: '100%', maxWidth: { 'xs': '650px' }, }}>
            {(loading || attachingAccounts || removingAccount) ?
                <FullPageLoader
                /> :
                (
                    <>
                        {error && (
                            <Box sx={{ marginBottom: '10px' }}>
                                <Alert severity="error">{lookupError(error)}</Alert>
                            </Box>
                        )}
                        <Box>
                            <Box display="flex" justifyContent="space-between" paddingBottom={'20px'}>
                                <Box>
                                    <Typography fontWeight="bold">{t('auth:addBank.yourAccounts')}</Typography>
                                </Box>
                            </Box>
                            <List>
                                {allAccounts.length > 0 ? accounts.map((accountGroup: any, index: number) => (
                                    <ListItem
                                        key={index}
                                        sx={{ padding: '0px', display: 'flex', justifyContent: 'space-between', paddingBottom: '15px', borderBottom: '1px solid #333333' }}
                                    >
                                        <Box display="flex" flexDirection="column">
                                            {accountGroup.map((account: BankAccount) => {
                                                return (
                                                    <Box marginBottom="4px" key={account.account_id}>
                                                        <Typography fontWeight="bold">
                                                            {account.name}
                                                        </Typography>
                                                        <Typography fontSize="12px">
                                                            {account.official_name}
                                                        </Typography>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                        <Button onClick={() => handleAccountUnlink(accountGroup[0].account_id)} disabled={Boolean(removingAccount)}>
                                            <Typography fontWeight="bold" fontSize="12px">{t('auth:addBank.remove')}</Typography>
                                        </Button>
                                    </ListItem>
                                )) :
                                    <Box display="flex" alignItems="center" justifyContent="center" marginTop="20px" paddingBottom="15px" borderBottom="1px solid #333333">
                                        <Typography textAlign="center" fontSize="13px">{t('auth:addBank.noAccounts')}</Typography>
                                    </Box>
                                }
                            </List>
                            <Box width="95%" maxWidth={'550px'} sx={{ position: 'fixed', bottom: '150px', left: '50%', transform: 'translateX(-50%)' }}>
                                {/* 
                                    Force hide the Plaid link as we need it loaded for Plaid stuff to run, but invisible because we'll be using 
                                    a confirmation modal to kick it off. Changing the plaidOpen variable to true is what causes the Plaid flow
                                    to open so the user can enter their bank details.
                                */}
                                {token && <PlaidLink linkToken={token} forceOpen={plaidOpen} />}

                                {/* Only show the Add New Account button if the user has no accounts connected as we only permit 1 per user */}
                                {allAccounts.length < 1 && <SparkButton onClick={handleConfirmPlaidDialogOpen} disabled={!token || Boolean(identityStore.gettingVerifyStatus)} loading={Boolean(identityStore.gettingVerifyStatus)}>
                                    {t('auth:addBank.connect')}
                                </SparkButton>
                                }
                            </Box>
                            {/* If the user has an account, show a button to take them direct to the funding page with a sexy blue gradient */}
                            {allAccounts.length >= 1 && <Box width="95%" maxWidth={'550px'} sx={{ position: 'fixed', bottom: '100px', left: '50%', transform: 'translateX(-50%)' }}>
                                <BlueSparkButton onClick={() => navigate('/giftcards/profile/fund')}>
                                    {t('auth:addBank.deposit')}
                                </BlueSparkButton>
                            </Box>
                            }
                        </Box>
                        {/* /* Plaid Confirmation Dialog */}
                        <BankDialog onClose={handleConfirmPlaidDialogClose} open={confirmDialogOpen} sx={{ background: 'black', borderRadius: "20px" }} PaperProps={{ sx: { margin: '20px', color: 'black', backgroundColor: 'white' } }}>
                            <DialogTitle>
                                <Typography fontSize="20px" fontWeight="bold" textAlign="center">{t('auth:addBank.addBank')}</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                    <Warning sx={{ color: 'common.goldenrod', fontSize: '80px' }} />
                                    <Box display="flex" marginBottom="20px">
                                        <Checkbox
                                            checked={agreedToTerms}
                                            onClick={toggleTermsAgreement}
                                            disableRipple
                                            sx={{
                                                color: 'gray',
                                                '&.Mui-checked': {
                                                    color: 'gray',
                                                },
                                                marginRight: '4px'
                                            }}
                                        />
                                        <Box margin="14px 0px">
                                            <Typography fontSize={'14px'} dangerouslySetInnerHTML={{ __html: t('auth:addBank.agreement') }} />
                                        </Box>
                                    </Box>
                                    <SparkButton onClick={openPlaidWindow} disabled={!agreedToTerms}>
                                        {t('global:continue')}
                                    </SparkButton>
                                </Box>
                            </DialogContent>
                        </BankDialog>
                        {/* Id Verify Dialog */}
                        <BankDialog darkBackground open={identityModalOpen} TransitionComponent={Transition}>
                            <Toolbar sx={{ paddingBottom: 0, display: 'flex', justifyContent: 'flex-end', }}>
                                <IconButton
                                    sx={{ marginLeft: 1 }}
                                    edge="start"
                                    color="inherit"
                                    onClick={handleCloseIdentityModal}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                            </Toolbar>
                            <DialogContent sx={{ pt: 0, width: '100%' }}>
                                <IDVerify />
                            </DialogContent>
                        </BankDialog>
                    </>
                )
            }
        </Container>
    );
};

export default observer(LinkedBanks);