import React, { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Alert, Box, Container, Typography } from '@mui/material';
import { auth } from '../SparkFirebase';
import BackButton from '../components/BackButton';
import SparkButton from '../components/SparkButton';
import RoundTextField from '../components/RoundTextField';
import { isValidEmail } from '../utils/helpers';
import { useStores } from '../hooks/use-stores';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { commonStore } = useStores();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');

    const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (emailError) {
            setEmailError('');
        }

        setEmail(e.target.value);
    }, [email, emailError])

    const resetPassword = useCallback(async () => {
        if (isValidEmail(email)) {
            try {
                setLoading(true);
                // No need to put this in the store, this is super simple
                await sendPasswordResetEmail(auth, email);

                navigate('/signin');
                commonStore.success(t('auth:forgotPassword.linkSent'));
            } catch (e: any) {
                setEmailError(t(`auth:fbErrorCodes.${e.code}`, t('global:networkError')))
            } finally {
                setLoading(false);
            }
        } else {
            setEmailError(t('auth:auth/invalid-email'));
        }
    }, [email]);

    return (
        <Container maxWidth="sm" sx={{ height: '100%' }}>
            <BackButton />
            <Box display="flex" height={'calc(100% - 75px)'} flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
                {emailError && <Alert severity="error">{emailError}</Alert>}
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Typography component="h1" variant="h5" fontWeight="bold">{t('auth:forgotPassword.title')}</Typography>
                    <Typography variant="body2" textAlign="center">{t('auth:forgotPassword.description')}</Typography>
                </Box>
                <RoundTextField
                    InputProps={{ sx: { fontWeight: 'bold' } }}
                    value={email}
                    onChange={handleEmailChange}
                    placeholder={t('auth:forgotPassword:enterEmail')}
                    margin="normal"
                    required
                    fullWidth
                    label={t('global:email')}
                    type="email"
                    name="email"
                    autoComplete="email"
                    error={!!emailError}
                />
                <SparkButton onClick={resetPassword} loading={loading} disabled={loading || !isValidEmail(email) || !!emailError}>
                    {t('auth:forgotPassword.resetYourPassword')}
                </SparkButton>
            </Box>
        </Container>
    )

}
export default ForgotPassword;