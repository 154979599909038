import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Container, Paper, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useStores } from '../../../hooks/use-stores';
import PurchasedCardInfo from '../components/PurchasedCardInfo';

const Confirmation = () => {
    const { t } = useTranslation();
    const { walletStore, giftcardStore } = useStores();
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/giftcards/shop');
    }

    const { purchasedCardInfo } = giftcardStore;

    useEffect(() => {
        if (!purchasedCardInfo) {
            goHome();
        }

        // Update the user's balances so that their sparks are displayed correctly
        walletStore.loadBalances();
    }, [])

    return (
        <Container sx={{ minHeight: '100vh', height: '100%', maxWidth: { xs: '600px' } }}>
            <Paper sx={{
                padding: 2,
                borderRadius: '14px',
                backgroundColor: 'common.darkgrey',
                backgroundImage: 'none'
            }}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop="10px" marginBottom="20px" padding="0px 20px">
                    <CheckCircleIcon sx={{ fontSize: '140px', color: 'success.dark' }} />
                    <Typography fontWeight="bold" fontSize="30px">{t('giftCards:confirmation.success')}</Typography>
                    <Typography textAlign="center"
                        dangerouslySetInnerHTML={{ __html: t('giftCards:confirmation.purchasedCard', { merchant: giftcardStore.purchasedCardInfo?.merchant }) }}
                    />
                </Box>

                {purchasedCardInfo && <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <PurchasedCardInfo
                        backgroundColor={purchasedCardInfo.backgroundColor}
                        orderNumber={purchasedCardInfo.orderNumberDetail}
                        securityCode={purchasedCardInfo?.securityCode}
                        logo={purchasedCardInfo.logo}
                        merchantName={purchasedCardInfo.merchant}
                        amount={purchasedCardInfo.denomination}
                        barcode={purchasedCardInfo.barcode}
                        fontColor={purchasedCardInfo.fontColor}
                        currency={purchasedCardInfo.currency}
                        pin={purchasedCardInfo.pin}
                        deliveryURL={purchasedCardInfo.deliveryURL}
                        disclaimerHtml={purchasedCardInfo.disclaimerHtml}
                        redemptionInstructionsHtml={purchasedCardInfo.redemptionInstructionsHtml}
                    />
                </Box>
                }
            </Paper>
        </Container>
    )
}

export default observer(Confirmation);