import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    Container,
} from '@mui/material';
import { useStores } from '../../../../hooks/use-stores';
import PlaidLink from '../components/PlaidLink';
import FullPageLoader from '../../components/FullPageLoader';

/*
    This is the dedicated page for the Plaid Redirect URI specificed on the backend.
    
    The user will be redirected here once they have authenticated their credentials in both
    the Link and Update flows. We 
*/
const LinkedBanksRedirect = () => {
    const navigate = useNavigate();
    const { plaidStore } = useStores();

    const { storedLinkToken } = plaidStore;

    // this is an error - you should not get here without being redirected and having a 
    // link token in storage. If the redirect to /banks fails, the user will be sent to the
    // login page by default
    if (!storedLinkToken) {
        navigate('/giftcards/profile/banks');
        return null;
    }

    return (
        <Container>
            <FullPageLoader />
            <PlaidLink linkToken={storedLinkToken!} reinit={true} />
        </Container>
    );
};

export default observer(LinkedBanksRedirect);