import React, { useCallback } from 'react';
import { Box, Container, Dialog, Divider, Grid, IconButton, Slide, styled, Toolbar, Typography, } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import MerchantImage from '../../shared/MerchantImage';

interface InformationLinkProps {
    description: string;
}

const InformationLink = ({ description }: InformationLinkProps) =>
    <Box
        sx={{
            width: '100%',
            cursor: 'pointer',
            padding: '8px 0',
            alignItems: 'center',
            borderBottom: '1px solid #222222',
        }}
        display={'flex'}
        justifyContent={'space-between'}
    >
        <Typography
            sx={{
                fontSize: 15,
                fontWeight: 'bold',
                color: 'primary.main',
                textAlign: 'left'
            }}
        >
            {description}
        </Typography>
        <ChevronRightIcon fontSize="medium" htmlColor="primary.main" />
    </Box>;


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RestrictionBox = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    marginTop: 6,
    width: '100%'
}));

const RedemptionBox = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    padding: '2px 0',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
}));

interface InfoBoxProps {
    openRestrictionsInformation: () => void
    openRedemptionInstructions: () => void
}

const InfoBox = (props: InfoBoxProps) => {
    const { t } = useTranslation();
    return <>
        <RestrictionBox
            onClick={props.openRestrictionsInformation}
        >
            <InformationLink description={t('giftCards:restrictions')} />
        </RestrictionBox>
        <RedemptionBox onClick={props.openRedemptionInstructions}
        >
            <InformationLink description={t('giftCards:redemption')} />
        </RedemptionBox>
    </>
}

interface Props {
    cardBackgroundColor: string;
    cardImage: string
    merchantName: string
    description: string
    redemptionInstructions: string;
    terms: string
    hideImage?: boolean;
}

const CardPreview = (props: Props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState<boolean>(false);
    const [modalTitle, setModalTitle] = React.useState<string>('');
    const [instructions, setInstructions] = React.useState<string>('');

    const openRedemptionInstructions = () => {
        setModalTitle(t('giftCards:redemption'));
        setInstructions(props.redemptionInstructions);
        handleClickOpen();
    }

    const openRestrictionsInformation = () => {
        setModalTitle(t('giftCards:restrictions'));
        setInstructions(props.terms);
        handleClickOpen();
    }

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, [open]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [open]);

    return (
        <Container sx={{ margin: '0 auto', }} disableGutters>
            <Grid container>
                {!props.hideImage && props.cardImage && <Grid item xs={3}>
                    <MerchantImage
                        imageUrl={props.cardImage}
                        backgroundColor={props.cardBackgroundColor}
                    />
                </Grid>
                }
                <Grid item xs={props.hideImage ? 12 : 9} container sx={{ maxHeight: '100px', paddingLeft: props.hideImage ? 0 : 2, overflow: 'hidden' }}>
                    <Grid item xs={12}>
                        <Typography sx={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontSize: '18px',
                            fontWeight: 'bolder'
                        }}>{props.merchantName}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        <Box sx={{
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            lineHeight: 1,
                            fontSize: 13,
                            color: 'lightgray',
                            '& > p': {
                                marginTop: '0px',
                                marginBottom: '0px'
                            }
                        }}
                            dangerouslySetInnerHTML={{ __html: props.description }}
                        >
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <InfoBox
                openRestrictionsInformation={openRestrictionsInformation}
                openRedemptionInstructions={openRedemptionInstructions}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    sx: { background: '#212121', maxHeight: '75%' }
                }}
            >
                <Toolbar sx={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Typography sx={{ ml: 0, flex: 1, fontWeight: 'bold' }} fontSize={"16px"} component="div">
                        {modalTitle}
                    </Typography>
                    <IconButton
                        sx={{ marginLeft: 1 }}
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <Box
                    sx={{ paddingLeft: 2, paddingRight: 2, '& a': { color: 'primary.main' } }}
                    dangerouslySetInnerHTML={{ __html: instructions }}
                />
            </Dialog>
        </Container >
    )
}

export default CardPreview;