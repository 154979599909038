import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import IEligibilityResultResponse from '../interfaces/ProveEligibility';
import IIdentityResultResponse from '../interfaces/ProveIdentity';
import IProveAuthResponse from '../interfaces/ProveAuth';
import IProveFinalizeResponse from '../interfaces/ProveFinalize';
import IVerificationResultResponse from '../interfaces/ProveVerification';
import { extractErrorMessage } from '../utils/helpers';
import { generateCreateEntity } from '../utils/mobx';

const MAX_POLLING_ATTEMPTS = 24;

class IdentityStore {
    pollVerificationResult: boolean | null = null;
    pollCounter = 0;

    resendingSMS = false;
    resendSMSError = false;
    resendSMSResult: any | null = null;

    finalizeResult: IProveFinalizeResponse | null = null;
    finalizing: boolean | null = null;

    gettingAuthUrl = false;
    authResult: IProveAuthResponse | null = null;

    identityLoading: boolean | null = null;
    identityResult: IIdentityResultResponse | null = null;
    identityError?: string | null = null;
    identityCheckError?: string | null = null;

    eligibilityLoading: boolean | null = null;
    eligibilityResult: IEligibilityResultResponse | null = null;
    eligibilityError?: string | null = null;

    verifyResult: IVerificationResultResponse | null = null;
    verificationsAttempts = 0;
    gettingVerifyStatus: boolean | null = null;
    verificationError?: string | null = null;
    verificationCheckError?: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.pollVerificationResult = null;
        this.pollCounter = 0;

        this.resendingSMS = false;
        this.resendSMSError = false;
        this.resendSMSResult = null;

        this.finalizeResult = null;
        this.finalizing = null;

        this.gettingAuthUrl = false;
        this.authResult = null;

        this.identityLoading = null;
        this.identityResult = null;
        this.identityError = null;
        this.identityCheckError = null;

        this.eligibilityLoading = null;
        this.eligibilityResult = null;
        this.eligibilityError = null;

        this.verifyResult = null;
        this.verificationsAttempts = 0;
        this.gettingVerifyStatus = null;
        this.verificationError = null;
        this.verificationCheckError = null;
    }

    setError(error: any, type = 'login') {
        if (error instanceof Error) {
            error = extractErrorMessage(error);
        }

        this.identityError = type === 'identity' ? error : null;
        this.identityCheckError = type === 'finalize' ? error : null;
    }

    startPollVerificationResultRegistration() {
        this.pollVerificationResult = true;
        this.pollCounter = 0;

        setTimeout(() => {
            this.getVerifyStatusRegistration();
        }, 5000);
    }

    startPollVerificationResult() {
        this.pollVerificationResult = true;
        this.pollCounter = 0;

        setTimeout(() => {
            this.getVerifyStatus();
        }, 5000);
    }

    startOneTimeVerificationResult() {
        this.pollVerificationResult = true;
        this.pollCounter = MAX_POLLING_ATTEMPTS;
        this.getVerifyStatus();
    }

    checkTrust = generateCreateEntity(
        'finalize',
        this,
        'finalizing',
        (vfp: string, userAuthGuid: string) => {
            return services.Auth.checkTrust(vfp, userAuthGuid);
        },
        'finalizeResult'
    );

    async getAuthUrl(phone: string) {
        try {
            if (this.gettingAuthUrl) {
                return;
            }

            this.verificationError = null;
            this.gettingAuthUrl = true;

            const { ipAddress }: { message: string, ipAddress: string } = await services.Auth.getIpAddress();
            const result = await services.Auth.getAuthUrl(phone, ipAddress);

            if (!result || !result.verified || !result.redirectUrl) {
                throw new Error(result.error_message || 'INVALID_PHONE');
            }

            runInAction(() => {
                this.authResult = result;
            });
        } catch (e) {
            runInAction(() => {
                this.verificationError = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.gettingAuthUrl = false;
            });
        }
    }

    async getVerifyStatus(oneTime: boolean = false) {
        try {
            if (this.gettingVerifyStatus) {
                return;
            }

            this.verificationCheckError = null;
            this.gettingVerifyStatus = true;

            const result = await services.Auth.getVerifyStatus();
            if (!oneTime && !result.phoneVerified?.possessionCheck) {
                runInAction(() => {
                    this.pollCounter += 1;
                    if (this.pollCounter > MAX_POLLING_ATTEMPTS) {
                        this.pollVerificationResult = false;
                    } else {
                        console.log('rerun check in 5 sec....');
                        setTimeout(() => {
                            this.getVerifyStatus();
                        }, 5000);
                    }
                });
            }

            runInAction(() => {
                this.verifyResult = result;
            });
        } catch (e) {
            runInAction(() => {
                this.verificationCheckError = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.gettingVerifyStatus = false;
            });
        }
    }

    async getVerifyStatusRegistration(oneTime: boolean = false) {
        try {
            if (this.gettingVerifyStatus) {
                return;
            }

            this.verificationCheckError = null;
            this.gettingVerifyStatus = true;

            const result = await services.Auth.getVerifyStatus();
            if (!oneTime && !result.phoneVerified?.possessionCheckRegistration) {
                runInAction(() => {
                    this.pollCounter += 1;
                    if (this.pollCounter > MAX_POLLING_ATTEMPTS) {
                        this.pollVerificationResult = false;
                    } else {
                        console.log('rerun check in 5 sec....');
                        setTimeout(() => {
                            this.getVerifyStatusRegistration();
                        }, 5000);
                    }
                });
            }

            runInAction(() => {
                this.verifyResult = result;
            });
        } catch (e) {
            runInAction(() => {
                this.verificationCheckError = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.gettingVerifyStatus = false;
            });
        }
    }

    async resendSMS() {
        try {
            if (this.resendingSMS) {
                return;
            }

            this.resendSMSError = false;
            this.resendingSMS = true;

            const { ipAddress }: { message: string, ipAddress: string } = await services.Auth.getIpAddress();
            const result = await services.Auth.resendSMS(ipAddress);

            runInAction(() => {
                this.resendSMSResult = result;
            })
        } catch (e) {
            console.log(e)
            runInAction(() => {
                this.resendSMSError = true;
            })
        } finally {
            runInAction(() => {
                this.resendingSMS = false;
            })
        }
    }


    async checkTrustRegistration(vfp: string, userAuthGuid: string) {
        try {
            if (this.finalizing) {
                return;
            }

            this.identityCheckError = null;
            this.finalizing = true;

            const result = await services.Auth.checkTrustRegistration(vfp, userAuthGuid);

            runInAction(() => {
                this.finalizeResult = result;
            })
        } catch (e) {
            runInAction(() => {
                this.identityCheckError = extractErrorMessage(e)
            })
        } finally {
            runInAction(() => {
                this.finalizing = false;
            })
        }
    }

    async verifyIdentity(
        firstName: string,
        lastName: string,
        dob: Date,
        last4: string,
        city: string,
        address: string,
        region: string,
        postalCode: string
    ) {
        try {
            if (this.finalizing) {
                return;
            }

            this.identityCheckError = null;
            this.finalizing = true;

            const result = await services.Auth.verifyIdentity(firstName, lastName, dob.toString(), last4, city, address, region, postalCode);
            if (!!result?.error_message) this.verificationsAttempts = 3;
            if (!result.verified) throw new Error('Verification failed, please contact Customer Service.');

            runInAction(() => {
                this.finalizeResult = result;
            })
        } catch (e) {
            runInAction(() => {
                this.identityCheckError = extractErrorMessage(e)
            })
        } finally {
            runInAction(() => {
                this.finalizing = false;
            })
        }
    }

    async eligibility() {
        try {
            if (this.eligibilityLoading) {
                return;
            }

            this.eligibilityError = null;
            this.eligibilityLoading = true;
            const result = await services.Auth.eligibility();

            runInAction(() => {
                this.eligibilityResult = result;
            })
        } catch (e) {
            runInAction(() => {
                this.eligibilityError = extractErrorMessage(e)
            })
        } finally {
            runInAction(() => {
                this.finalizing = false;
            })
        }
    }

    async identity(dob: Date | null, last4: string | null) {
        try {
            if (this.identityLoading) {
                return
            }

            this.identityError = null;
            this.identityLoading = true;

            if (dob) {
                const result = await services.Auth.identity(dob, last4);
                if (result?.verified === null) throw new Error('Verification failed, please contact Customer Service.');

                runInAction(() => {
                    this.identityResult = result;
                })
            }
        } catch (e) {
            runInAction(() => {
                this.identityError = extractErrorMessage(e)
            })
        } finally {
            runInAction(() => {
                this.identityLoading = false;
            })
        }

    }

    resetIdentityError() {
        this.identityError = null;
        this.identityCheckError = null;
    }

    get waitingForVerification() {
        if (!this.authResult) return false;
        return this.authResult.message === 'success' && this.authResult.verified;
    }

    get eligibilityForPrefill() {
        if (!this.eligibilityResult) return false;
        return this.eligibilityResult.eligibility;
    }

    get possessionCheckConfirmed() {
        if (!this.verifyResult) return false;
        return this.verifyResult.phoneVerified?.possessionCheckConfirmed;
    }

    get possessionChecked() {
        if (!this.verifyResult) return false;
        return this.verifyResult.phoneVerified?.possessionCheck;
    }

    get possessionCheckedRegistration() {
        if (!this.verifyResult) return false;
        return this.verifyResult.phoneVerified?.possessionCheckRegistration;
    }

    get proveVerified() {
        if (!this.verifyResult) return false;
        return this.verifyResult.phoneVerified?.proveVerified;
    }
}

export default IdentityStore;
