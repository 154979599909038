import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../utils/cookieUtil';
import './winklogin.module';

var config = {
  url: "https://qa-wink-oauth2-keycloak.azurewebsites.net/auth/",
  realm: "wink",
  clientId: "qa-sparkwallet"
};

/* eslint-disable */
const winkLogin = new WinkLogin(config);
/* eslint-enable */

const WinkDemo = () => {
  const navigate = useNavigate();

  const getUserProfile = (profile) => {
    console.log("getUserProfile");

    console.log("Username (winkTag): " + profile.username);
    console.log("Name: " + winkLogin.idTokenParsed.given_name);
    console.log("Surname: " + winkLogin.idTokenParsed.family_name);
    console.log("Phone: " + winkLogin.idTokenParsed.phone_number);
    console.log("Email: " + winkLogin.idTokenParsed.email);
    console.log("ID: " + profile.id);

    console.log("Session: " + winkLogin.session_state);
    console.log("Access token: " + winkLogin.token);
    console.log("Refresh token: " + winkLogin.refreshToken);
    console.log("ID token: " + winkLogin.idToken);

    console.log("Refresh token (parsed)");
    console.log(winkLogin.refreshTokenParsed);
    console.log(
      "Refresh token expiration date: " +
      winkLogin.getFormattedTime(winkLogin.refreshTokenParsed.exp)
    );

    console.log(
      "ID token (parsed): " + JSON.stringify(winkLogin.idTokenParsed)
    );
    console.log(winkLogin.idTokenParsed);
    console.log(
      "ID token expiration date: " +
      winkLogin.getFormattedTime(winkLogin.idTokenParsed.exp)
    );
  }

  const onFailure = (error) => {
    console.log("onFailure");
    console.log(error);
  }

  const signOut = () => {
    if(winkLogin) {
      winkLogin.logout()
    }
  }

  // If a wink cookie exists, we have been redirected, so validate the token
  const load = async () => {
    const winkCookie = getCookie('wink_id_token')
    if (winkCookie) {
      await validateExistingTokenAndSignIn();
    } else {
      winkLogin.winkLogin();
    }
  }

  // If the wink token exists, attempt to verify the user
  // If the user is valid, then they will be signed in using Eyaderno's magic tbd API 
  // If not, we will redirect them to the create account page; otherwise, there was an API error
  const validateExistingTokenAndSignIn = async () => {
    // If we're here, then the token exists
    const token = winkLogin.token;
    const formData = {
      ClientId: "qa-sparkwallet",
      AccessToken: token,
      ClientSecret: "RLK7dAy2mY/DZVjISPt21dsYb+ZLV+rb2JjSuNUGBFw=",
    };

    try {
      const validateRequest = await fetch('https://qa-winklogin-cms-userservice-001.azurewebsites.net/api/ConfidentialClient/verify-client', {
        contentType: "application/json",
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        }
      })

      const result = await validateRequest.json();
      alert(`Token validated with name ${result.firstName} ${result.lastName}. Nothing else to do until signin api completed`);
    } catch (e) {
      console.log('An API error occurred - token possibly invalid');
    }
  }

  useEffect(() => {
    winkLogin.initWinkClient({
      onFailure: onFailure,
      getUserProfile: getUserProfile
    });

    load();
  }, []);

  return <div>
    <button id="wink-oauth-signout-button" class="wink-oauth-button-light" onClick={signOut}>
      Signout from Wink (for easy token clearing)
    </button>
  </div>
}

export default WinkDemo;