import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MerchantImage from './MerchantImage';

const Wrapper = styled(Box)(() => ({
    position: 'relative',
    userSelect: 'none',
    cursor: 'pointer',
}));

const MainContainer = styled(Box)(() => ({
    borderRadius: '20px',
    overflow: 'hidden',
    scrollSnapAlign: 'start',
    lineHeight: 1
}));

const StyledBadge = styled(Box)(({ theme }) => ({
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px',
    backgroundColor: theme.palette.common.goldenrod,
    color: 'black',
    fontSize: '12px',
    borderRadius: '8px',
    fontWeight: 'bold',
    zIndex: 2
}));

interface Props {
    largeImage?: boolean;
    logoImageUrl: string;
    backgroundColor: string;
    cashbackPercentage: string;
    name: string;
    onSelect: () => void;
    showMerchantInfo?: boolean
    showMerchantNameAtBottom?: boolean;
    hideCashbackPercentage?: boolean;
}

const MerchantDisplay = (props: Props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const height = () => {
        if (!props.showMerchantInfo) {
            if (isDesktop) {
                return '220px'
            }
            return '180px';
        }
        return '125px';
    }

    return (
        <Wrapper onClick={props.onSelect}>
            <MainContainer>
                <MerchantImage
                    largeImage={props.largeImage}
                    imageUrl={props.logoImageUrl}
                    backgroundColor={props.backgroundColor}
                    height={height()}
                    disableBottomBorderRadius
                />
                {
                    props.showMerchantInfo ? <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-around"
                        alignItems="center"
                        sx={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            backgroundColor: 'common.darkgrey',
                        }}
                    >
                        <Box sx={{
                            color: 'white',
                            padding: '6px 6px',
                            width: '100%'
                        }}>
                            <Typography
                                sx={{
                                    whiteSpace: 'nowrap',
                                    fontWeight: 'bold',
                                    textOverflow: 'ellipsis',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    maxWidth: '100%',
                                    overflow: 'hidden'
                                }}
                            >
                                {props.name}
                            </Typography>
                        </Box>
                        {!props.hideCashbackPercentage && <Box marginBottom="10px">
                            <StyledBadge>
                                {t('global:percentBack', { percent: `${parseInt(props.cashbackPercentage)}` })}
                            </StyledBadge>
                        </Box>
                        }
                    </Box> :
                        !props.hideCashbackPercentage && <Box>
                            <StyledBadge sx={{ position: 'absolute', top: '5%', left: '5%' }}>
                                {t('global:percentBack', { percent: `${parseInt(props.cashbackPercentage)}` })}
                            </StyledBadge>
                        </Box>
                }
            </MainContainer>
            {
                props.showMerchantNameAtBottom && <Box marginTop="10px">
                    <Typography fontWeight="bold" fontSize="14px" textAlign="center">{props.name}</Typography>
                </Box>
            }
        </Wrapper>
    )
}

export default MerchantDisplay;