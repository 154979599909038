import React from "react";
import { useStores } from "../hooks/use-stores";
import { observer } from "mobx-react-lite";
import { Alert, Snackbar } from "@mui/material";

function SnackbarContainer() {
    const { commonStore } = useStores();
    const closeSnackbar = () => {
        commonStore.hideMessage();
    };
    return <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center", }}
        sx={{
            marginTop: '10px'
        }}
        autoHideDuration={6000}
        message={commonStore.message || ""}
        open={commonStore.messageShown}
        onClose={closeSnackbar}>
        <Alert severity={commonStore.messageType || "info"}>{commonStore.message || ""}</Alert>
    </Snackbar>
}

export default observer(SnackbarContainer);

