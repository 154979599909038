import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
    Alert,
    Box,
    Container,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useStores } from '../../../../hooks/use-stores';
import FullPageLoader from '../../components/FullPageLoader';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { currencyFormatter } from '../../../../utils/helpers';
import FormInput from '../../components/FormInput';
import SparkButton from '../../../../components/SparkButton';
import { ACH_WEEKLY_LIMIT } from '../../../../constants';

const FundYourWallet = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { commonStore, plaidStore } = useStores();

    const [processingPayment, setProcessingPayment] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const { accounts, error } = plaidStore;
    const [inputError, setInputError] = useState<string>('');
    const [selectedAccount, setSelectedAccount] = useState<string>('');
    const [amountInCents, setAmountInCents] = useState<string>('0.00');

    const handleAccountSelect = (e: SelectChangeEvent<string>) => {
        setSelectedAccount(e.target.value);
    };

    const makePayment = async () => {
        const amount = parseFloat(amountInCents);
        if (!selectedAccount) {
            setInputError('selectAccount');
        } else if (isNaN(amount) || amount <= 0 || amount > ACH_WEEKLY_LIMIT) {
            setInputError('invalidAmount');
        } else {
            setInputError('')
            setProcessingPayment(true);
            await plaidStore.processPayment(selectedAccount, amount);

            // The error case is handled below
            if (plaidStore.successfulPayment) {
                navigate(`/giftcards/profile/transactions`);
            }
            setProcessingPayment(false);
        }
    };

    const setAmount = (e: ChangeEvent<HTMLInputElement>) => {
        setInputError('');
        setAmountInCents(e.target.value);
    }

    const allAccounts = useMemo(() => {
        return accounts.flat();
    }, [accounts])

    const selectedAccountInfo = useMemo(() => {
        return allAccounts.find(a => a.account_id == selectedAccount);
    }, [allAccounts, selectedAccount]);

    const lookupError = (errorString: string) => {
        if (errorString == 'PLAID_UPDATE' || errorString == 'ITEM_LOGIN_REQUIRED') {
            return <Typography>{t('profile:fund.plaidUpdate')} {<Link to={`/giftcards/profile/banks?updateAccount=${selectedAccount}`}>{t('profile:fund.updateLink')}</Link>}</Typography>
        } else {
            let string = '';
            if (errorString == 'only_one_active_transaction') {
                string = t('profile:fund.activeTransaction');
            } else if (errorString == 'LINK_FAILED') {
                if (plaidStore.linkSessionId) {
                    string = t('auth:addBank.linkFailedWithId', { errorCode: plaidStore.linkSessionId });
                } else {
                    string = t('auth:addBank.linkFailedGeneral');
                }
            } else if (errorString == 'not_enough_funds') {
                string = t('profile:fund.notEnoughFunds');
            } else if (errorString == 'ach_limit_met') {
                string = t('profile:fund.achLimitMet', { limit: currencyFormatter(ACH_WEEKLY_LIMIT) });
            } else if (errorString == 'identity_invalid') {
                string = t('profile:fund.identityInvalid')
            } else if (errorString == 'payment_failed') {
                string = t('profile:fund.paymentFailed')
            } else if (errorString == 'selectAccount') {
                string = t('profile:fund.selectAccount')
            } else if (errorString == 'invalidAmount') {
                string = t('profile:fund.invalidAmount')
            } else if (errorString == 'account_update_error') {
                string = t('profile:fund.accountUpdateError')
            } else if (errorString == 'funding_disabled') {
                string = t('profile:fund.fundingDisabled')
            } else {
                string = t('global:genericError')
            }
            return <Typography>{string}</Typography>
        }
    }

    const load = async () => {
        setLoading(true);
        await plaidStore.loadAccounts();
        setLoading(false);
    }

    // Set the default account id to the first in the list when the accounts are loaded
    useEffect(() => {
        if (allAccounts.length) {
            setSelectedAccount(allAccounts[0].account_id)
        }
    }, [accounts])

    useEffect(() => {
        const connectedAccount = searchParams.get('connected');
        if (connectedAccount == 'true') {
            commonStore.success(t('auth:addBank.connected'));
        } else if (connectedAccount == 'false') {
            setInputError('LINK_FAILED');
        } else if (searchParams.get('reconnectedAccount')) {
            commonStore.success(t('auth:addBank.reconnected'));
        }
    }, [searchParams]);

    useEffect(() => {
        load();

        return () => {
            // Reset the linkSessionId so we can recieve another one if there continues to be connection errors
            plaidStore.linkSessionId = '';
        }
    }, []);

    return (
        <Container sx={{ maxWidth: { 'xs': '550px' } }}>
            {loading ? (
                <FullPageLoader />
            ) :
                <Box>
                    <Box marginBottom="10px" visibility={(error || inputError) ? 'visible' : 'hidden'}>
                        <Alert severity="error">
                            {lookupError(error || inputError)}
                        </Alert>
                    </Box>
                    <Paper sx={{ padding: '20px', borderRadius: '10px' }}>
                        <Box display="flex" alignItems="center" justifyContent="center" marginBottom="14px">
                            <Typography fontSize="20px" textAlign="center">{t("profile:fund.addMoney")}</Typography>
                        </Box>
                        {
                            allAccounts.length > 0 ? (
                                <>
                                    <Box marginBottom="10px" width="100%">
                                        <Select
                                            sx={{ width: '100%' }}
                                            value={selectedAccount}
                                            onChange={handleAccountSelect}
                                        >
                                            {allAccounts.map((a: any) =>
                                                <MenuItem
                                                    key={a.name}
                                                    value={a.account_id}
                                                >
                                                    {a.name}
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </Box>
                                    <Box marginBottom="12px" width="100%">
                                        <FormInput
                                            value={amountInCents}
                                            onChange={setAmount}
                                            max={ACH_WEEKLY_LIMIT}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography fontSize="12px" marginBottom="22px">
                                            {t('profile:fund.warning', { accountName: selectedAccountInfo?.name })}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" flexDirection="column">
                                        <SparkButton sx={{ padding: '12px' }} onClick={makePayment} loading={processingPayment} disabled={processingPayment}>
                                            {t("profile:fund.confirmDeposit")}
                                        </SparkButton>
                                    </Box>
                                </>
                            ) : <>
                                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="20px">
                                    <Typography fontWeight="bold">{t('profile:fund.noAccounts')}</Typography>
                                </Box>
                                <Box width="95%" maxWidth={'550px'} sx={{ position: 'fixed', bottom: '100px', left: '50%', transform: 'translateX(-50%)' }}>
                                    <SparkButton onClick={() => navigate('/giftcards/profile/banks')}>
                                        {t('auth:addBank.connect')}
                                    </SparkButton>
                                </Box>
                            </>
                        }
                    </Paper>
                </Box>
            }
        </Container>
    );
}

export default observer(FundYourWallet);