import React from 'react'
import { Box, Typography } from '@mui/material';
import GradientText from '../../../components/GradientText';
import { BLUE_GRADIENT } from '../../../constants';

interface Props {
    title: string;
    description: string;
}
const HeaderText = (props: Props) =>
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
        <GradientText component="h1" variant="body1" fontWeight="bold" gradientColor={BLUE_GRADIENT}>
            {props.title}
        </GradientText>
        <Typography textAlign="center" color="white" variant="body2">
            {props.description}
        </Typography>
    </Box>

export default HeaderText;