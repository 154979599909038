import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AccountCircle, CardGiftcard, LocalOffer, PersonOutline, Stars } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Box, Container, Divider, IconButton, Paper, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import Merchants from './Merchants';
import Checkout from './Checkout';
import Category from './Category';
import Review from './Review';
import Confirmation from './Confirmation';
import MyGiftCards from './MyGiftCards';
import SparkRewards from './SparkRewards';
import Profile from './Profile';
import SearchBar from './shared/TopSearchBar';
import { useStores } from '../../hooks/use-stores';
import { Merchant } from '../../stores/models/Merchant';
import Finish from './Finish';
import SparkRewardsButton from './components/SparkRewardsButton';
import { isIos } from '../../utils/helpers';
import RequireAuth from '../../hocs/RequireAuth';
import Breadcrumbs from '../../components/Breadcrumbs';


const SignInButton = ({ onClick }: { onClick: () => void }) => <IconButton sx={{ width: 'min-content', padding: '4px 6px !important' }} onClick={onClick}>
    <PersonOutline />
</IconButton>

const CustomBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
    fontSize: '.75rem',
    '.MuiBottomNavigationAction-label.Mui-selected': {
        fontSize: '.75rem'
    }
}));

const BreadcrumbContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    color: theme.palette.common.midgrey,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '50%'
}));

const Giftcards = () => {
    const { authStore, commonStore, giftcardStore, walletStore } = useStores();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const location = useLocation();
    const onCheckout = location.pathname.includes('checkout');
    const onReview = location.pathname.includes('review');
    const onConfirmation = location.pathname.includes('confirmation');
    const onFinalize = location.pathname.includes('finalize');
    const showBreadCrumbs = onCheckout || onReview || onConfirmation || onFinalize;
    const showSearchBar = location.pathname.includes('shop') && isDesktop;

    const [currentPage, setCurrentPage] = useState(0);

    const goToShop = useCallback(() => {
        navigate('/giftcards/shop');
    }, []);

    const goToMyCards = useCallback(() => {
        navigate('/giftcards/mycards');
    }, []);

    const goToRewards = useCallback(() => {
        navigate('/giftcards/rewards');
    }, []);

    const goToProfile = useCallback(() => {
        navigate('/giftcards/profile')
    }, []);

    const goToSignin = useCallback(() => {
        navigate('/signin')
    }, []);

    const onSelectMerchant = useCallback((merchant: Merchant) => {
        giftcardStore.selectMerchant(merchant);
        navigate('/giftcards/review');
    }, []);

    const topCornerComponent = () => {
        if (showBreadCrumbs) {
            return (
                <BreadcrumbContainer>
                    <Breadcrumbs
                        items={
                            [
                                {
                                    top: '1',
                                    bottom: t('giftCards:breadCrumbs.enterValue'),
                                    current: onReview || onCheckout || onFinalize || onConfirmation,
                                    hideBottom: !isDesktop
                                },
                                {
                                    top: '2',
                                    bottom: t('giftCards:breadCrumbs.checkout'),
                                    current: onCheckout || onConfirmation || onFinalize,
                                    hideBottom: !isDesktop
                                },
                                {
                                    top: '3',
                                    bottom: t('giftCards:breadCrumbs.confirmation'),
                                    current: onConfirmation,
                                    hideBottom: !isDesktop

                                }]
                        }
                    />
                </BreadcrumbContainer>
            )
        } else if (showSearchBar) {
            return (
                <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }} maxWidth="500px" width="100%">
                    <SearchBar
                        onSelectItem={onSelectMerchant}
                        searchItems={giftcardStore.countryMerchants}
                        noOptionsText={t('giftCards:noMerchantsFound')}
                        formatOptionsLabel={(merchant: Merchant) => merchant.name}
                    />
                    {authStore.isRestrictedUser && <Box paddingLeft="10px" display="flex">
                        <SignInButton onClick={goToSignin} />
                    </Box>
                    }
                </Box>
            )
        } else {
            if (authStore.isRestrictedUser) {
                return <Box marginRight="10px">
                    <SignInButton onClick={goToSignin} />
                </Box>
            }
            return (<Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <SparkRewardsButton rewards={walletStore.balances?.sparks ? Math.round(walletStore.balances?.sparks) : 0} />
            </Box>
            )
        }
    }

    const showBottomNavigation = !(authStore.isRestrictedUser || commonStore.isNativeApp);

    // Needed for the bar to be accurate on refresh
    useEffect(() => {
        if (location.pathname.includes('/giftcards/profile')) {
            setCurrentPage(3);
        } else if (location.pathname.includes('/giftcards/rewards')) {
            setCurrentPage(2)
        } else if (location.pathname.includes('/giftcards/mycards')) {
            setCurrentPage(1)
        } else {
            setCurrentPage(0);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!authStore.isRestrictedUser && authStore.isAccountComplete) {
            walletStore.loadBalances();
        }
    }, [])

    return (
        <Container sx={{ padding: 0, height: '100%', marginTop: commonStore.isNativeApp ? '20px' : '0px' }} disableGutters>
            {/* Hide the head if on native apps */}
            {!commonStore.isNativeApp &&
                <>
                    <Box display="flex" sx={{ padding: '10px', width: '100%' }} alignItems="center" justifyContent={'space-between'}>
                        <Box sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', height: '50px' }} onClick={() => navigate('/giftcards/shop')}>
                            <Box component="img" src={`${process.env.PUBLIC_URL}/sparklogo.png`} sx={{ marginRight: "-2px", height: { xs: '25px', sm: "30px" } }} />
                            <Typography display={'block'} fontWeight="bold" letterSpacing={-.25} sx={{ fontSize: { xs: '13px', sm: "16px" } }}>{t('global:shopWithSpark')}</Typography>
                            {!authStore.isRestrictedUser && <Box marginLeft="10px" sx={{ display: { xs: 'none', md: 'initial' } }}>
                                <SparkRewardsButton rewards={walletStore.balances?.sparks ? Math.round(walletStore.balances?.sparks) : 0} size="small" />
                            </Box>}
                        </Box>
                        {
                            topCornerComponent()
                        }
                    </Box>
                    <Divider sx={{ marginBottom: '20px' }} />
                </>
            }
            <Box mb={showBottomNavigation ? '100px' : '0px'}>
                {/* Add padding to compensate for bottom bar and prevent content from being covered up */}
                <Routes>
                    {/* The standard checkout flow is: Merchants -> Review -> Checkout -> Finalize -> Confirmation */}
                    <Route
                        path="/confirmation"
                        element={
                            <RequireAuth>
                                <Confirmation />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/finish"
                        element={
                            <RequireAuth>
                                <Finish />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/checkout"
                        element={
                            <RequireAuth>
                                <Checkout />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/mycards"
                        element={
                            <RequireAuth>
                                <MyGiftCards />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/rewards"
                        element={
                            <RequireAuth>
                                <SparkRewards />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/profile/*"
                        element={
                            /* 
                              On the profile page, pressing the Sign Out button causes you to return
                              to the profile page on the next sign-in which is a bit strange, so just force it to go to the
                              plain old signin page
                            */
                            <RequireAuth notLoggedUrl="/signin">
                                <Profile />
                            </RequireAuth>
                        }
                    />
                    {/* BEGIN PUBLIC ROUTES */}
                    <Route
                        path="/review"
                        element={
                            <Review />
                        }
                    />
                    <Route
                        path="/category"
                        element={<Category />}
                    />
                    <Route
                        path="/shop"
                        element={<Merchants />}
                    />
                    <Route
                        path="*"
                        element={<Navigate to="/giftcards/shop" />}
                    />
                </Routes>
            </Box>
            {
                !(authStore.isRestrictedUser || commonStore.isNativeApp) &&
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000000, paddingBottom: isIos() ? '6px' : 'initial' }} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={currentPage}
                        onChange={(event, newValue) => {
                            setCurrentPage(newValue);
                        }}
                    >
                        <CustomBottomNavigationAction value={0} onClick={goToShop} label={t('giftCards:shop')} icon={<LocalOffer />} />
                        <CustomBottomNavigationAction value={1} onClick={goToMyCards} label={t('giftCards:myGiftCards')} icon={<CardGiftcard />} />
                        <CustomBottomNavigationAction value={2} onClick={goToRewards} label={t('giftCards:sparks')} icon={<Stars />} />
                        <CustomBottomNavigationAction value={3} onClick={goToProfile} label={t('giftCards:profile')} icon={<AccountCircle />} />
                    </BottomNavigation>
                </Paper>
            }
        </Container >
    )
}

export default observer(Giftcards);