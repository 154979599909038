import React from 'react';
import { Autocomplete, Box, Grid, styled, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Merchant } from '../../../stores/models/Merchant';

const MerchantImage = styled('img')(({ theme }) => ({
    cursor: 'pointer',
    width: '100%',
    borderRadius: '10px',
    maxWidth: `${100}%`,
    zIndex: 1
}));

interface Props {
    searchItems: Merchant[];
    noOptionsText?: string;
    searchPlaceholderText?: string;
    formatOptionsLabel: (item: Merchant) => string;
    onSelectItem: (selectedItem: Merchant) => void;
    large?: boolean;
}

function TopSearchBar(props: Props): React.ReactElement {
    const { t } = useTranslation();

    return (
            <Autocomplete
                sx={{ flexGrow: 1, width: '100%' }}
                disableClearable
                selectOnFocus
                clearOnBlur
                noOptionsText={props.noOptionsText || t('global:noResults')}
                options={props.searchItems}
                onChange={(e, merchant: Merchant) => props.onSelectItem(merchant)}
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            type: 'search',
                            sx: {
                                borderRadius: '10px',
                                backgroundColor: 'common.dimblue',
                                fontSize: props.large ? 18 : 14
                            },
                            style: { padding: props.large ? '14px 18px' : '8px 10px', letterSpacing: '.5px', border: '1px solid black', }
                        }}
                        placeholder={props.searchPlaceholderText || t('global:search')}
                        variant="standard"
                    />
                )}
                renderOption={(props, option: Merchant) =>
                    <li {...props} key={option.id}>
                        <Grid container gap={1}>
                            <Grid item xs={3} sx={{ padding: '10px' }}>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <MerchantImage src={option.logoImageUrl} />
                                </Box>
                            </Grid>
                            <Grid item xs={8} display="flex" flexDirection="column" justifyContent="center">
                                <Typography fontWeight="bold">{option.name}</Typography>
                            </Grid>
                        </ Grid>
                    </li>
                }
                popupIcon={null}
                //@ts-ignore
                getOptionLabel={props.formatOptionsLabel}
            />
    )
}

export default observer(TopSearchBar);