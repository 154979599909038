import React from 'react';
import { Box, Button, Grid, styled } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

const Root = styled(Box)(({ theme }) => ({
    margin: '0 auto',
    maxWidth: 450,
    width: '100%',
}));

const KeypadButton = styled(Button)(({  theme }) => ({
    display: 'flex',
    width: '33%',
    fontSize: 24,
    justifyContent: 'center',
    alignItems: 'center',
    color: "white",
    padding: '12px 20px',
    // clear: fontSize: 16
    // back: fontsize: 22
}));

const Keypad = ({ onKeyPressed, value }: {onKeyPressed: (key: string) => void, value: string}) => {
    const keypadKeys = [
        [
            {
                code: '1',
                content: '1'
            },
            {
                code: '2',
                content: '2'
            },
            {
                code: '3',
                content: '3'
            }
        ],
        [
            {
                code: '4',
                content: '4'
            },
            {
                code: '5',
                content: '5'
            },
            {
                code: '6',
                content: '6'
            }
        ],
        [
            {
                code: '7',
                content: '7'
            },
            {
                code: '8',
                content: '8'
            },
            {
                code: '9',
                content: '9'
            }
        ],
        [
            {
                code: 'clear',
                content: <SettingsBackupRestoreIcon />
            },
            {
                code: '0',
                content: '0'
            },
            {
                code: 'back',
                content: <BackspaceIcon />
            }
        ],
    ];

    const onButtonClick = (key: any) => {
        if (key.code === 'back') {
            // If the value's length is 1, then it's in thousandths of a cent, so delete it
            if (value && value.length > 1)
                onKeyPressed(value.slice(0, -1));
            else
                onKeyPressed('0');
        } else if (key.code === "clear") {
            onKeyPressed('0');
        } else {
            onKeyPressed(value + key.code);
        }
    };

    return (
        <Root>
            <Grid container>
                {
                    keypadKeys.map((keyRow, index) => (
                        <Grid container item key={index}>
                            {/* @ts-ignore */}
                            {keyRow.map(key => <KeypadButton key={`button-${key.code}`} onClick={() => onButtonClick(key)}
                            >{key.content}
                            </KeypadButton>)}
                        </Grid>
                    ))
                }
            </Grid>
        </Root>
    );
};

export default Keypad;