import { makeAutoObservable, runInAction } from 'mobx';
import { AuthProvider, createUserWithEmailAndPassword, sendEmailVerification, signInWithCredential, signInWithEmailAndPassword, signInWithPopup, signOut, User as FBUser, UserCredential, UserInfo } from 'firebase/auth';
import CommonStore from './commonStore';
import User, { Roles } from './models/User';
import services from '../services';
import { auth } from '../SparkFirebase'
import { backOffDelay, extractErrorMessage, parseJwt } from '../utils/helpers';
import { setItem, removeItem, getItem } from '../utils/storage';
import { CreateAccountPage } from '../pages/CreateAccount';
import { ACCESS_TOKEN_KEY, LINK_TOKEN_KEY, REFRESH_TOKEN_KEY, UPDATE_FLOW_KEY } from '../constants';
import { resetAllStores } from './helpers';

function getSavedToken(): { accessToken: string; refreshToken: string } | null {
    const accessToken = getItem('spark_access_token') || getItem(ACCESS_TOKEN_KEY);
    const refreshToken = getItem('spark_refresh_token') || getItem(REFRESH_TOKEN_KEY);

    if (!accessToken || !refreshToken) return null;
    return {
        accessToken,
        refreshToken,
    };
}

function setSavedToken(accessToken: string, refreshToken: string) {
    setItem(ACCESS_TOKEN_KEY, accessToken, 30);
    setItem(REFRESH_TOKEN_KEY, refreshToken, 30);
}

export const RESTRICTED_USER_ALLOWED_ROUTES = {
    parent: '/giftcards',
    paths: ['/giftcards/shop', '/giftcards/review', '/createAccount', '/signin']
}

class AuthStore {
    commonStore: CommonStore;
    inProgress = false;
    signingIn = false;
    creatingAccount = false;
    currentSparkUser: User | null = null;
    subscriptions?: any | null = null;
    app: any = null;
    signinError?: string | null = null;
    signupError?: string | null = null;
    profileError?: string | null = null;
    updatingUser = false;
    firebaseUser: UserInfo | null = null;
    redirectionSteps: Array<CreateAccountPage> = []

    constructor(commonStore: CommonStore) {
        makeAutoObservable(this, { commonStore: false });
        this.commonStore = commonStore;
    }

    setError(error: any, type = 'login') {
        if (error instanceof Error) {
            error = extractErrorMessage(error);
        }
        this.signinError = type === 'login' ? error : null;
        this.signupError = type === 'signup' ? error : null;
        this.profileError = type === 'profile' ? error : null;
    }

    async logout(callback?: Function) {
        this.inProgress = false;
        this.signingIn = false;
        this.creatingAccount = false;
        this.signinError = null;
        this.signupError = null;
        this.updatingUser = false;
        this.redirectionSteps = [];
        this.forgetUser();

        resetAllStores();
        if (callback) callback();
    }

    setToken(accessToken: string, refreshToken: string) {
        setSavedToken(accessToken, refreshToken)
    }

    get displayName() {
        if (!this.currentSparkUser) return '';
        return this.currentSparkUser.name || this.currentSparkUser.email;
    }

    get isAuthenticated() {
        return this.currentSparkUser != null;
    }

    forgetUser = () => {
        signOut(auth);
        this.currentSparkUser = null;
        this.firebaseUser = null;
        this.setToken('', '');

        const authAndPlaidItems = [ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, UPDATE_FLOW_KEY, LINK_TOKEN_KEY];
        authAndPlaidItems.forEach(item => removeItem(item))
    }

    async refreshUser() {
        try {
            let user = {};
            // @ts-ignore
            Object.assign(this.currentSparkUser, user);
        } catch (e) {
            console.error(e);
        }
    }

    async pullUser(counter = 0) {
        this.inProgress = true;
        try {
            if (this.token) {
                await Promise.all([
                    services.Auth.verify(this.token.accessToken, this.token.refreshToken),
                    services.UserProfile.fetch().then((user) => {
                        runInAction(() => {
                            this.currentSparkUser = user.userProfile;
                        });
                    }).catch(e => { throw e })
                ])
            } else {
                throw new Error()
            }
        } catch (e: any) {
            if (counter < 10 && e && (!e.response || e.response.status !== 403 || e.response.status !== 401)) {
                setTimeout(async () => {
                    await this.pullUser(counter + 1);
                }, backOffDelay(500, counter));
            } else if (e?.response?.status === 401) {
                if (!!this.token) {
                    await this.refreshUserToken();
                } else {
                    this.setError(extractErrorMessage(e));
                }
            } else {
                return this.logout();
            }
        } finally {
            runInAction(() => {
                this.inProgress = false;
            });
        }
    }

    refreshUserToken = async () => {
        if (!!this.token) {
            try {
                const refreshResult = await services.Auth.refreshAccessToken(this.token.accessToken, this.token.refreshToken);
                const accessToken = refreshResult.access_token;
                this.setToken(accessToken, this.token.refreshToken);
            } catch (e) {
                this.setError(extractErrorMessage(e));
            }
        }
    }

    /****** Signup *******/

    // We call this function whenever we are authenticating through a third party,
    // whether the user is signing in or signing up. The third party flow is different from email
    // in that email has a signin flow and a sign up flow
    signupWithProvider = async (provider: AuthProvider) => {
        this.creatingAccount = true;
        return await this.signUp(() => signInWithPopup(auth, provider));
    }

    // Sign up with an email/password. Checks the email - if invalid, it will set the 
    // error on this store in the signUp functions try/catch
    signupWithEmailAndPassword = async (email: string, password: string) => {
        this.creatingAccount = true;
        return await this.signUp(async () => {
            const checkEmail = await services.User.verifyEmail(email);
            if (checkEmail.status == 'failure') {
                this.signupError = 'invalidemail';
            } else {
                return await createUserWithEmailAndPassword(auth, email, password);
            }
            return null;
        });
    }

    // Sign up is really an extension of 'signIn' with a send email step if the user's
    // email isn't verified.
    private signUp = async (getCredentials: () => Promise<UserCredential | null> | UserCredential) => {
        try {
            this.signinError = null;
            this.signupError = null;
            this.creatingAccount = true;

            let userCredentials = await getCredentials();

            if (!userCredentials) {
                throw new Error('');
            }

            await Promise.all([
                this.sendVerifyEmail(),
                this.signinWithCredentials(userCredentials)
            ])

            return true;
        } catch (e) {
            runInAction(() => {
                const errorMessage = extractErrorMessage(e);
                this.signupError = errorMessage;
                this.creatingAccount = false;
            })
        }
    }

    sendVerifyEmail = async () => {
        if (!this.currentFBUser) {
            return null;
        }

        if (!this.currentFBUser.emailVerified) {
            return sendEmailVerification(
                this.currentFBUser, {
                android: {
                    installApp: true,
                    minimumVersion: "6",
                    packageName: 'io.sparkwallet'
                },
                iOS: {
                    bundleId: "io.sparkwallet"
                },
                url: "https:\/\/sparkwallet.page.link\/verifyEmail"
            })
        }
    }

    /****** Sign In *******/
    signinWithEmailPassword = async (email: string, password: string) => {
        this.inProgress = true;
        return await this.signIn(() => signInWithEmailAndPassword(auth, email, password));
    }

    // Re-authenticates the current user to get the most up-to-date info from the backend
    // This is most useful for the signup process to know which steps the user needs to do to
    // sucessfully be allowed into their account. It's also what we use to re-auth the user
    // when they return to the site.
    signinCurrentUser = async () => {
        this.inProgress = true;
        return await this.signIn();
    }

    // This is a convenience function used for signup
    private signinWithCredentials = async (credentials: UserCredential) => {
        this.inProgress = true;
        return await this.signIn(() => credentials);
    }

    private signIn = async (getCredentials?: () => Promise<UserCredential> | UserCredential) => {
        this.signingIn = true;
        this.signinError = null;

        try {
            let firebaseCredentials;

            if (getCredentials) {
                firebaseCredentials = await getCredentials();
            } else {
                firebaseCredentials = { user: this.currentFBUser }
            }

            if (!firebaseCredentials?.user) {
                throw new Error('Unsupported signin method');
            }

            const user = firebaseCredentials.user;
            const idToken = await user.getIdToken();

            const signInResult = await services.Auth.signIn(idToken);

            const accessToken = signInResult.access_token;
            const refreshToken = signInResult.refresh_token;

            runInAction(() => {
                if (!user.emailVerified) {
                    this.redirectionSteps.push(CreateAccountPage.CONFIRM_EMAIL);
                }

                if (!signInResult.registrationVerified) {
                    this.redirectionSteps.push(CreateAccountPage.PHONE_VERIFICATION);
                }

                if (signInResult.needsSetup) {
                    this.redirectionSteps.push(CreateAccountPage.CREATE_WALLET);
                }

                if (!signInResult.usernameVerified) {
                    this.redirectionSteps.push(CreateAccountPage.CHOOSE_ADDRESS);
                }

                this.setToken(accessToken, refreshToken);
                setItem('access_token', accessToken, 30);
                setItem('refresh_token', refreshToken, 30);
            })

            await this.pullUser();
            return true;
        } catch (e: any) {
            console.log(e)
            // Restricted users may end up here while trying to log in, so ensure their login errors 
            // are still set and we dont' just end up refreshing their tokens
            if (this.isRestrictedUser) {
                this.setError(extractErrorMessage(e));
                return;
            }

            if (!!this.token) {
                try {
                    await this.refreshUserToken();
                } catch (e) {
                    this.setError(extractErrorMessage(e));
                }
            } else {
                this.setError(extractErrorMessage(e));
            }
        } finally {
            runInAction(() => {
                this.inProgress = false;
                this.signingIn = false;

                // Creating account is only finalized when the user is signed in
                this.creatingAccount = false;
            });
        }
    }

    finishCreateAccountStep(finishedStep: CreateAccountPage) {
        this.redirectionSteps = this.redirectionSteps.filter(step => step != finishedStep);
    }

    get isAccountComplete() {
        return this.redirectionSteps.length == 0;
    }

    get currentFBUser(): FBUser | null {
        return auth.currentUser;
    }

    async isEmailVerified() {
        await auth.currentUser?.reload();
        return auth.currentUser?.emailVerified;
    }

    get token(): { accessToken: string; refreshToken: string } | null {
        return getSavedToken();
    }

    get userEmail() {
        return auth.currentUser?.email
    }

    get loginUrl() {
        return `/signin?redirect=${window.location.pathname}${window.location.search}`;
    }

    get loginGuid() {
        if (!this.token) return null;
        let access_token = parseJwt(this.token.accessToken);

        return access_token.loginGuid;
    }

    // Is this user signed out or has a restricted only role, then they are restricted to a few pages of the app
    get isRestrictedUser() {
        const isUserSignedOut = !this.currentSparkUser;
        const userHasRestrictedRole = !!this.currentSparkUser?.userRoles[Roles.RESTRICTED_ACCESS_SHOP];
        return isUserSignedOut || userHasRestrictedRole;
    }

    get canVerifyPhone() {
        return this.redirectionSteps.includes(CreateAccountPage.PHONE_VERIFICATION);
    }

    get canCreateWallet() {
        return this.redirectionSteps.includes(CreateAccountPage.CREATE_WALLET);
    }

    get canChooseAddress() {
        return this.redirectionSteps.includes(CreateAccountPage.CHOOSE_ADDRESS);
    }
}

export default AuthStore;
