export enum ACHPaymentStatus {
    SCHEDULED = 'Scheduled',
    CANCELLED = 'Cancelled',
    CLEARED = 'Cleared',
    REJECTED = 'Rejected',

    // The following are ACHQ statuses, preserve for legacy reasons
    SUBMITTED = 'Submitted',
    CREATED = 'Created',
}

class ACHTransaction {
    public _id: string;
    public accountName: string; // method
    public amount: number;
    public createdAt: string; // date
    public paymentStatus: ACHPaymentStatus;
    public last4: string;

    constructor(props: any) {
        this._id = props._id;
        this.accountName = props.accountName;
        this.amount = props.amount;
        this.createdAt = props.createdAt;
        this.paymentStatus = props.paymentStatus;
        this.last4 = props.last4;
    }
}

export default ACHTransaction;