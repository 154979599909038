import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material'
import { AuthProvider, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { useStores } from '../hooks/use-stores';

const GoogleProvider = new GoogleAuthProvider();
const AppleProvider = new OAuthProvider('apple.com');

interface ThirdPartySigninButtonProps {
    icon: string;
    text: string;
    onClick: () => void;
}

const ThirdPartySigninButton = (props: ThirdPartySigninButtonProps) => {
    return (
        <Button
            sx={{
                color: 'white',
                background: 'transparent',
                boxShadow: 'inset 0px 0px 0px 1px #566171',
                padding: '12px 20px',
                borderRadius: '100px',
                textTransform: 'none',
            }}
            onClick={props.onClick}
        >
            <Box width="100%" display="flex" justifyContent="center" alignItems="center" position="relative">
                <Box display="flex" position="absolute" left={'8px'}>
                    <img src={props.icon} height="20" />
                </Box>
                <Typography fontSize="15px" fontWeight="bold" maxWidth="calc(100% - 100px)">{props.text}</Typography>
            </Box>
        </Button>
    )
}

interface ThirdPartySigninProps {
    onSuccessfulAuth?: () => void;
    signIn?: boolean; // If true, the user is signing *in*, otherwise they are signing *up*
    hideOR?: boolean;
}

const ThirdPartyAuthentication = (props: ThirdPartySigninProps) => {
    const { authStore } = useStores();
    const { t } = useTranslation();

    const signUpWithProvider = async (provider: AuthProvider) => {
        // There is only 'signUp' with provider which behaves the same as sign in.
        const success = await authStore.signupWithProvider(provider);

        if (success && props.onSuccessfulAuth) {
            props.onSuccessfulAuth();
        }
    }

    return (
        <Box>
            {!props.hideOR && <Box display="flex" alignItems="center" justifyContent="center" mt={1} mb={1}>
                <Typography>-</Typography>
                <Typography fontWeight="bold" ml={1} mr={1}>{t('global:or')}</Typography>
                <Typography>-</Typography>
            </Box>
            }
            <Box display="flex" flexDirection="column" gap={2} mt={3}>
                <ThirdPartySigninButton
                    onClick={async () => await signUpWithProvider(AppleProvider)}
                    icon={`${process.env.PUBLIC_URL}/appleLogo.png`}
                    text={t(`createAccount:sign${props.signIn ? 'in' : 'up'}WithApple`)}
                />
                <ThirdPartySigninButton
                    onClick={async () => await signUpWithProvider(GoogleProvider)}
                    icon={`${process.env.PUBLIC_URL}/googleLogo.png`}
                    text={t(`createAccount:sign${props.signIn ? 'in' : 'up'}WithGoogle`)}
                />
            </Box>
        </Box>
    )
}

export default ThirdPartyAuthentication;