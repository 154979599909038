import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import SparkButton, { BlueSparkButton } from '../../components/SparkButton';
import { BottomContainer, MainContainer } from '../../components/TopBottomLayout';
import ThirdPartyAuthentication from '../../components/ThirdPartyAuthentication';
import { useStores } from '../../hooks/use-stores';
import FullPageLoader from '../Giftcards/components/FullPageLoader';

const LandingPage = () => {
    const { t } = useTranslation();
    const { authStore } = useStores();
    const navigate = useNavigate();

    const goToLoginPage = useCallback(() => {
        navigate('/signin');
    }, []);

    const goToEmailSignup = useCallback(() => {
        navigate('/createAccount/emailSignup');
    }, [])

    return (
        <MainContainer minHeight="500px">
            {authStore.creatingAccount ? <FullPageLoader /> :
                <>
                    <Box>
                        <Typography component="h1" fontSize={'30px'} fontWeight="bold">
                            {t('createAccount:landingPage.title')}
                        </Typography>
                    </Box>
                    <Box mt={1} mb={1}>
                        <Typography variant="body2" color="dimgray">{t('createAccount:landingPage.description')}</Typography>
                    </Box>
                    <Box mt={2} mb={2} width="100%">
                        <SparkButton onClick={goToEmailSignup}>{t('createAccount:landingPage.emailSignup')}</SparkButton>
                    </Box>
                    <Box width="100%">
                        <ThirdPartyAuthentication
                            onSuccessfulAuth={() => navigate('/')}
                        />
                    </Box>
                    <BottomContainer>
                        <Typography textAlign="center" variant="body2" mb={.5}>{t('createAccount:landingPage.existingAccount')}</Typography>
                        <BlueSparkButton
                            disabled={authStore.creatingAccount}
                            onClick={goToLoginPage}>
                            {t('createAccount:loginWithEmail')}
                        </BlueSparkButton>
                    </BottomContainer>
                </>
            }
        </MainContainer>
    )
}

export default observer(LandingPage);