import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Avatar, Box, Button, Container, Dialog, Link, List, ListItem, Paper, styled, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useStores } from '../../../../hooks/use-stores';
import SparkButton from '../../../../components/SparkButton';
import { currencyFormatter } from '../../../../utils/helpers';
import { SPARK_WALLET_ME } from '../../../../constants';

const ProfileHomeWrapper = styled(Container)(({ theme }) => ({
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.up('xs')]: {
        maxWidth: '650px'
    }
}));

const UserInfoAvatar = styled(Avatar)(({ theme }) => ({
    width: '42px',
    height: '42px',
    marginBottom: '4px',
    [theme.breakpoints.up('sm')]: {
        width: '64px',
        height: '64px',
    }
}));

const UserInfoWrapper = styled(Paper)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    marginBottom: theme.spacing(1),
    padding: '20px',
    borderRadius: '20px',
    backgroundColor: theme.palette.common.darkgrey,
    backgroundImage: 'none'
}));

const ProfileMenu = styled(List)(({ theme }) => ({
    borderRadius: '20px',
    backgroundColor: theme.palette.common.darkgrey,
}));

interface MenuItemProps {
    icon: any;
    heading: string;
    description: string;
    onClick: () => void;
}

const ProfileMenuItem = (props: MenuItemProps) =>
    <ListItem onClick={props.onClick} sx={{ paddingLeft: 0, paddingRight: 0, cursor: 'pointer', '&:not(:last-child)': { borderBottom: '1px solid #333333' }, paddingBottom: '10px' }}>
        <Box display="flex" sx={{ width: '100%' }} justifyContent="space-between" alignItems="center">
            <Box display="flex" width="100%" alignItems="center">
                <Box padding="10px" sx={{ backgroundColor: "common.darkgrey", borderRadius: '20px', marginRight: '4px' }}>
                    <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '36px', height: '36px' }}>{props.icon}</Box>
                </Box>
                <Box display="flex" flexDirection="column" alignItems={'flex-start'} width="75%">
                    <Typography sx={{ fontWeight: 'bold', fontSize: '18px', color: 'common.lightgrey' }}>
                        {props.heading}
                    </Typography>
                    <Typography sx={{ fontSize: '14px', color: '#aaaaaa' }}>
                        {props.description}
                    </Typography>
                </Box>
            </Box>
            <Box marginRight="4px">
                <NavigateNextIcon fontSize="large" />
            </Box>
        </Box>
    </ListItem>

const ProfileHome = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { authStore, commonStore, giftcardStore } = useStores()

    const [referralModalOpen, setReferralModalOpen] = useState<boolean>(false);

    /* Not showing ref links
    useEffect(() => {
        (async () => {
            await giftcardStore.generateReferralLink();
        })();
    }, []);

    const copyRefLink = () => {
        navigator.clipboard.writeText(giftcardStore.referralLink?.shortLink || '').then(() => {
            commonStore.success("Link copied");
        }, () => {
            commonStore.warn("Failed to copy your referral link");
        })
    }
    */

    const handlereferralModalOpen = useCallback(() => {
        setReferralModalOpen(true);
    }, [referralModalOpen])

    const handlereferralModalClose = useCallback(() => {
        setReferralModalOpen(false);
    }, [referralModalOpen])

    return (
        <Container sx={{ padding: 0 }}>
            <ProfileHomeWrapper>
                <UserInfoWrapper>
                    <UserInfoAvatar src={authStore.firebaseUser?.photoURL || ''} />
                    <Typography sx={{ fontSize: '20px' }}>{authStore.currentSparkUser?.username}</Typography>
                    {authStore.currentSparkUser?.username && <Link className="sparkLink" href={`https://${SPARK_WALLET_ME}/${authStore.currentSparkUser?.username}`} target="_blank"><Typography>sparkwallet.me/{authStore.currentSparkUser?.username}</Typography></Link>}
                </UserInfoWrapper>
                {false && <SparkButton onClick={handlereferralModalOpen}>{t('profile:home.invite', { satsAmount: '5,000' })}</SparkButton>}
                <ProfileMenu>
                    <ProfileMenuItem
                        icon={<PersonIcon fontSize="medium" />}
                        heading={t('profile:profile.title')}
                        description={t('profile:profile.description')}
                        onClick={() => navigate('/giftcards/profile/profile')}
                    />
                    {/* {<ProfileMenuItem
                        icon={<PersonIcon fontSize="medium" />}
                        heading={t('profile:linkedBanks.title')}
                        description={t('profile:linkedBanks.description')}
                        onClick={() => navigate('/giftcards/profile/banks')}
                    />} */}
                    <ProfileMenuItem
                        icon={<PersonIcon fontSize="medium" />}
                        heading={t('profile:privacy.title')}
                        description={t('profile:privacy.description')}
                        onClick={() => navigate('/giftcards/profile/privacy')}
                    />
                    {/* V2 */}
                    {/* <ProfileMenuItem
                        icon={<PersonIcon fontSize="medium" />}
                        heading={t('profile:fund.title')}
                        description={t('profile:fund.description')}
                        onClick={() => navigate('/giftcards/profile/fund')}
                    /> */}
                    <ProfileMenuItem
                        icon={<PersonIcon fontSize="medium" />}
                        heading={t('profile:transactions.title')}
                        description={t('profile:transactions.description')}
                        onClick={() => navigate('/giftcards/profile/transactions')}
                    />
                    {false && <ProfileMenuItem
                        icon={<PersonIcon fontSize="medium" />}
                        heading={t('profile:limits.title')}
                        description={t('profile:limits.description')}
                        onClick={() => navigate('/giftcards/profile/limits')}
                    />
                    }
                    <ListItem>
                        <Box width="100%" display="flex" justifyContent="center" mt={1}>
                            <Button onClick={() => authStore.logout()} variant="outlined">
                                <Typography fontWeight="bold" textTransform="none">{t('profile:signOut')}</Typography>
                            </Button>
                        </Box>
                    </ListItem>
                </ProfileMenu>
            </ProfileHomeWrapper>
            {false && <Dialog
                open={referralModalOpen}
                onClose={handlereferralModalClose}
            >
                <Box sx={{ padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <Typography>{t('profile:home.inviteFriends')}</Typography>
                    <Typography>{t('profile:home.invite', { satsAmount: '5,000' })}</Typography>
                    <Paper sx={{ padding: '10px', margin: '10px', borderRadius: '20px' }}>
                        <Typography textAlign="center" marginBottom="4px">
                            {t('profile:home.invite', { satsAmount: '5,000', cardAmount: currencyFormatter(100) })}
                        </Typography>
                        <Typography textAlign="center">
                            {t('profile:home.earnings', { satsAmount: 10 })}
                        </Typography>
                    </Paper>
                    <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold ' }}>
                        {t('profile:home.referralLink')}
                    </Typography>
                    <Box sx={{ cursor: 'pointer', marginTop: '4px' }}>
                        <Typography component={'a'} sx={{ textDecoration: 'underline', color: 'blue' }}>{giftcardStore.referralLink?.shortLink}</Typography>
                    </Box>
                    <Typography>{t('profile:home.tapToCopy')}</Typography>
                </Box>
            </Dialog>
            }
        </Container>
    )
}

export default observer(ProfileHome);