import React from 'react';
import { Box, styled, Typography, } from '@mui/material';
import { useStores } from '../../../hooks/use-stores';
import MerchantImage from '../shared/MerchantImage';

interface WrapperProps {
    backgroundColor: string;
}

const Wrapper = styled(Box)<WrapperProps>((props) => ({
    display: "flex",
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'left',
    backgroundColor: props.backgroundColor,
    borderRadius: 20,
    width: '100%',
    maxWidth: '440px',
    padding: '22px',
    paddingBottom: '6px',
    height: '200px',
    [props.theme.breakpoints.up('sm')]: {
        height: '240px',
    }
}));

const Image = styled('div')(({ theme }) => ({
    maxWidth: '200px',
    width: '100%',
    height: '200px'
}));

const CardDenomination = styled(Typography)((props) => ({
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: -2,
    [props.theme.breakpoints.up('sm')]: {
        fontSize: '22px',
    }
}));

interface MerchantNameProps {
    fontColor: string;
}
const MerchantName = styled(Typography)<MerchantNameProps>((props) => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: '0 10px',
    color: props.fontColor,
    fontSize: 12,
    whiteSpace: 'nowrap',
    marginLeft: 1,
    [props.theme.breakpoints.up('sm')]: {
        fontSize: '16px',
    }
}));

interface CardNumberLabelProps {
    fontColor: string;
}
const CardNumberLabel = styled(Box)<CardNumberLabelProps>((props) => ({
    color: props.fontColor,
    fontSize: '12px',
    fontWeight: '400',
    marginBottom: '3px',
    [props.theme.breakpoints.up('sm')]: {
        fontSize: '16px',
    }
}));

const GiftCardNumberWrapper = styled(Box)((props) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%'
}));

const GiftCardNumber = styled(Box)(({ theme }) => ({
    letterSpacing: '1px',
    color: 'black',
    padding: '14px',
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.common.lightgrey,
    borderRadius: '10px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
    }
}));

const PinWrapper = styled(Box)((props) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: '10px',
    color: 'black',
    width: '100%',
}));

const PinHeader = styled(Typography)((props) => ({
    fontSize: '12px',
    marginBottom: -2
}));

const Pin = styled(Typography)((props) => ({
    fontWeight: 'bold'
}));

interface PurchasedGiftCardImageProps {
    backgroundColor: string;
    fontColor: string;
    logo: string;
    amount: string;
    currency?: string;
    giftCardNumber: string;
    pin?: string;
    merchantName: string;
    hideCurrencyCode?: boolean;
    useGiftCardNumber?: boolean;
}
const PurchasedGiftCardImage = (props: PurchasedGiftCardImageProps) => {
    const { commonStore } = useStores();

    const cardNumber = props.giftCardNumber || "XXXX XXXX XXXX XXXX";
    const copyToClipboard = () => {
        navigator.clipboard.writeText(cardNumber).then(() => {
            commonStore.success("Link copied");
        }, () => {
            commonStore.warn("Failed to copy Lightning Invoice");
        })
    }

    return (
        <Wrapper backgroundColor={props.backgroundColor}>
            <Box display="flex" height="50px" width="100%" alignItems={'center'} minWidth="0">
                <MerchantImage
                    backgroundColor={props.backgroundColor}
                    imageUrl={props.logo}
                />
                <Box sx={{ padding: '0px 6px', flexGrow: 1, }}>
                    <MerchantName fontColor={props.fontColor}>{props.merchantName}</MerchantName>
                </Box>
                {props.amount && props.currency &&
                    <Box sx={{ color: props.fontColor }}>
                        <CardDenomination>{props.amount}</CardDenomination>
                        <Box>
                            {!props.hideCurrencyCode && <Typography sx={{ fontSize: 12, fontWeight: 'bold', textAlign: 'right' }}>{props.currency}</Typography>}
                        </Box>
                    </Box>
                }
            </Box>
            <GiftCardNumberWrapper>
                {
                    props.useGiftCardNumber &&
                    <Box>
                        <Box>
                            <CardNumberLabel fontColor={props.fontColor}>
                                Gift card code
                            </CardNumberLabel>
                            <GiftCardNumber onClick={copyToClipboard}>{cardNumber}</GiftCardNumber>
                        </Box>
                        {props.pin && <PinWrapper>
                            <PinHeader>Pin</PinHeader>
                            <Pin>{props.pin}</Pin>
                        </PinWrapper>
                        }
                    </Box>
                }
            </GiftCardNumberWrapper>
        </Wrapper>
    )
}

export default PurchasedGiftCardImage;