import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Alert, Box, Container, Dialog, Grid, Slide, styled, Typography } from '@mui/material';
import AmountInput from './components/AmountInput';
import CardPreview from './components/CardPreview';
import FullPageLoader from '../components/FullPageLoader';
import { currencyFormatter } from '../../../utils/helpers';
import { useStores } from '../../../hooks/use-stores';
import SparkButton from '../../../components/SparkButton';
import { GIFT_CARD_MAXIMUM_VALUE, GIFT_CARD_MINIMUM_VALUE } from '../../../constants';
import NewSigninForm from '../../../components/NewSigninForm';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalWrapper = styled(Box)(({ theme }) => ({
    background: '#080A0C',
    zIndex: 1000000,
    margin: '0 auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative'
}));

const CheckoutContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0
}));

const Review = () => {
    const { t } = useTranslation();
    const { authStore, commonStore, giftcardStore } = useStores();
    const {
        selectedMerchantInfo
    } = giftcardStore;

    const [loading, setLoading] = useState<boolean>(true);
    const [authModalOpen, setAuthModalOpen] = useState<boolean>(false);
    const [valueInCents, setValueInCents] = useState<string>('0');
    const [error, setError] = useState<string>('')
    const [incorrectPriceChosen, setIncorrectPriceChosen] = useState<boolean>(false);

    const navigate = useNavigate();

    /* 
        The minimum price that we can create a card for is actually 5.00.
        Sadly the API doesn't ensure this, so we have to fix it here
    */
    const isMinUnderFive = parseFloat(giftcardStore.selectedMerchantInfo?.eCodeInfo.minimumValue) < GIFT_CARD_MINIMUM_VALUE;
    const staticPrices = selectedMerchantInfo?.eCodeInfo?.staticPrices;

    const minPriceInDollars = isMinUnderFive ? String(GIFT_CARD_MINIMUM_VALUE) : selectedMerchantInfo?.eCodeInfo?.minimumValue;
    const maxPriceInDollars = selectedMerchantInfo?.eCodeInfo?.maximumValue || String(GIFT_CARD_MAXIMUM_VALUE);

    const setValue = (priceInCents: string) => {
        if (incorrectPriceChosen) {
            setIncorrectPriceChosen(false);
        }

        const currentPriceInCents = parseFloat(priceInCents);
        const maxPriceInCents = parseFloat(maxPriceInDollars) * 100;

        if (currentPriceInCents > maxPriceInCents) {
            priceInCents = String(maxPriceInCents);
        }

        setValueInCents(priceInCents);
    }

    const goHome = useCallback(() => {
        navigate('/giftcards/shop');
    }, [])

    const handleAuthModalClose = useCallback(() => {
        setAuthModalOpen(false);
    }, [authModalOpen]);

    const isOutOfStock = useMemo(() => {
        // We depend on the outOfStock boolean to know if either ranged or static prices are oos.
        // If static prices are in stock, then the eCodeInfo?.staticPrices list will contain the in stock denominations
        return selectedMerchantInfo.outOfStock;
    }, [selectedMerchantInfo])

    // If the price is in the static price list, we check if it's in the list of static prices and if it's out of stock.
    // If the price is in range (if it has a range), ensure it sits in the range
    const isEnteredPriceValid = useCallback(() => {
        const valueInDollars = parseFloat(valueInCents) / 100;

        if (staticPrices && staticPrices.length) {
            const valueString = currencyFormatter(valueInDollars).replace('$', '')

            const isSelectedValidStaticPrice = staticPrices.includes(valueString);
            if (isSelectedValidStaticPrice) {
                return true;
            }
        } else {
            const min = parseFloat(minPriceInDollars);
            const max = parseFloat(maxPriceInDollars);
            const total = valueInDollars;

            if (total >= min && total <= max) {
                return true;
            }
        }

        return false;
    }, [valueInCents]);

    const navigateToCheckout = async () => {
        const orderTotal = String(parseFloat(valueInCents) / 100);
        giftcardStore.orderTotal = orderTotal;
        giftcardStore.finalOrderTotals.money = orderTotal;
        giftcardStore.finalOrderTotals.sparks = '0';

        if (authStore.isRestrictedUser) {
            commonStore.warn(t('auth:restrictedWarning'));
        } else {
            navigate('/giftcards/checkout');
        }
    }

    const finalizePurchase = async () => {
        try {
            if (isEnteredPriceValid()) {
                // If the user is the demo account user; prompt them to sign in to an actual account
                // The modal will take care of the rest of the setup
                if (authStore.isRestrictedUser) {
                    setAuthModalOpen(true);
                    return;
                }

                await navigateToCheckout();
            } else {
                setIncorrectPriceChosen(true);
            }
        } catch (e: any) {
            commonStore.warn(e)
        }
    }

    const load = async () => {
        await giftcardStore.loadSelectedMerchantInfo(!authStore.isAuthenticated);
        setLoading(false);
    }

    useEffect(() => {
        if (!giftcardStore.selectedMerchantId) {
            goHome();
        }
        load();
    }, []);

    // If we get to this page without having selected a merchant, go home
    if (!giftcardStore.selectedMerchantId) {
        return null;
    }

    return (
        loading || (!giftcardStore.selectedMerchantInfo?.name && !giftcardStore.error) ? <FullPageLoader />
            :
            (giftcardStore.error) ? <Alert severity="error">{error || t('global:networkError')}</Alert>
                :
                <Container maxWidth="sm">
                    <Grid container alignItems={'center'} marginBottom={1} marginTop={-1}>
                        <Grid item xs={12} padding={1} >
                            <Typography textAlign={'center'} fontWeight={'bold'} fontSize={26}>
                                {t('giftCards:review.enterAmount')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <CardPreview
                        merchantName={giftcardStore.selectedMerchantInfo.name}
                        cardImage={giftcardStore.selectedMerchantInfo.logoImageUrl}
                        cardBackgroundColor={giftcardStore.selectedMerchantInfo.backgroundColor}
                        description={giftcardStore.selectedMerchantInfo.eCodeInfo.description}
                        redemptionInstructions={giftcardStore.selectedMerchantInfo.eCodeInfo.redeemInstructionsHtml}
                        terms={giftcardStore.selectedMerchantInfo.eCodeInfo.termsAndConditionsHtml}
                    />
                    <CheckoutContainer disableGutters>
                        <AmountInput
                            isValidPrice={isEnteredPriceValid()}
                            isStaticPrice={!selectedMerchantInfo.eCodeInfo.hasRange}
                            staticPrices={staticPrices}
                            setValueInCents={setValue}
                            valueInCents={valueInCents}
                            max={maxPriceInDollars}
                            min={minPriceInDollars}
                            currency={giftcardStore.selectedMerchantCurrency}
                            isOutOfStock={isOutOfStock}
                            incorrectPrice={incorrectPriceChosen}
                        />
                        {!isOutOfStock && <Box sx={{ width: '100%', maxWidth: '500px', marginTop: 2, marginBottom: 4 }}>
                            <SparkButton disabled={loading} onClick={finalizePurchase}>
                                <Typography letterSpacing={2} fontWeight="bold">{t('giftCards:review.reviewAndPay')}</Typography>
                            </SparkButton>
                        </Box>
                        }
                    </CheckoutContainer>
                    <Dialog
                        open={authModalOpen}
                        onClose={handleAuthModalClose}
                        PaperComponent={Box}
                        fullWidth
                        fullScreen
                        TransitionComponent={Transition}
                    >
                        <ModalWrapper>
                            <NewSigninForm
                                onSuccessfulAuth={navigateToCheckout}
                                onClose={handleAuthModalClose}
                            />
                        </ModalWrapper>
                    </Dialog>
                </Container>
    )
};

export default observer(Review);