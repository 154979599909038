import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { Alert, Box, Button, CircularProgress, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreateAccountPage } from '.';
import SparkButton from '../../components/SparkButton';
import { useStores } from '../../hooks/use-stores';
import HeaderText from './components/HeaderText';
import { BottomContainer, MainContainer } from '../../components/TopBottomLayout';

const RoundTelInput = styled(MuiTelInput)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '14px',
  marginTop: 6,
  'fieldset': {
    borderRadius: '14px'
  },
  [theme.breakpoints.up('xs')]: {
    maxWidth: '400px'
  }
}));


/*
 Steps to verification:
 1a) User enters phone, email, and password on previous screen for auth with firebase
 1b) User authenticates with Apple or Google for auth with firebase
 2) Get id token from firebase
 3) Using idToken from firebase, sign in the user
 4) User enters phone number
 5a) Call auth/registration/auth-url with phone number
 5b) SMS sent to user on backend
 5c) Start polling auth/phone-verify-status on frontend
 6) Watch for registrationVerified to be true
 */

const PhoneVerification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authStore, commonStore, identityStore } = useStores();
  const { gettingAuthUrl, pollVerificationResult, verificationError, verifyResult } = identityStore;

  const [error, setError] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const isPhoneValid = useMemo(() => {
    return matchIsValidTel(phoneNumber)
  }, [phoneNumber]);

  const handleChangePhoneNumber = useCallback((value: string, info: any) => {
    if (error) {
      setError('');
    }

    setPhoneNumber(info.numberValue);
  }, [phoneNumber]);

  const startVerifyProcess = async () => {
    setError('');
    await identityStore.getAuthUrl(phoneNumber);

    if (identityStore.authResult) {
      identityStore.startPollVerificationResultRegistration();
    }
  }

  const resendVerificationLink = useCallback(async () => {
    identityStore.resendSMS();
  }, []);

  // The user's phone was invalid or a network error ocurred
  useEffect(() => {
    if (verificationError) {
      if (verificationError == 'INVALID_PHONE') {
        setError(t('createAccount:phoneVerification.invalidPhone'));
      } else if (verificationError.includes('associated with an existing account')) {
        setError(t('createAccount:phoneVerification.phoneExists'));
      } else {
        setError(t('createAccount:phoneVerification.genericFailure'));
      }
    }
  }, [verificationError])

  useEffect(() => {
    if (identityStore.resendSMSError) {
      commonStore.warn(t('createAccount:phoneVerification.resendFailure'));
    }
  }, [identityStore.resendSMSError]);

  useEffect(() => {
    if (identityStore.resendSMSResult?.message == 'success') {
      if (identityStore.resendSMSResult?.verified) {
        commonStore.success(t('createAccount:phoneVerification.resendSuccess'));
      } else {
        commonStore.warn(t('createAccount:phoneVerification.resendFailure'))
      }
    }
  }, [identityStore.resendSMSResult]);

  useEffect(() => {
    if (identityStore.possessionCheckedRegistration) {
      authStore.finishCreateAccountStep(CreateAccountPage.PHONE_VERIFICATION)
    }
  }, [verifyResult]);


  // Check if user can be here - abstract to HOC 
  useEffect(() => {
    if (!authStore.canVerifyPhone) {
      navigate('/home')
    }
  }, [])

  if (!authStore.canVerifyPhone) {
    return null;
  }

  return (
    <MainContainer minHeight="600px">
      <Box sx={{ visibility: error ? 'visible' : 'hidden', mb: 1 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
      <HeaderText
        title={t('createAccount:phoneVerification.title')}
        description={pollVerificationResult ? t('createAccount:phoneVerification.clickLink') : t('createAccount:phoneVerification.description')}
      />
      {
        pollVerificationResult ?
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={4}>
            <CircularProgress size={60} />
          </Box>
          :
          <Box display="flex" flexDirection="column" mt={3} width="100%" alignItems="center">
            <RoundTelInput
              disableDropdown
              splitCallingCode
              forceCallingCode
              fullWidth
              defaultCountry="US"
              onlyCountries={['US']}
              value={phoneNumber}
              onChange={handleChangePhoneNumber}
              label={t('createAccount:phoneVerification.phoneNumber')}
            />
            <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
              <img width="100%" src={`${process.env.PUBLIC_URL}/phoneVerification.svg`} />
            </Box>
          </Box>
      }
      <BottomContainer>
        {pollVerificationResult && <Box display="flex" flexDirection="column" alignItems="center" gap={0}>
          <Typography variant="caption">{t('createAccount:phoneVerification.didntReceive')}</Typography>
          <Button
            onClick={resendVerificationLink}
            disableRipple
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }}
            disabled={identityStore.resendingSMS}>
            <Typography mt={-.5} variant="body2" fontWeight="bold" textTransform="none">{t('auth:phoneVerify.resendLink')}</Typography>
          </Button>
        </Box>
        }
        {!pollVerificationResult && <SparkButton
          onClick={startVerifyProcess}
          loading={gettingAuthUrl}
          disabled={gettingAuthUrl || !isPhoneValid}>
          {t('global:next')}
        </SparkButton>}
      </BottomContainer>
    </MainContainer >
  );
}

export default observer(PhoneVerification);