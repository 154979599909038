import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';
import { BLUE_GRADIENT } from '../constants';

interface SparkButtonProps {
    size?: 'small' | 'large'
    backgroundColor?: string;
}

const SparkButton = styled(LoadingButton, {
    // Configure which props should be forwarded on DOM
    shouldForwardProp: (prop) =>
        prop != 'backgroundColor' && prop != 'size'
}) <SparkButtonProps>`
box-shadow: 0 20px 30px -10px rgba(54, 54, 54, 0.6);
padding:  ${(props: SparkButtonProps) => props.size == 'large' ? `18px` : `12px`};
letter-spacing: -0.5px;
display: inline-block;
border-radius: 100px;
font-family: "colfax-medium", sans-serif;
font-size: 14px;
letter-spacing: 1px;
font-weight: bold;
-webkit-transition: all 0.4s ease;
transition: all 0.4s ease;
text-align: center;
position: relative;
background: ${(props: SparkButtonProps) => props.backgroundColor || 'linear-gradient(91.26deg, #8AD4EC 0%, #EF96FF 21.74%, #FF56A9 54.03%, #FFAA6C 85.28%)'};
cursor: pointer;
color: #fff;
width: 100%;
z-index: 2;
text-transform: none;
.MuiLoadingButton-loadingIndicator {
    color: rgba(255, 255, 255, .8);
    vertical-align: middle;
    display: inline;

    & > span {
        width: 20px !important;
        height: 20px !important;
    }
  }
}
`;

const BlueSparkButton = (props: any) => <SparkButton {...props} backgroundColor={`linear-gradient(${BLUE_GRADIENT})`} />

export { BlueSparkButton };
export default SparkButton;
