import moment from 'moment-timezone';
import BaseService from './BaseService';
import IProveAuthResponse from '../interfaces/ProveAuth';
import IProveFinalizeResponse from '../interfaces/ProveFinalize';
import IVerificationResultResponse from '../interfaces/ProveVerification';
import IEligibilityResultResponse from '../interfaces/ProveEligibility';
import IIdentityResultResponse from '../interfaces/ProveIdentity';
//import { formatDOB } from '../utils/helpers';

class Auth extends BaseService {
    constructor() {
        super('/api/v1');
    }

    verify(idToken: string, refreshToken: string) {
        return this.post(
            `${this.baseUrl}/session/verify`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'X-Authorization': `Bearer ${refreshToken}`,
                },
            }
        );
    }

    async signIn(idToken: string) {
        return this.post(`${this.baseUrl}/signin`, {
            idToken,
            userSession: {
                pushTokenId: 'Web',
                ipAddress: 'Web',
                appVersion: 'Web',
                model: 'Web',
                os: 'Web',
                osVersion: 'Web',
            },
        });
    }

    async refreshAccessToken(idToken: string, refreshToken: string) {
        return this.post(`${this.baseUrl}/session/refresh`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'X-Authorization': `Bearer ${refreshToken}`,
                }
            });
    }

    async getVerifyStatus(): Promise<IVerificationResultResponse> {
        const response = await this.get(`${this.baseUrl}/auth/phone-verify-status`);
        return response as IVerificationResultResponse;
    }

    async verifyIdentity(
        firstName: string,
        lastName: string,
        dob: string,
        last4: string,
        city: string,
        address: string,
        region: string,
        postalCode: string
    ): Promise<IProveFinalizeResponse> {
        const response = await this.post(`${this.baseUrl}/auth/verify-identity`, {
            firstName,
            lastName,
            dob: moment(dob).format("YYYY-MM-DD"),
            last4,
            city,
            address,
            region,
            postalCode,
        });
        return response as IProveFinalizeResponse;
    }

    async eligibility(): Promise<IEligibilityResultResponse> {
        const response = await this.post(`${this.baseUrl}/auth/eligibility`);
        return response as IEligibilityResultResponse;
    }

    async identity(dob: any, last4: string | null): Promise<IIdentityResultResponse> {
        const response = await this.post(`${this.baseUrl}/auth/identity`, {
            dob: moment(dob).format("YYYY-MM-DD"),
            last4,
        });
        return response as IIdentityResultResponse;
    }

    async checkTrust(vfp: string, userAuthGuid: string): Promise<IProveFinalizeResponse> {
        const response = await this.get(`${this.baseUrl}/auth/instant-link`, {
            vfp,
            userAuthGuid,
        });
        return response as IProveFinalizeResponse;
    }

    async getAuthUrl(phoneNumber: string, ipAddress: string): Promise<IProveAuthResponse> {
        if (!phoneNumber.startsWith('+1')) phoneNumber = '+1' + phoneNumber;
        const response = await this.post(`${this.baseUrl}/auth/registration/auth-url`, {
            isNative: false,
            consentStatus: 'optedIn',
            phoneNumber,
            sourceIp: ipAddress
        });
        return response as IProveAuthResponse;
    }

    async resendSMS(sourceIp: string): Promise<any> {
        return await this.post(`${this.baseUrl}/auth/registration/auth-url/resend`, {
            sourceIp
        });
    }

    async checkTrustRegistration(vfp: string, userAuthGuid: string): Promise<IProveFinalizeResponse> {
        const response = await this.get(`${this.baseUrl}/auth/registration/instant-link`, {
            vfp,
            userAuthGuid,
        });
        return response as IProveFinalizeResponse;
    }

    async getIpAddress() {
        const response = await this.get(`${this.baseUrl}/get-ip-address`);
        return response;
    }
}

export default Auth;
