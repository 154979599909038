import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Button, Container, CardContent, CardActions, Card } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useStores } from '../hooks/use-stores';
import GradientButton from '../components/GradientButton';
import successAnimation from '../lottie/success.json';
import FullPageLoader from './Giftcards/components/FullPageLoader';

interface Props {
    vfp?: string | null;
    finalize?: boolean;
    mobile?: boolean;
    userAuthGuid?: string;
    registration?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
    faIcons: {
        marginRight: '5px',
    },
    divider: {
        margin: '20px 0',
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        paddingTop: '58px',
        paddingLeft: '1px',
        paddingRight: '1px',
    },
    content: {
        width: '100%',
        height: '100%',
        paddingLeft: '1px',
        paddingRight: '1px',
    },
    card: {
        width: '100%',
    },
}));

export default observer(function ContinueAuth(props: Props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { identityStore } = useStores();
    const { finalizeResult, finalizing } = identityStore;
    const { userAuthGuid } = useParams();

    useEffect(() => {
        if ((!!props.finalize || !!props.mobile) && props.vfp && userAuthGuid) {
            if (!!props.registration) {
                identityStore.checkTrustRegistration(props.vfp, userAuthGuid);
            } else {
                identityStore.checkTrust(props.vfp, userAuthGuid);
            }
        }
    }, [props.finalize, props.mobile, props.vfp, userAuthGuid]);

    const handleRedirect = () => {
        if (props.mobile) {
            //@ts-ignore
            window.location.href = 'https://sparkwallet.page.link/phoneVerify';
        } else {
            //@ts-ignore
            if (window.parent) {
                window.parent.location.href = `${process.env.REACT_APP_MY_URL}/wallet/ewallet/add_more_money/verify_identity`;
            } else {
                window.location.href = `${process.env.REACT_APP_MY_URL}/wallet/ewallet/add_more_money/verify_identity`;
            }
        }
    };

    if (finalizing === true || finalizing === null)
        return (
            <FullPageLoader />
        );

    return (
        <Container maxWidth={'md'} className={classes.wrapper}>
            <Container className={classes.container}>
                <Container maxWidth={'md'} className={classes.content}>
                    <Card variant="outlined" className={classes.card}>
                        <CardContent>
                            {!finalizeResult || !finalizeResult.verified ? (
                                <>
                                    <div style={{ textAlign: 'center' }}>
                                        {props.vfp!.length > 10 ? (
                                            //todo: when doing the monetary check vfp comes back > 10 so we see the flicker
                                            //this check fixed the registration flow only
                                            <>
                                                <h2
                                                    style={{
                                                        lineHeight: '60px',
                                                        fontSize: '45px',
                                                        margin: 'auto',
                                                        color: 'white',
                                                    }}
                                                >
                                                    {t('auth:continueAuth.failureToVerify')}
                                                </h2>
                                                <h4
                                                    style={{
                                                        fontSize: '18px',
                                                        marginBottom: '5px',
                                                        color: 'white',
                                                    }}
                                                >
                                                    {t('auth:continueAuth.phoneVerifyFail')}
                                                </h4>
                                            </>
                                        ) : (
                                            <>
                                                <h2
                                                    style={{
                                                        lineHeight: '60px',
                                                        fontSize: '45px',
                                                        margin: 'auto',
                                                        color: 'white',
                                                    }}
                                                >
                                                    {t('auth:continueAuth.phoneVerification')}
                                                </h2>
                                                <h4
                                                    style={{
                                                        fontSize: '18px',
                                                        marginBottom: '5px',
                                                        color: 'white',
                                                    }}
                                                >
                                                    {t('auth:continueAuth.loading')}
                                                </h4>
                                            </>
                                        )}
                                        {props.mobile ? (
                                            <>
                                                <GradientButton
                                                    color="primary"
                                                    variant="contained"
                                                    size="large"
                                                    fullWidth
                                                    style={{ color: 'black' }}
                                                    onClick={handleRedirect}
                                                >
                                                    {t('auth:return')}
                                                </GradientButton>
                                            </>
                                        ) : (
                                            <>
                                                <h4
                                                    style={{
                                                        fontSize: '12px',
                                                        marginTop: '5px',
                                                        marginBottom: '5px',
                                                        color: '#F6C604'
                                                    }}
                                                >
                                                    {t('auth:continueAuth.continueWarning')}
                                                </h4>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    size="large"
                                                    fullWidth
                                                    style={{ color: 'white' }}
                                                    onClick={handleRedirect}
                                                >
                                                    {t('auth:continue')}
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ textAlign: 'center' }}>
                                        <Lottie animationData={successAnimation} loop={false} />
                                        <h2
                                            style={{
                                                lineHeight: '32px',
                                                fontSize: '24px',
                                                marginBottom: '32px',
                                                margin: 'auto',
                                                color: 'white',
                                            }}
                                        >
                                            {t('auth:continueAuth.phoneVerifySuccess')}
                                        </h2>
                                        {props.mobile ? (
                                            <>
                                                <GradientButton
                                                    color="primary"
                                                    variant="contained"
                                                    size="large"
                                                    fullWidth
                                                    style={{ color: 'white' }}
                                                    onClick={handleRedirect}
                                                >
                                                    {t('auth:return')}
                                                </GradientButton>
                                            </>
                                        ) : (
                                            <>
                                                <h4
                                                    style={{
                                                        fontSize: '12px',
                                                        marginTop: '24px',
                                                        marginBottom: '24px',
                                                        color: '#F6C604'
                                                    }}
                                                >
                                                    {t('auth:continueAuth.continueWarning')}
                                                </h4>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    size="large"
                                                    fullWidth
                                                    style={{ color: 'white' }}
                                                    onClick={handleRedirect}
                                                >
                                                    {t('auth:continue')}
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </CardContent>
                        <CardActions></CardActions>
                    </Card>
                </Container>
            </Container>
        </Container>
    );
});
