
import {
    makeAutoObservable, runInAction,
} from 'mobx';
import { Merchant, MerchantInfo } from './models/Merchant';
import AsyncCheckoutInfo from './models/AsyncCheckoutInfo';
import CheckoutToken from './models/CheckoutToken';
import countries from '../utils/countries';
import services from '../services';
import GiftCard from './models/GiftCard';
import PurchasedCard from './models/PurchasedCard';
import moment from 'moment';
import ReferralLink from './models/ReferralLink';
import { SPARK_WALLET_PAYMENT_METHOD } from '../constants';
import PaymentMethod from './models/PaymentMethod';
import { extractErrorMessage } from '../utils/helpers';

const DEFAULT_COUNTRY = 'US';

class GiftcardStore {
    public merchants: Merchant[] = [];

    public selectedMerchantCurrency: string = '';
    public selectedMerchantId: string = '';
    public selectedMerchantInfo = new MerchantInfo({}, {})
    public countryCode: string = DEFAULT_COUNTRY;
    public searchQuery: string = '';
    public category: string = '';
    public applySparks: boolean = false;
    public orderTotal: string = '0.00'; // default price to 0
    public checkoutToken: CheckoutToken | null = null;
    public myCards: GiftCard[] = [];
    public asyncCheckoutInfo: AsyncCheckoutInfo | null = null;
    public purchasedCardInfo: PurchasedCard | null = null;
    public selectedPaymentMethod: PaymentMethod = SPARK_WALLET_PAYMENT_METHOD;
    public referralLink: ReferralLink | null = null;
    public featuredMerchants: any = null;
    public paymentMethods: Array<PaymentMethod> = [];
    public finalOrderTotals = { money: '', sparks: '' }
    public error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.selectedMerchantCurrency = '';
        this.selectedMerchantId = '';
        this.selectedMerchantInfo = new MerchantInfo({}, {})
        this.countryCode = DEFAULT_COUNTRY;
        this.searchQuery = '';
        this.category = '';
        this.applySparks = false;
        this.orderTotal = '0.00'; // default price to 0
        this.checkoutToken = null;
        this.myCards = [];
        this.asyncCheckoutInfo = null;
        this.purchasedCardInfo = null;
        this.selectedPaymentMethod = SPARK_WALLET_PAYMENT_METHOD;
        this.referralLink = null;
        this.featuredMerchants = null;
        this.paymentMethods = [];
        this.finalOrderTotals = { money: '', sparks: '' }
        this.error = null;
    }

    setError(error: Error | string | null) {
        this.error = extractErrorMessage(error);
    }

    async loadMerchants(isPublic: boolean) {
        try {
            if (this.merchants.length > 0) {
                return;
            }
            this.error = null;
            const result = await services.Merchants.getMerchants(isPublic);

            runInAction(() => {
                this.merchants = result.merchants;
            })
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            })
        }
    }

    async loadFeaturedMerchants() {
        try {
            this.error = null;
            const result = await services.Merchants.getFeaturedMerchants();

            runInAction(() => {
                this.featuredMerchants = result;
            })
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            })
        }
    }

    async loadSelectedMerchantInfo(isPublic: boolean) {
        try {
            if (!this.selectedMerchantId) {
                throw new Error('Merchant was not selected');
            }

            this.error = null;

            const result = await services.Merchants.getMerchantInfo(this.selectedMerchantId, isPublic);
            runInAction(() => {
                this.selectedMerchantInfo = new MerchantInfo(result.merchantDetails.merchantInfo, result.merchantDetails.eCodeInfo);
            })
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            });
        }
    }

    async getCheckoutToken() {
        try {
            this.error = null;

            const merchantID = this.selectedMerchantInfo.id;
            const eCodeID = this.selectedMerchantInfo.eCodeInfo.id;
            const amount = this.orderTotal;
            const applySparks = this.applySparks;

            if (!merchantID || !eCodeID || !amount) {
                throw new Error('Missing information')
            }

            const result = await services.Merchants.getCheckoutToken(merchantID, eCodeID, amount, applySparks);

            runInAction(() => {
                this.checkoutToken = result.checkoutInfo;
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            });
        }
    }

    get checkoutTokenExpired() {
        const now = moment();
        const expiration = moment(this.checkoutToken?.expiry);
        return expiration.diff(now) <= 0;
    }

    async sparkCashCheckout() {
        this.error = null;
        try {
            if (!this.checkoutToken || this.checkoutTokenExpired) {
                throw Error('Checkout token invalid')
            }
            const result = await services.Merchants.sparkCashCheckout(this.checkoutToken.checkoutToken);

            runInAction(() => {
                this.asyncCheckoutInfo = result;
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e)
            });
        }
    }

    async lightningCheckoutStart() {
        this.error = null;

        try {
            if (!this.checkoutToken || this.checkoutTokenExpired) {
                throw Error('Checkout token invalid')
            }
            const result = await services.Merchants.lightningCheckout(this.checkoutToken.checkoutToken);

            runInAction(() => {
                this.asyncCheckoutInfo = result;
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            });
        }
    }

    async lightningCheckoutRefresh() {
        this.error = null;

        try {
            if (!this.checkoutToken || this.checkoutTokenExpired) {
                throw Error('Checkout token invalid')
            }

            const result = await services.Merchants.lightningCheckoutRefresh(this.checkoutToken.checkoutToken);
            runInAction(() => {
                this.asyncCheckoutInfo = result
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            });
        }
    }

    async getMyGiftCards(status: 'active' | 'archived' = 'active') {
        this.error = null;

        try {
            const result = await services.Merchants.getMyGiftCards(status);

            runInAction(() => {
                this.myCards = result.giftCards;
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            });
        }
    }

    async loadPaymentMethods() {
        try {
            this.error = null;

            const result = await services.Merchants.getPaymentMethods();

            runInAction(() => {
                // @ts-ignore
                this.paymentMethods = result;
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            });
        }
    }

    async generateReferralLink() {
        try {
            this.error = null;

            const result = await services.User.generateRefLink();
            runInAction(() => {
                this.referralLink = result.shareLink;
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            })
        }
    }

    selectMerchant(merchant: Merchant) {
        this.selectedMerchantId = merchant.id;
        this.selectedMerchantCurrency = merchant.currencyCode;
    }

    switchCountry(value: string) {
        this.countryCode = value;
    }

    merchantsByCategory = (category: string) => {
        let merchantList = [];
        if (category == 'featured') {
            // do nothing;
        }
        merchantList = this.countryMerchants.filter(m => m.categories.includes(category));
        return merchantList;
    }

    // Comma separated string of slugs
    merchantsBySlug = (slugs: string, isFeatured?: boolean) => {
        let merchantList: Array<Merchant> = [];
        if (!slugs || !slugs.length) {
            return [];
        }

        let slugList = slugs.split(',').map(slug => slug.trim());

        slugList.forEach((slug) => {
            const merchantToAdd = this.countryMerchants.find(merchant => merchant.slug == slug);
            if (merchantToAdd) {
                merchantList.push(merchantToAdd)
            }
        })

        // Sort ascending alphabetically
        return merchantList.sort((a, b) =>
            a.name.localeCompare(b.name)
        );
    }


    filterMerchantsByName = (name: string, merchants: Merchant[]) => {
        const regex = new RegExp(name, 'i');
        return merchants.filter(m => m.name.match(regex));
    }

    markCardAsUsed = async (giftCardID: string) => {
        try {
            this.error = null;
            await services.Merchants.markCard(giftCardID, 'archived');
        } catch (e: any) {
            runInAction(() => {
                this.setError(e)
            })
        }
    }

    markCardAsActive = async (giftCardID: string) => {
        try {
            this.error = null;
            await services.Merchants.markCard(giftCardID, 'active');
        } catch (e: any) {
            runInAction(() => {
                this.setError(e)
            });
        }
    }

    get countryMerchants() {
        // filter by country and sort alphabetically
        return this.merchants.filter((x) => x.availableCountries.includes(this.countryCode)).sort((a, b) => a.name.localeCompare(b.name));
    }

    get countryName() {
        let country = countries.find(
            (country) => country.code == this.countryCode
        );

        if (country) {
            return country.name;
        }
        return '';
    }

    get merchantsOfSelectedCategory() {
        return this.merchantsByCategory(this.category)
    }

    calculateEarnings(userSparks: number) {
        let sparksEarned = 0;

        const orderTotal = Number(this.orderTotal);
        const orderTotalAsSparks = orderTotal * 100;
        const usersSparksAsDollars = userSparks / 100;
        const cashbackPercent = Number(this.selectedMerchantInfo?.eCodeInfo?.cashbackPercentage) || 0

        sparksEarned = Math.floor(cashbackPercent * orderTotal);

        if (this.applySparks) {
            // User has more sparks than the order total, so their whole total is covered
            if (usersSparksAsDollars >= orderTotal) {
                this.finalOrderTotals.money = '0';
                this.finalOrderTotals.sparks = String(Math.round(orderTotalAsSparks));
                sparksEarned = 0;
            } else {
                // user has fewer sparks than the order total, so they pay with a combination of sparks and bitcoin
                // this will also catch the case where the user has zero sparks
                const cashPortionOfTotal = orderTotal - usersSparksAsDollars;
                this.finalOrderTotals.money = String(orderTotal - usersSparksAsDollars);
                this.finalOrderTotals.sparks = String(Math.round(userSparks)); // apply all the user's sparks

                sparksEarned = cashbackPercent * cashPortionOfTotal;
            }
        } else {
            this.finalOrderTotals.money = String(orderTotal);
            this.finalOrderTotals.sparks = '0';
        }

        return Math.floor(sparksEarned);
    }
}

export default GiftcardStore;