import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { CreateAccountPage } from '.';
import { useStores } from '../../hooks/use-stores';
import SparkButton, { BlueSparkButton } from '../../components/SparkButton';
import { BottomContainer, MainContainer } from '../../components/TopBottomLayout';
import { useNavigate } from 'react-router-dom';

const ConfirmEmail = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { authStore, commonStore } = useStores();

    const resendEmailVerification = async () => {
        try {
            await authStore.sendVerifyEmail();
            commonStore.success(t('createAccount:confirmEmail.verifyEmailResent'));
        } catch (e) {
            commonStore.warn(t('createAccount:confirmEmail.verifyEmailFailedToSend'));
        }
    }

    const continueSignup = async () => {
        if (await authStore.isEmailVerified()) {
            authStore.finishCreateAccountStep(CreateAccountPage.CONFIRM_EMAIL);
        } else {
            commonStore.warn(t('createAccount:confirmEmail.notYetVerified'));
        }
    }

    useEffect(() => {
        if (!authStore.currentFBUser || authStore.currentFBUser?.emailVerified) {
            navigate('/')
        }
    }, [])

    if (!authStore.currentFBUser || authStore.currentFBUser?.emailVerified) {
        return null;
    }

    return (
        <MainContainer alignItems="center" minHeight='600px'>
            <Box gap={3} display="flex" flexDirection="column" alignItems="center">
                <img width="50%" src={`${process.env.PUBLIC_URL}/confirmEmail.png`} />
                <Typography component="h1" variant="h4" fontWeight="bold">{t('createAccount:confirmEmail.title')}</Typography>
                <Typography variant="body2" textAlign="center">{t('createAccount:confirmEmail.description')}</Typography>
                <Typography variant="body2" fontWeight="bold">{authStore.userEmail}</Typography>
                <Typography variant="body2" textAlign="center">{t('createAccount:confirmEmail.checkInbox')}</Typography>
                <SparkButton onClick={continueSignup}>
                    <Typography fontWeight="bold">{t('global:continue')}</Typography>
                </SparkButton>
            </Box>
            <BottomContainer display="flex" flexDirection='column' alignItems="center">
                <Typography mb={1} variant="body2" textAlign="center">{t('createAccount:confirmEmail.notRecieved')}</Typography>
                <BlueSparkButton onClick={resendEmailVerification}>
                    <Typography fontWeight="bold">{t('createAccount:confirmEmail.notRecievedButton')}</Typography>
                </BlueSparkButton>
            </BottomContainer>
        </MainContainer>
    )

}
export default observer(ConfirmEmail);