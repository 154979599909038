import React, { SyntheticEvent, useEffect, useMemo } from 'react';
//@ts-ignore
import Helmet from 'react-helmet';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { green, grey } from '@mui/material/colors';
import {
    AppBar,
    Avatar,
    Box,
    Container,
    CssBaseline,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Menu,
    Toolbar,
} from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import { useStores } from './hooks/use-stores';
import User from './stores/models/User';
import SmallAvatar from './components/SmallAvatar';
import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/ForgotPassword';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CreateAccount, { CreateAccountPage } from './pages/CreateAccount';
import Giftcards from './pages/Giftcards';
import { onAuthStateChanged } from 'firebase/auth';
import Snackbar from './components/Snackbar';
import FullPageLoader from './pages/Giftcards/components/FullPageLoader';
import { auth } from './SparkFirebase';
import ContinueAuth from './pages/ContinueAuth';
import RequireAuth from './hocs/RequireAuth';
import IdentityVerification from './pages/IdentityVerification';
import PhoneVerification from './pages/CreateAccount/PhoneVerification';
import WinkDemo from './pages/WinkDemo';

declare module '@mui/material/styles/createPalette' {
    interface CommonColors {
        bluegray: string;
        dimblue: string;
        goldenrod: string;
        mintgreen: string;
        midgrey: string;
        darkgrey: string;
        lightgrey: string;
        rose: string;
    }
}

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: 'rgb(6, 7, 8)',
        },
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#007BFF',
            // dark: will be calculated from palette.primary.main,
            contrastText: '#FFFFFF',
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#dc004e',
            // dark: will be calculated from palette.primary.main,
            contrastText: '#FFFFFF',
        },
        common: {
            bluegray: '#7c8b9e',
            dimblue: '#0a131d',
            goldenrod: '#f6c945',
            mintgreen: green['A200'],
            darkgrey: '#101010',
            midgrey: grey[500],
            lightgrey: grey[100],
            rose: '#eb5864',
        },
    },
});

const SparkVerifyHead = () => (
    <Helmet>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <meta name="description" content="Phone Verification" />
        <title>Spark Wallet</title>
    </Helmet>
);

const SparkHead = () => (
    <Helmet>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <link rel="apple-touch-icon" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <link rel="manifest" href={`${process.env.PUBLIC_URL}/sparklogo.png`} />
        <meta name="description" content="Spark Wallet" />
        <title>Spark Wallet</title>
    </Helmet>
);

function Layout({ currentUser }: { currentUser: User | null }) {
    const { t } = useTranslation();
    const { authStore } = useStores();
    const navigate = useNavigate();

    const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleUserMenuOpen = (event: SyntheticEvent<HTMLElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const handleLogout = () => {
        authStore.logout();
        handleUserMenuClose();
        navigate('/');
    };

    const isUserMenuOpen = Boolean(userMenuAnchorEl);

    const hideToolbar = !process.env.REACT_APP_DEV_TOOLBAR;

    return hideToolbar ? (
        <Outlet />
    ) : (
        <div>
            <AppBar color={'secondary'} position="static">
                <Container maxWidth={'md'} disableGutters>
                    <Toolbar disableGutters variant="dense">
                        <span>
                            <img src={'/logo.png'} />
                            <span>Life</span>
                        </span>
                        <Box flexGrow={1}></Box>
                        {authStore.isAuthenticated && (
                            <div>
                                <IconButton color="inherit" onClick={handleUserMenuOpen}>
                                    <SmallAvatar src={currentUser?.photoURL} variant={'square'} />
                                </IconButton>
                            </div>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>
            <Menu
                anchorEl={userMenuAnchorEl}
                id={'user-menu'}
                keepMounted
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={isUserMenuOpen}
                onClose={handleUserMenuClose}
            >
                <div>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar src={currentUser?.photoURL} variant={'square'} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${currentUser?.firstName} ${currentUser?.lastName}`}
                            secondary={
                                <>
                                    {currentUser?.username} <br /> {currentUser?.userID}{' '}
                                </>
                            }
                        ></ListItemText>
                    </ListItem>
                </div>
                <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    <ListItemText primary={t('Logout')} />
                </ListItem>
            </Menu>
            <Outlet />
        </div>
    );
}

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const { authStore, commonStore } = useStores();
    const { languagesLoaded, appLoaded } = commonStore;

    const isFinalize = useMatch('/finalize/*');
    const isMobile = useMatch('/mobile/*');
    const isRegistrationFinalize = useMatch('/registration/finalize/*');
    const isRegistrationMobile = useMatch('/registration/mobile/*');
    const searchParams = new URLSearchParams(location.search);
    const vfp = searchParams.get('vfp');
    const haveVFP = Boolean(vfp);

    useEffect(() => {
        if (haveVFP) {
            if (!!isFinalize || !!isMobile || !!isRegistrationFinalize || !!isRegistrationMobile) {
            } else {
                //@ts-ignore
                window.location = `${process.env.REACT_APP_CONTINUE_AUTH_URL}?vfp=${vfp}`;
            }
        }
    }, [haveVFP, vfp, isFinalize]);

    const onVerifyRoute = useMemo(() => {
        const verifyPaths = [
            '/verify_identity',
            '/verify_phone',
            '/finalize',
            '/mobile',
            '/registration/finalize',
            '/registration/mobile',
        ];

        return verifyPaths.some((vp) => location.pathname.includes(vp));
    }, [location.pathname]);

    useEffect(() => {
        /*
            These redirections are for users who sign up to our app.
    
            Restricted users should not be redirected by these. They have no concept of email verification.
            Restricted users can only access a few pages
        */
        if (!authStore.isRestrictedUser && !authStore.isAccountComplete && !onVerifyRoute) {
            const currentRedirectPage = authStore.redirectionSteps[0];

            if (
                !location.pathname.includes('createAccount/confirmEmail') &&
                currentRedirectPage == CreateAccountPage.CONFIRM_EMAIL
            ) {
                navigate('/createAccount/confirmEmail');
            } else if (
                !location.pathname.includes('createAccount/verifyPhone') &&
                currentRedirectPage == CreateAccountPage.PHONE_VERIFICATION
            ) {
                navigate('/createAccount/verifyPhone');
            } else if (
                !location.pathname.includes('createAccount/createWallet') &&
                currentRedirectPage == CreateAccountPage.CREATE_WALLET
            ) {
                navigate('/createAccount/createWallet');
            } else if (
                !location.pathname.includes('createAccount/chooseAddress') &&
                currentRedirectPage == CreateAccountPage.CHOOSE_ADDRESS
            ) {
                navigate('/createAccount/chooseAddress');
            }
        }
    }, [location.pathname, authStore.redirectionSteps, authStore.currentSparkUser, authStore.signinError]);

    useEffect(() => {
        // https://beta.reactjs.org/learn/synchronizing-with-effects#how-to-handle-the-effect-firing-twice-in-development
        let ignore = false;

        // @ts-ignore
        const fbAuthUnsub = onAuthStateChanged(auth, async (user) => {
            if (!ignore) {
                if (user) {
                    authStore.firebaseUser = user;

                    // If there is no signin process pending, then we are returning to the site
                    // And must re-auth the user
                    if (!authStore.inProgress && !authStore.creatingAccount) {
                        await authStore.signinCurrentUser();
                    }
                }

                commonStore.setAppLoaded();
            }
        });

        commonStore.loadLanguages();

        return () => {
            fbAuthUnsub();
            ignore = true;
        };
    }, []);

    if (!languagesLoaded) {
        return <div>{languagesLoaded}</div>;
    }

    return (
        <>
            {onVerifyRoute ? <SparkVerifyHead /> : <SparkHead />}
            <I18nextProvider i18n={commonStore.i18n}>
                <ThemeProvider theme={darkTheme}>
                    <>
                        <CssBaseline />
                        {languagesLoaded}
                        {!appLoaded ? (
                            <FullPageLoader />
                        ) : (
                            <>
                                <Routes>
                                    {haveVFP && <Route index element={<ContinueAuth vfp={vfp} />} />}
                                    {haveVFP && isFinalize && (
                                        <Route path="/finalize/:userAuthGuid" element={<ContinueAuth finalize vfp={vfp} />} />
                                    )}
                                    {haveVFP && isMobile && (
                                        <Route path="/mobile/:userAuthGuid" element={<ContinueAuth mobile vfp={vfp} />} />
                                    )}
                                    {haveVFP && isRegistrationFinalize && (
                                        <Route
                                            path="/registration/finalize/:userAuthGuid"
                                            element={<ContinueAuth registration={true} finalize vfp={vfp} />}
                                        />
                                    )}
                                    {haveVFP && isRegistrationMobile && (
                                        <Route
                                            path="/registration/mobile/:userAuthGuid"
                                            element={<ContinueAuth registration={true} mobile vfp={vfp} />}
                                        />
                                    )}
                                    <Route path="/winkLogin" element={<WinkDemo />} />

                                    <Route path="/claim-giftcard" />
                                    <Route path="/winklogin" />
                                    <Route path="/shop-home" />
                                    <Route element={<Layout currentUser={authStore.currentSparkUser} />}>
                                        <Route
                                            path="/verify_identity"
                                            element={
                                                <RequireAuth>
                                                    <IdentityVerification />
                                                </RequireAuth>
                                            }
                                        />
                                        <Route
                                            path="/verify_phone"
                                            element={
                                                <RequireAuth>
                                                    <PhoneVerification />
                                                </RequireAuth>
                                            }
                                        />
                                        <Route path="/signin" element={<LoginPage />} />
                                        <Route path="/forgotpassword" element={<ForgotPassword />} />
                                        <Route path="/createAccount/*" element={<CreateAccount />} />
                                        <Route index element={<Navigate to="/giftcards/shop" />} />
                                        <Route path="/giftcards/*" element={<Giftcards />} />
                                        <Route path="*" element={<Navigate to="/giftcards/shop" />} />
                                    </Route>
                                </Routes>
                                <Snackbar />
                            </>
                        )}
                    </>
                </ThemeProvider>
            </I18nextProvider>
        </>
    );
}

export default observer(App);
