import Spouse from './Spouse';

export enum Roles {
    ADMIN = 'admin',
    USER = 'user',
    IS_MERCHANT = 'is_merchant',
    IS_CHARITY = 'is_charity',
    NEEDS_SETUP = 'needs_setup',
    SUPER_ADMIN = 'super_admin',
    LIFEINFOAPP = 'lifeinfoapp',
    AMBASSADOR = 'ambassador',
    RESTRICTED_ACCESS_SHOP = 'restricted_access_shop',
}

interface Role {
    [key: string]: boolean;
}

class User {
    preferredLanguage: string | null;
    email: string | null;
    name: string | null;
    firstName: string | null;
    lastName: string | null;
    username: string | null;
    userID: number | null;
    photoURL: string | undefined;
    spouse: Spouse | null | undefined;
    userRoles: Role;

    constructor(props: any) {
        this.preferredLanguage = props.preferredLanguage;
        this.email = props.email;
        this.name = props.name;
        this.photoURL = props.photoURL;
        this.firstName = props.firstName;
        this.lastName = props.lastName;
        this.username = props.username;
        this.userID = props.userID;
        this.userRoles = props.userRoles;

        if (props.spouse) {
            this.spouse = new Spouse(props.spouse);
        }
    }
}

export default User;
