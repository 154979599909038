import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Box, Container, Stack, Typography } from '@mui/material';
import SparkButton from '../../components/SparkButton';

interface Props {
    onNext: () => void;
}

const IdentityVerifyHome = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Container>
            <Stack gap={2} alignItems="center">
                <Typography fontWeight="bold" component="h1" variant="h5">{t('auth:identityVerify.title')}</Typography>
                <Typography variant="body2">{t('auth:identityVerify.description')}</Typography>
                <Typography fontWeight="bold" variant="body2">{t('auth:identityVerify.process')}</Typography>
                <Box mb={1}>
                    <Typography variant="body2">{t('auth:identityVerify.step1')}</Typography>
                    <Typography variant="body2">{t('auth:identityVerify.step2')}</Typography>
                    <Typography variant="body2">{t('auth:identityVerify.step3')}</Typography>
                </Box>
                <SparkButton onClick={props.onNext}>
                    {t('auth:identityVerify.beginVerification')}
                </SparkButton>
            </Stack>
        </Container>
    )
}

export default observer(IdentityVerifyHome);