import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import {
    Alert,
    Box,
    Container,
    Typography,
    Grid,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useStores } from '../../../hooks/use-stores';
import { Merchant } from '../../../stores/models/Merchant';
import FeaturedMerchants from './components/FeaturedMerchants';
import FullPageLoader from '../components/FullPageLoader';
import MerchantDisplay from '../shared/MerchantDisplay';
import TopSearchBar from '../shared/TopSearchBar';

const Merchants = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { authStore, commonStore, giftcardStore } = useStores();
    const navigate = useNavigate();
    const theme = useTheme();
    const searchParams = new URLSearchParams(location.search);
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [loading, setLoading] = useState<boolean>(true);
    const {
        countryMerchants,
    } = giftcardStore;

    const onSelectMerchant = (merchant: Merchant) => {
        giftcardStore.selectMerchant(merchant)
        navigate('/giftcards/review');
    }

    useEffect(() => {
        if (searchParams.get('unauthorized') == 'true') {
            commonStore.showError(t('auth:noPermission'));
            searchParams.delete('unauthorized');
        }
    }, [searchParams])

    useEffect(() => {
        (async () => {
            giftcardStore.reset();

            let promiseList = [giftcardStore.loadMerchants(!authStore.isAuthenticated)];
            if (!giftcardStore.featuredMerchants) {
                promiseList.push(giftcardStore.loadFeaturedMerchants());
            }

            await Promise.all(promiseList);
            setLoading(false);
        })();
    }, []);

    return (
        <Container>
            {
                loading ?
                    <FullPageLoader />
                    :
                    <>
                        {(countryMerchants.length == 0 || giftcardStore.error) ?
                            <Alert severity="error">{t('giftCards:merchants.unavailable')}</Alert>
                            :
                            <>
                                <Box sx={{ display: { xs: 'flex', md: 'none', }, width: '100%' }}>
                                    <TopSearchBar
                                        onSelectItem={onSelectMerchant}
                                        searchItems={giftcardStore.countryMerchants}
                                        noOptionsText={t('giftCards:noMerchantsFound')}
                                        formatOptionsLabel={(merchant: Merchant) => merchant.name}
                                        large={isDesktop}
                                    />
                                </Box>
                                <>
                                    {giftcardStore.featuredMerchants?.featured && <Grid item xs={12}>
                                        <FeaturedMerchants
                                            category={'featured'}
                                            merchants={giftcardStore.merchantsBySlug(giftcardStore.featuredMerchants.featured, true)}
                                            onSelectMerchant={onSelectMerchant}
                                            largeImages
                                        />
                                    </Grid>
                                    }
                                    {/* TODO: Abstract this into a .map() */}
                                    <Grid container>
                                        {giftcardStore.featuredMerchants?.food &&
                                            <Grid item xs={12}>
                                                <FeaturedMerchants
                                                    title={t('giftCards:featuredMerchants.food.title')}
                                                    subtitle={t('giftCards:featuredMerchants.food.subtitle')}
                                                    category={'food-and-drink'}
                                                    merchants={giftcardStore.merchantsBySlug(giftcardStore.featuredMerchants?.food)}
                                                    onSelectMerchant={onSelectMerchant}
                                                />
                                            </Grid>
                                        }
                                        {giftcardStore.featuredMerchants?.shop &&
                                            <Grid item xs={12}>
                                                <FeaturedMerchants
                                                    title={t('giftCards:featuredMerchants.shopping.title')}
                                                    subtitle={t('giftCards:featuredMerchants.shopping.subtitle')}
                                                    category={'department-stores'}
                                                    merchants={giftcardStore.merchantsBySlug(giftcardStore.featuredMerchants?.shop)}
                                                    onSelectMerchant={onSelectMerchant}
                                                />
                                            </Grid>
                                        }
                                        {giftcardStore.featuredMerchants?.entertainment &&
                                            <Grid item xs={12}>
                                                <FeaturedMerchants
                                                    title={t('giftCards:featuredMerchants.entertainment.title')}
                                                    subtitle={t('giftCards:featuredMerchants.entertainment.subtitle')}
                                                    category={'entertainment'}
                                                    merchants={giftcardStore.merchantsBySlug(giftcardStore.featuredMerchants?.entertainment)}
                                                    onSelectMerchant={onSelectMerchant}
                                                />
                                            </Grid>
                                        }
                                        {giftcardStore.featuredMerchants?.travel &&
                                            <Grid item xs={12}>
                                                <FeaturedMerchants
                                                    title={t('giftCards:featuredMerchants.travel.title')}
                                                    subtitle={t('giftCards:featuredMerchants.travel.subtitle')}
                                                    category={'travel'}
                                                    merchants={giftcardStore.merchantsBySlug(giftcardStore.featuredMerchants?.travel)}
                                                    onSelectMerchant={onSelectMerchant}
                                                />
                                            </Grid>
                                        }

                                    </Grid>
                                </>
                                <Box>
                                    <Grid container>
                                        <Grid container item xs={8} sx={{ marginTop: 2, marginBottom: '-4px' }}>
                                            <Box display={'flex'} flexDirection="column">
                                                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{t('giftCards:merchants.discover')}</Typography>
                                                <Typography variant="body1" sx={{ color: 'gray' }}>{t('giftCards:merchants.view')}</Typography>
                                            </Box>
                                        </Grid>
                                        <Container sx={{ marginTop: '16px', padding: '0px !important' }}>
                                            <Grid container spacing={2}>
                                                {countryMerchants.map((merchant) => {
                                                    return (
                                                        <Grid item xs={6} md={3} key={merchant.slug}>
                                                            <MerchantDisplay
                                                                backgroundColor={merchant.backgroundColor}
                                                                logoImageUrl={merchant.logoImageUrl}
                                                                name={merchant.name}
                                                                cashbackPercentage={merchant.cashbackPercentage}
                                                                onSelect={() => onSelectMerchant(merchant)}
                                                                showMerchantNameAtBottom
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Container>
                                    </Grid>
                                </Box>
                            </>
                        }
                    </>
            }
        </Container>
    );
}

export default observer(Merchants);
