import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const FullPageLoader = () => (
    <Box display="flex" alignItems={'center'} justifyContent={'center'} sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }}>
        <CircularProgress />
    </Box>
);

export default FullPageLoader;