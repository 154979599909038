import CommonStore from './commonStore';
import AuthStore from './authStore';
import IdentityStore from './identityStore';
import GiftcardStore from './giftcardStore';
import PlaidStore from './plaidStore';
import WalletStore from './walletStore';

const commonStore = new CommonStore();

const authStore = new AuthStore(commonStore);
const identityStore = new IdentityStore();
const giftcardStore = new GiftcardStore();
const plaidStore = new PlaidStore();
const walletStore = new WalletStore();

const stores = {
    authStore,
    commonStore,
    giftcardStore,
    identityStore,
    plaidStore,
    walletStore
};

export default stores;
