import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
    styled,
    Container,
} from '@mui/material';
import { useStores } from '../hooks/use-stores';
import { useNavigate, useLocation } from 'react-router-dom';
import NewSigninForm from '../components/NewSigninForm';

const Wrapper = styled(Container)(({ theme }) => ({
    color: '#575962',
    width: '100%',
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position: 'relative',
    padding: 0
}));

const SignIn = () => {
    const { authStore } = useStores();
    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect');

    const navigateToApp = () => {
        navigate(redirect || '/giftcards/shop', { replace: true });
    }

    useEffect(() => {
        authStore.setError('');

        // For non-restricted users, we don't want them to see this page, so redirect them
        // when the page loads initially. For restricted users, we want them to see this page
        // so they can log into a 'real' non-restricted account.
        if (!authStore.isRestrictedUser) {
            navigate('/giftcards/shop', { replace: true });
        }
    }, []);

    if (!authStore.isRestrictedUser) {
        return null;
    }

    return (
        <Wrapper>
            <NewSigninForm
                onSuccessfulAuth={navigateToApp}
            />
        </Wrapper>
    )
}

export default observer(SignIn);