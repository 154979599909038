import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';
import BankAccount from './models/BankAccount';
import ACHTransaction from './models/ACHTransaction';
import { LINK_TOKEN_KEY, UPDATE_FLOW_KEY } from '../constants';
import { getItem, setItem, removeItem } from '../utils/storage';

interface SuccessfulPayment {
    _id: string;
    amount: string;
    accountName: string;
    createdAt: string;
}

class PlaidStore {
    public accounts: Array<BankAccount> = [];
    public attachingAccounts: boolean = false;
    public token: string | null = null;
    public loadingToken: boolean = false;
    public loadingAccounts: boolean = true;
    public processingPayment: boolean = false;
    public linkSessionId: string = '';

    // Doing the update flow for Plaid
    public transactions: ACHTransaction[] = [];
    public successfulPayment: SuccessfulPayment | null = null;
    public removingAccount: boolean | null = null;
    public error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.accounts = [];
        this.attachingAccounts = false;
        this.token = null;
        this.loadingToken = false;
        this.loadingAccounts = true;
        this.processingPayment = false;
        this.linkSessionId = '';

        this.transactions = [];
        this.successfulPayment = null;
        this.removingAccount = null;
        this.error = null;
    }

    setError(error: Error | string | null) {
        this.error = extractErrorMessage(error);
    }

    async loadToken() {
        try {
            this.error = null;

            const res = await services.Plaid.fetch();
            runInAction(() => {
                // Store the token in the browser - we'll need to ensure its accessible later 
                setItem(LINK_TOKEN_KEY, res.linkToken, 1);
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            })
        }
    }

    async loadUpdateToken(accountId: string) {
        try {
            this.error = null;

            const res = await services.Plaid.fetchUpdateToken(accountId);
            runInAction(() => {
                // Store the token in the browser - we'll need to ensure its accessible later from the
                // reinit flow
                setItem(LINK_TOKEN_KEY, res.linkToken, 1);
                setItem(UPDATE_FLOW_KEY, true, 1);
            });
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            })
        }
    }

    async attachAccounts(publicToken: string, metadata: any) {
        try {
            this.error = null;
            this.attachingAccounts = true;

            await services.Plaid.attachAccounts(publicToken, metadata);
            await this.loadAccounts();
        } catch (e: any) {
            runInAction(() => {
                const errorCode = e.response?.data?.error_code;
                const linkId = e.response?.data?.link_id;
                if (errorCode == 'LINK_FAILED') {
                    this.linkSessionId = linkId;
                    this.error = errorCode;
                } else {
                    this.setError(e);
                }
            })
        } finally {
            runInAction(() => {
                this.attachingAccounts = false;

                // Remove the link token - we no longer need it 
                this.removeLinkToken();
            })
        }
    }

    async loadAccounts() {
        try {
            this.error = null;

            const result = await services.Plaid.Accounts().list();
            runInAction(() => {
                this.accounts = result.accounts;
            })
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            })
        }
    }

    async processPayment(accountId: string, amount: number) {
        try {
            this.error = null;

            const res = await services.Plaid.processPayment(accountId, amount);
            runInAction(() => {
                this.successfulPayment = res;
            })
        } catch (e: any) {
            runInAction(() => {
                const errorCode = e.response?.data?.error_code;

                // These error codes require special handling to inform the user
                const paymentErrorCodes = ['PLAID_UPDATE', 'ITEM_LOGIN_REQUIRED', 'account_update_error', 'identity_invalid', 'not_enough_funds', 'payment_failed', 'ach_limit_met'];
                if (paymentErrorCodes.includes(errorCode)) {
                    this.error = errorCode;
                } else {
                    this.setError(e);
                }
            })
        }
    }

    async loadTransactions(cb: () => void, status?: string,) {
        try {
            this.error = null;
            this.transactions = await services.Plaid.loadTransactions(status);
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            });
        } finally {
            runInAction(() => {
                cb();
            });
        }
    }

    async unlinkAccount(accountId: string) {
        try {
            this.error = null;
            this.removingAccount = true;

            await services.Plaid.unlinkAccount(accountId);
            await this.loadAccounts();
        } catch (e: any) {
            runInAction(() => {
                this.setError(e);
            })
        } finally {
            runInAction(() => {
                this.removingAccount = false;
            })
        }
    }

    finishedUpdateFlow() {
        removeItem(UPDATE_FLOW_KEY);
        this.removeLinkToken();
    }

    removeLinkToken() {
        removeItem(LINK_TOKEN_KEY);
    }

    // We store the token in the browser because there's no guarantee how the user is
    // redirected, so it's safest to get it from storage rather than memory
    get storedLinkToken() {
        return getItem(LINK_TOKEN_KEY);
    }

    get inUpdateMode() {
        return getItem(UPDATE_FLOW_KEY) == 'true';
    }
}

export default PlaidStore;
