import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Typography } from '@mui/material';

interface AgreementCheckboxProps {
    accepted: boolean;
    acceptedError?: boolean;
    updateAcceptance: (e: any) => void;
}

const AgreementCheckbox = (props: AgreementCheckboxProps) => {
    const { t } = useTranslation();

    const { accepted, updateAcceptance, acceptedError } = props;

    return (<FormControl
        required
        error={accepted}
        component="fieldset"
        variant="standard"
        sx={{ mb: 1, pl: 2, pr: 2 }}
    >
        <FormGroup>
            <FormControlLabel
                sx={{ mr: 0 }}
                control={
                    <Checkbox value={accepted} onClick={updateAcceptance} />
                }
                label={
                    <Typography color="common.bluegray" fontSize="14px" dangerouslySetInnerHTML={{ __html: t('global:acceptTerms') }} />
                }
            />
        </FormGroup>
        <FormHelperText sx={{ textAlign: 'center', visibility: !acceptedError ? 'hidden' : 'initial' }}>
            {t('createAccount:agreeRequirement')}
        </FormHelperText>
    </FormControl>)
};

export default AgreementCheckbox