import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
    Box,
    Button,
    Container,
    Typography,
    useTheme,
} from '@mui/material';
import AccountCreationSuccess from './AccountCreationSuccess';
import ChooseAddress from './ChooseAddress';
import ConfirmEmail from './ConfirmEmail';
import CreateWallet from './CreateWallet';
import EmailSignup from './EmailSignup';
import LandingPage from './LandingPage';
import PhoneVerification from './PhoneVerification';
import { SPARK_WALLET } from '../../constants';
import Breadcrumbs from '../../components/Breadcrumbs';
import BackButton from '../../components/BackButton';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks/use-stores';


// In order of appearance in the wizard
export enum CreateAccountPage {
    LANDING_PAGE,
    EMAIL_SIGNUP_HOME,
    CONFIRM_EMAIL,
    PHONE_VERIFICATION,
    CREATE_WALLET,
    CHOOSE_ADDRESS,
    SUCCESS
}

// The pages in the wizard
const ALL_PAGES = [
    {
        // Home Page
        id: CreateAccountPage.EMAIL_SIGNUP_HOME,
        path: '/emailSignup',
        component: EmailSignup,
    },
    {
        // Home Page
        id: CreateAccountPage.CONFIRM_EMAIL,
        path: '/confirmEmail',
        component: ConfirmEmail,
        hideBackButton: true,
        hideBreadcrumbs: true,
        showSignOutButton: true,
    },
    {
        // Phone Verification Page
        id: CreateAccountPage.PHONE_VERIFICATION,
        path: '/verifyPhone',
        component: PhoneVerification,
        hideBackButton: true,
        showSignOutButton: true,
    },
    {
        // Create Wallet Page
        id: CreateAccountPage.CREATE_WALLET,
        path: '/createWallet',
        component: CreateWallet,
        hideBackButton: true,
        showSignOutButton: true,
    },
    {
        // Choose Address Page
        id: CreateAccountPage.CHOOSE_ADDRESS,
        path: '/chooseAddress',
        component: ChooseAddress,
        hideBackButton: true,
        hideBreadcrumbs: true,
        showSignOutButton: true,
    },
    {
        // Create Account Page
        id: CreateAccountPage.SUCCESS,
        path: '/success',
        component: AccountCreationSuccess,
        hideBackButton: true,
        hideBreadcrumbs: true
    },
];


const CreateAccount = () => {
    const { t } = useTranslation();

    const { authStore } = useStores();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();

    const getCurrentPage = () => ALL_PAGES.find(page => location.pathname.includes(page.path));
    const [showSignoutButton, setShowSignoutButton] = useState<boolean>(getCurrentPage()?.showSignOutButton ?? false);
    const [hideBackButton, setHideBackButton] = useState<boolean>(getCurrentPage()?.hideBackButton ?? false);
    const [currentPage, setCurrentPage] = useState<CreateAccountPage>(getCurrentPage()?.id ?? CreateAccountPage.LANDING_PAGE);

    const signOut = () => {
        authStore.logout(() => navigate('/giftcards/shop'));
    }

    const topComponent = useMemo(() => {
        switch (currentPage) {
            case CreateAccountPage.LANDING_PAGE:
                return (
                    <Typography component="h1" variant="body1" textTransform="uppercase">
                        {SPARK_WALLET}
                    </Typography>
                );
            default:
                const currentPageInfo = ALL_PAGES.find(page => page.id == currentPage);
                if (!currentPageInfo || currentPageInfo.hideBreadcrumbs) {
                    return <div></div>;
                }

                return (
                    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                        <Box width="75%">
                            <Breadcrumbs
                                colors={{ active: theme.palette.primary.main, inactive: theme.palette.common.dimblue }}
                                // We group the create account breadcrumbs by specific pages
                                items={
                                    [[CreateAccountPage.EMAIL_SIGNUP_HOME],
                                    [CreateAccountPage.PHONE_VERIFICATION, CreateAccountPage.CREATE_WALLET, CreateAccountPage.CHOOSE_ADDRESS],
                                    [CreateAccountPage.SUCCESS]].map(pages => {
                                        return {
                                            current: pages.some(p => currentPage >= p),
                                        }
                                    })
                                }
                            />
                        </Box>
                    </Box>
                )
        }
    }, [currentPage, authStore.inProgress])

    // Needed for the bar to be accurate on refresh
    useEffect(() => {
        const currentPage = getCurrentPage();

        setCurrentPage(currentPage?.id ?? CreateAccountPage.LANDING_PAGE);
        setHideBackButton(Boolean(currentPage?.hideBackButton));
        setShowSignoutButton(Boolean(currentPage?.showSignOutButton));
    }, [location.pathname]);

    return (
        <Container sx={{ pl: 3, pr: 3, height: '100%', position: 'relative' }} maxWidth="sm" disableGutters>
            <Box width="100%" display="flex" justifyContent="flex-end" mt={1} visibility={(!!authStore.currentSparkUser && showSignoutButton) ? 'visible' : 'hidden'}>
                <Button onClick={signOut} disabled={authStore.inProgress}>
                    <Typography fontSize={'13px'} fontWeight="bold" textTransform="none">{t('profile:signOut')}</Typography>
                </Button>
            </Box>
            <BackButton hideBackButton={hideBackButton} topComponent={topComponent} />
            <Box sx={{ position: 'relative', height: 'calc(100vh - 114px)' }}>
                <Routes>
                    {ALL_PAGES.map((page) => <Route
                        key={page.id}
                        path={page.path}
                        element={<page.component />}
                    />
                    )}
                    <Route
                        path={'*'}
                        element={<LandingPage />}
                    />
                </Routes>
            </Box>
        </Container>
    )
}

export default observer(CreateAccount);