import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Button, Container, Divider, styled, TextField, Typography } from '@mui/material';
import { useStores } from '../../../../hooks/use-stores';
import { useTranslation } from 'react-i18next';
import { SPARK_WALLET_ME } from '../../../../constants';

const Heading = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold'
}));

const WarningHeading = styled(Heading)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold'
}));

const SectionWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginBottom: '20px'
}));

const FormWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}));

const InfoText = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    color: theme.palette.common.midgrey,
    marginTop: '4px'
}));

const UserProfile = () => {
    const { t } = useTranslation();
    const { authStore } = useStores();

    return (
        <Container sx={{ maxWidth: { xs: '600px' } }}>
            <Typography fontSize="30px" fontWeight="bold">{t('profile:profile.yourAccount')}</Typography>
            <Typography sx={{ fontSize: '13px', color: 'common.goldenrod' }}>{t('profile:infoChangeWarning')}</Typography>
            {false && <SectionWrapper>
                <Heading>{t('profile:profile.nameAndContact')}</Heading>
                <FormWrapper>
                    <TextField
                        value={authStore.currentSparkUser?.firstName}
                    />
                    <TextField
                        value={authStore.currentSparkUser?.lastName}
                    />
                </FormWrapper>
            </SectionWrapper>
            }
            <SectionWrapper>
                <Heading mt={2}>{t('profile:profile.lightningAddress')}</Heading>
                <FormWrapper>
                    <TextField
                        disabled
                        value={`${SPARK_WALLET_ME}/${authStore.currentSparkUser?.username}`}
                    />
                </FormWrapper>
                <InfoText>{t('profile:profile.lightningAddressInfo')}</InfoText>
            </SectionWrapper>
            <Divider sx={{ margin: '34px 0px' }} />
            {false && <SectionWrapper>
                <WarningHeading>{t('profile:profile.deleteAccount')}</WarningHeading>
                <Button sx={{ width: '100%', background: 'dimgray', color: 'white' }}>{t('profile:profile.deleteAccount')}</Button>
                <InfoText>{t('profile:profile.deleteAccountInfo')}</InfoText>
            </SectionWrapper>
            }
        </Container>
    )
}

export default observer(UserProfile);