import PaymentMethod from "./stores/models/PaymentMethod";

export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const LINK_TOKEN_KEY = 'link_token';
export const UPDATE_FLOW_KEY = 'update_flow';

export const PASSWORD_MIN_LENGTH = 8;

export const ACH_WEEKLY_LIMIT = 250;
export const SPARK_CASH = 'Spark Cash';
export const SPARK_WALLET = 'Spark Wallet';
export const SPARK_WALLET_ME = 'sparkwallet.me';

export const GIFT_CARD_MINIMUM_VALUE = 5;
export const GIFT_CARD_MAXIMUM_VALUE = 1500; // A hard maximum if none is supplied by the backend


/* Payment Methods */
export const SPARK_CASH_PAYMENT_METHOD = (sparkCashBalance: number): PaymentMethod => ({
    logo: `${process.env.PUBLIC_URL}/img/sparkcirclelogo.png`,
    name: SPARK_CASH,
    rank: 0,
    balance: String(sparkCashBalance)
})

export const SPARK_WALLET_PAYMENT_METHOD: PaymentMethod = {
    logo: `${process.env.PUBLIC_URL}/img/sparklogo.png`,
    name: SPARK_WALLET,
    rank: 1,
    openAppPrefix: 'sparkwallet:lightning:'
}

/*
*/
export const BLUE_GRADIENT = `91.38deg, #70A2FF 0%, #54F0D1 104.21%`;
