import Username from './UsernameService';
import Password from './PasswordService';
import IdLessRestService from './IdLessRestService';

export default class UserProfile extends IdLessRestService {
    constructor() {
        super('/api/v1/user/profile');
    }

    update(entity: any) {
        return this.put(`${this.baseUrl}/`, entity);
    }

    createUsername(newUsername: string, referralCode?: string) {
        return this.put(`${this.baseUrl}/username`, {
            newUsername,
            referralCode
        });
    }

    PasswordService() {
        return new Password(this);
    }

    UsernameService() {
        return new Username(this);
    }
}
