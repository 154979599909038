import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, } from '@mui/material';
import IdentityVerifyHome from './IdentityVerifyHome';
import AddressInput from './AddressInput';
import EnterDOB from './EnterDOB';

const IdentityVerification = () => {
    const [step, setStep] = useState<number>(0);

    const currentStepComponent = useMemo(() => {
        if (step == 1) {
            return <EnterDOB onNext={() => setStep(2)} />
        } else if (step == 2) {
            return <AddressInput />
        } else {
            return <IdentityVerifyHome onNext={() => setStep(1)} />
        }
    }, [step]);

    return (
        <Container sx={{ padding: 0, width: '100%', height: '100%', maxWidth: { xs: '650px', sm: '1100px' } }}>
            {currentStepComponent}
        </Container>
    )
}

export default observer(IdentityVerification);