import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLottie } from 'lottie-react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Alert, Box } from '@mui/material';
import { CreateAccountPage } from '.';
import { useStores } from '../../hooks/use-stores';
import { BottomContainer, MainContainer } from '../../components/TopBottomLayout';
import SparkButton from '../../components/SparkButton';
import HeaderText from './components/HeaderText';
import successAnimation from '../../lottie/success.json';
import AgreementCheckbox from '../../components/AgreementCheckbox';

const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const CreateWallet = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { authStore, walletStore } = useStores();
    const { View } = useLottie(defaultOptions);

    const [loading, setLoading] = useState<boolean>(false);
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [termsAcceptedError, setTermsAcceptedError] = useState<boolean>(false);

    const updateTermsAcceptance = useCallback(() => {
        if (termsAcceptedError) {
            setTermsAcceptedError(false);
        }

        setTermsAccepted(!termsAccepted)
    }, [termsAccepted, termsAcceptedError])

    const createWallet = async () => {
        setLoading(true);
        await walletStore.createWallet();
        setLoading(false);
    }

    const advanceToNextAccountStep = () => {
        if (!walletStore.error) {
            authStore.finishCreateAccountStep(CreateAccountPage.CREATE_WALLET);
        }
    }

    const errorMessage = useMemo(() => {
        if (walletStore.error) {
            return t('createAccount:createWallet.failure');
        }
        return ''
    }, [walletStore.error]);

    // Check if user can be here - abstract to HOC 
    useEffect(() => {
        if (!authStore.canCreateWallet) {
            navigate('/home')
        }
    }, [])

    if (!authStore.canCreateWallet) {
        return null;
    }

    return (
        <MainContainer minHeight="550px">
            {errorMessage && <Box mb={2}>
                <Alert severity="error">{errorMessage}</Alert>
            </Box>
            }
            {
                walletStore.createdWallet && !walletStore.error
                    ?
                    <>
                        <Box>
                            {View}
                            <HeaderText
                                title={t('createAccount:createWallet.congratulations')}
                                description={t('createAccount:createWallet.setupCompleted')}
                            />
                        </Box>
                        <BottomContainer>
                            <SparkButton onClick={advanceToNextAccountStep}>
                                {t('global:continue')}
                            </SparkButton>
                        </BottomContainer>

                    </>
                    :
                    <>
                        <HeaderText
                            title={t('createAccount:createWallet.title')}
                            description={t('createAccount:createWallet.description')}
                        />
                        <Box display="flex" alignItems="center" justifyContent="center" mt={6}>
                            <img width="50%" src={`${process.env.PUBLIC_URL}/wallet.png`} />
                        </Box>
                        <BottomContainer>
                            <AgreementCheckbox
                                accepted={termsAccepted}
                                acceptedError={termsAcceptedError}
                                updateAcceptance={updateTermsAcceptance}
                            />
                            <SparkButton onClick={createWallet} disabled={!termsAccepted || loading} loading={loading}>
                                {t('global:continue')}
                            </SparkButton>
                        </BottomContainer>
                    </>
            }
        </MainContainer>
    )

}
export default observer(CreateWallet);