import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Container, Grid, Typography } from '@mui/material';
import MerchantDisplay from '../shared/MerchantDisplay';
import { useStores } from '../../../hooks/use-stores';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/helpers';
import { Merchant } from '../../../stores/models/Merchant';
import BackToMerchantsButton from '../components/BackToMerchantsButton';

const Category = () => {
    const navigate = useNavigate();
    const { giftcardStore } = useStores();

    const goHome = () => {
        navigate('/giftcards/shop');
    }

    const onSelectMerchant = (merchant: Merchant) => {
        giftcardStore.selectMerchant(merchant);
        navigate('/giftcards/review');
    }

    // Ensure the user has selected a category
    if (!giftcardStore.category || giftcardStore.merchantsOfSelectedCategory?.length == 0) {
        goHome();
        return null;
    }

    return (
        <Container sx={{ padding: 1 }}>
            <BackToMerchantsButton />
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                {/* Capitalize all the letters so that we get a consistent category title */}
                {capitalizeFirstLetterOfEachWord(giftcardStore.category.replaceAll('-', ' '))}
            </Typography>
            <Grid container spacing={2}>
                {
                    giftcardStore.merchantsOfSelectedCategory.map(merchant =>
                        <Grid key={merchant.id} item xs={6} md={3}>
                            <MerchantDisplay
                                largeImage
                                backgroundColor={merchant.backgroundColor}
                                logoImageUrl={merchant.logoImageUrl}
                                name={merchant.name}
                                cashbackPercentage={merchant.cashbackPercentage}
                                onSelect={() => onSelectMerchant(merchant)}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </Container>
    )
}

export default observer(Category);