
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Container, } from '@mui/material';
import ProfileHome from './pages/ProfileHome';
import UserProfile from './pages/UserProfile';
import PrivacyAndSecurity from './pages/PrivacyAndSecurity';
import FundYourWallet from './pages/FundYourWallet';
import LinkedBanks from './pages/LinkedBanks';
import Transactions from './pages/Transactions';
import LinkedBanksRedirect from './pages/LinkedBanksRedirect';

const Profile = () => {
    return (
        <Container sx={{ padding: 0, height: '100%', maxWidth: { xs: '650px', sm: '1100px' } }}>
            <Routes>
                <Route
                    path="/banks"
                    element={<LinkedBanks />}
                />
                <Route path="/banks/linking"
                    element={<LinkedBanksRedirect />}
                />
                <Route
                    path="/fund"
                    element={<FundYourWallet />}
                />
                <Route
                    path="/profile"
                    element={<UserProfile />}
                />
                <Route
                    path="/privacy"
                    element={<PrivacyAndSecurity />}
                />
                <Route
                    path="/transactions"
                    element={<Transactions />}
                />
                <Route
                    path="*"
                    element={<ProfileHome />}
                />
            </Routes>
        </Container>
    )
}

export default observer(Profile);