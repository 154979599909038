import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Close, HelpOutline } from '@mui/icons-material';
import { Alert, Box, Dialog, DialogContent, Divider, FormControlLabel, FormGroup, FormHelperText, IconButton, InputAdornment, Link, Toolbar, Typography } from '@mui/material';
import { useStores } from '../../hooks/use-stores';
import { CreateAccountPage } from '.';
import { BottomContainer, MainContainer } from '../../components/TopBottomLayout';
import HeaderText from './components/HeaderText';
import { SPARK_WALLET_ME } from '../../constants';
import SparkButton from '../../components/SparkButton';
import RoundTextField from '../../components/RoundTextField';

const ChooseAddress = () => {
    const { t } = useTranslation();
    const { authStore, walletStore } = useStores();
    const navigate = useNavigate();

    const [showLightningAddressModal, setShowLightningAddressModal] = useState<boolean>(false);
    const [username, setUsername] = useState<string>('');
    const [referralCode, setReferralCode] = useState<string>('');
    const [usernameError, setUsernameError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const errorMessage = useMemo(() => {
        if (walletStore.error) {
            return t('createAccount:chooseAddress.failure');
        }
        return ''
    }, [walletStore.error]);

    const toggleLightningAddressModal = () => {
        setShowLightningAddressModal(!showLightningAddressModal)
    }

    const onChangeUsername = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (usernameError) {
            setUsernameError(false);
        }

        setUsername(event.target.value.trim());
    }, [username, usernameError])


    const submit = async () => {
        if (!username.trim().length) {
            setUsernameError(true);
            return;
        }

        setLoading(true);

        await walletStore.createUsername(username, referralCode);

        if (!walletStore.error) {
            authStore.finishCreateAccountStep(CreateAccountPage.CHOOSE_ADDRESS);
            navigate('/createaccount/success');
            ;
        }

        setLoading(false);
    }

    // Check if user can be here - abstract to HOC for create account page
    useEffect(() => {
        if (!authStore.canChooseAddress) {
            navigate('/home')
        }
    }, [])

    if (!authStore.canChooseAddress) {
        return null;
    }

    return (
        <MainContainer gap={1}>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <HeaderText
                title={t('createAccount:chooseAddress.title')}
                description={t('createAccount:chooseAddress.description')}
            />
            <FormGroup sx={{ width: '100%' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={-.5}>
                    <FormHelperText
                        component={Typography}
                        sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '13px'
                        }}
                    >
                        {t('createAccount:chooseAddress.chooseName')}
                    </FormHelperText>
                    <IconButton color="inherit" onClick={toggleLightningAddressModal}>
                        <HelpOutline fontSize="medium" />
                    </IconButton>
                </Box>
                <FormControlLabel
                    sx={{ mr: 0 }}
                    label={null}
                    control={
                        <RoundTextField
                            placeholder={t('createAccount:chooseAddress.addressPlaceholder')}
                            sx={{ width: '95%', margin: '0 auto', mt: .5 }}
                            value={username}
                            onChange={onChangeUsername}
                            margin="normal"
                            required
                            fullWidth
                            autoComplete="none"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                    >
                                        {`@${SPARK_WALLET_ME}`}
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={
                                {
                                    maxLength: 20
                                }
                            }
                            error={usernameError}
                        />
                    }
                />
            </FormGroup>
            <Typography color={'common.goldenrod'} variant="body2">
                {t('createAccount:chooseAddress.addressCantChange')}
            </Typography>
            <Box display="flex" flexDirection="column" mt={2} width="100%">
                <Typography variant="caption" letterSpacing={.5} textAlign="center">{t('createAccount:chooseAddress.payPage')}</Typography>
                <Typography
                    component="span"
                    variant="body1"
                    textAlign="center"
                    fontWeight="bold"
                    color="primary"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        width: '95%',
                        margin: '0 auto'
                    }}
                >https://{SPARK_WALLET_ME}/{username}</Typography>
            </Box>
            <BottomContainer>
                <SparkButton onClick={submit} loading={loading} disabled={loading || !username.length}>
                    <Typography fontWeight="bold">
                        {t('createAccount:chooseAddress.finishSetup')}
                    </Typography>
                </SparkButton>
            </BottomContainer>
            <Dialog
                open={showLightningAddressModal}
                onClose={toggleLightningAddressModal}
                sx={{ maxHeight: 'calc(100% - 75px)' }}
                PaperProps={{
                    sx: { borderRadius: '14px', paddingBottom: '10px', width: '100%', background: 'common.darkgrey', height: 'min-content ' }
                }}
            >
                <Toolbar sx={{ paddingTop: 2, paddingBottom: 2, justifyContent: 'flex-end' }}>
                    <IconButton
                        sx={{ marginLeft: 1 }}
                        color="inherit"
                        onClick={toggleLightningAddressModal}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent sx={{ pt: 0 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" gap={3}>
                        <Typography sx={{ ml: 0, flex: 1, fontWeight: 'bold' }} fontSize={"16px"} component="div">
                            {t('createAccount:chooseAddress.whatIsLightning')}
                        </Typography>
                        <Typography sx={{ ml: 0, flex: 1 }} fontSize={"16px"} component="div">
                            {t('createAccount:chooseAddress.lightningDescription')}
                        </Typography>
                        <Typography sx={{ ml: 0, flex: 1 }} fontSize={"16px"} component="div">
                            {t('createAccount:chooseAddress.notice')}
                        </Typography>
                        <Divider sx={{ color: 'white', width: '100%', margin: '1px 0' }} />
                        <Link className="sparkLink" color="primary" target="_blank" rel="noopener" href="https://lightningaddress.com">{t('createAccount:chooseAddress.learnMore')}</Link>
                    </Box>
                </DialogContent>
            </Dialog>
        </MainContainer >
    )

}
export default observer(ChooseAddress);