
import RestService from './RestService';
import IdLessRestService from './IdLessRestService';

// Cleanup TODO - absorb UserProfile service
export default class UserService extends IdLessRestService {
    constructor() {
        super('/api/v1/user');
    }

    async generateRefLink() {
        return this.post(`${this.baseUrl}/share/generateReferralLink`);
    }

    async verifyEmail(email: string) {
        return this.post(`${this.baseUrl}/verifyEmail`, { email });
    }
}
