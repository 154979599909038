import React, { useMemo } from 'react';
import { Circle } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';

interface BreadcrumbTheme {
    active: string;
    inactive: string;
}

interface BreadcrumbSharedProps {
    colors: BreadcrumbTheme;
    current?: boolean;
    usingDefaultCircle?: boolean;
}

const Breadcrumb = styled(Box, {
    shouldForwardProp: (prop) => prop !== "colors" && prop !== "current" && prop !== 'usingDefaultCircle'
})<BreadcrumbSharedProps>(({ theme, current, colors }) => ({
    color: current ? colors.active : colors.inactive,
    padding: '0 4px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    userSelect: 'none',
    zIndex: 2,
}));

const BreadcrumbBit = styled('div', {
    shouldForwardProp: (prop) => prop !== "colors" && prop !== "current" && prop !== 'usingDefaultCircle'
})<BreadcrumbSharedProps>(({ theme, current, colors, usingDefaultCircle }) => ({
    width: '100%',
    border: `1px solid ${theme.palette.common.midgrey}`,
    backgroundColor: current ? colors.active : colors.inactive,
    borderColor: current ? colors.active : colors.inactive,
    height: '1px',
    margin: usingDefaultCircle ? '0 -.5rem' : '0 .5rem',
    display: 'flex',
    alignSelf: 'center',
    zIndex: 1,
}));

const BreadcrumbContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    color: theme.palette.common.midgrey,
    width: '100%',
    alignItems: 'center',
}));

interface BreadcrumbItemProps {
    colors: BreadcrumbTheme;
    current: boolean;
    top?: string;
    bottom?: string;
    hideBottom?: boolean;
}

const BreadcrumbItem = (props: BreadcrumbItemProps) =>
    <Breadcrumb current={props.current} colors={props.colors}>
        {props.top ? <Typography fontSize="14px">{props.top}</Typography> : <Circle sx={{ fontSize: '14px' }} />}
        {!props.hideBottom && <Typography fontWeight={'bold'} fontSize="12px" whiteSpace="nowrap">{props.bottom}</Typography>}
    </Breadcrumb>


interface BreadcrumbItemType {
    current: boolean;
    top?: string;
    bottom?: string;
    hideBottom?: boolean;
}

interface BreadcrumbsProps {
    items: Array<BreadcrumbItemType>;
    hideBottom?: boolean;
    colors?: BreadcrumbTheme;
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
    const DEFAULT_ACTIVE_COLOR = 'white';
    const DEFAULT_INACTIVE_COLOR = 'gray';

    const colors = useMemo(() => {
        return {
            active: props.colors?.active || DEFAULT_ACTIVE_COLOR,
            inactive: props.colors?.inactive || DEFAULT_INACTIVE_COLOR
        }
    }, [props.colors])

    return (
        <BreadcrumbContainer>
            {props.items.map((item: BreadcrumbItemType, index: number) => (
                <React.Fragment key={index}>
                    <BreadcrumbItem
                        colors={colors}
                        top={item.top}
                        bottom={item.bottom}
                        current={item.current}
                        hideBottom={item.hideBottom || props.hideBottom}
                    />
                    {props.items.length !== (index + 1) &&
                        <BreadcrumbBit
                            colors={colors}
                            current={props.items[index + 1].current}
                            // If the top text is not provided, then we're using the default circle, so ensure the
                            // margin is gon
                            usingDefaultCircle={!item.top}
                        />
                    }
                </React.Fragment>
            ))}
        </BreadcrumbContainer>
    )
}

export default Breadcrumbs;