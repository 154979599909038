import React, { useCallback, useEffect } from 'react';
import { PlaidLinkOnEvent, PlaidLinkOnEventMetadata, PlaidLinkOnExit, PlaidLinkOnSuccess, PlaidLinkStableEvent, usePlaidLink } from 'react-plaid-link';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../../../hooks/use-stores';

/*
There are 3 different flows that happen with plaid

1) Linking
2) Re-init
3) Update

Linking Flow:
The Plaid flow is easy to follow. On the Banks page, we call plaidStore.loadToken,
which returns a 'link_token' from the backend via a protected API.

This is a short-lived token associated with a user's id. Once this token exists and is saved
into a cookie, this component is mounted and ready to be used. The link_token is exchanged
with Plaid and, once verified, the Plaid component is ready to be used and is considered 'ready'. See
'usePlaidLink' below. Ready means we can open the Plaid widget and authenticate the user's bank
details.

If the user is doing a non-oauth bank authentication, then onSuccess is called once their 
information is verified. the onSuccess callback gives us a publicToken and some metadata. We call the backend
with this information using the attachAccounts API. We create the user's bank account information and
any payment processor info they might need.

If the user is doing an OAuth bank authentication, there is a redirect involved. The onSuccess 
callback is not immediately invoked. Instead, the user is redirected to the Redirect URI defined
on the server, which is 

*/
const PlaidLink = ({
    linkToken,
    reinit,
    forceOpen,
}: {
    linkToken: string | null;
    reinit?: boolean; // If we're in the re-init flow
    forceOpen?: boolean; // To connect an account the user should press the Add button. this forces Plaid to open
}) => {
    const navigate = useNavigate();
    const { plaidStore } = useStores();

    const onSuccess = useCallback<PlaidLinkOnSuccess>(async (publicToken, metadata) => {
        if (plaidStore.inUpdateMode) {
            plaidStore.finishedUpdateFlow();

            // Take the user back to the fund page and show them their account was successfully reconnected
            navigate('/giftcards/profile/fund?reconnectedAccount=true')
        } else {
            /* 
                We're in the normal Plaid flow (not the update flow). This is where we connect an account
                for the first time.

                Here we have successfully authenticated with the Plaid Frontend, we receive a Public Token
                Send the Public Token to our backend, where we will exchange this token with Plaid for 
                2 long lived tokens, an Access and Processor Token used to
                authenticate future API calls for processing payments. We store these on the User
                account in Mongo.

                We will also be here if we're in the 'Auth' flow - which is a confusing name for
                a non-OAuth bank. It skips the redirect and goes straight to giving you a public token
            */
            await plaidStore.attachAccounts(publicToken, metadata);
            if (!plaidStore.error) {
                // Direct the user to the funding page once they connect their bank account
                navigate('/giftcards/profile/fund?connected=true')
            } else {
                navigate(`/giftcards/profile/fund?connected=false`)
            }
        }
    }, [plaidStore.inUpdateMode]);

    // TODO (if possible): Handle link token timeouts
    // This may not be possible in React due to 
    // https://plaid.com/docs/link/handle-invalid-link-token/
    const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
        // If we exit the update flow, 'refresh' the page so we're no longer in it
        if (plaidStore.inUpdateMode) {
            window.location.href = '/giftcards/profile/banks';
        } else {
            navigate('/giftcards/profile/banks?reset=true')
        }
    }, []);

    const onEvent = useCallback<PlaidLinkOnEvent>(
        (
            eventName: PlaidLinkStableEvent | string,
            metadata: PlaidLinkOnEventMetadata,
        ) => {
            console.log(eventName)
            console.log(metadata);
        },
        [],
    );

    const { open, ready, error, exit } = usePlaidLink({
        token: linkToken,
        onSuccess,
        onExit,
        onEvent,
        // During re-init, we need to pass the redirect URI with an OAuth state ID parameter,
        // which should be our current url.
        receivedRedirectUri: reinit ? window.location.href : undefined
    });

    useEffect(() => {
        // We can't open anything util we're ready
        if (ready && (reinit || forceOpen)) {
            open();
        }
    }, [ready, open, forceOpen, reinit]);

    // If there is no link token, we can't do anything
    useEffect(() => {
        if (!linkToken) {
            navigate('/giftcards/profile/banks');
        }
    }, []);

    if (!linkToken) {
        return null;
    }

    // We must show something so the open function can run, so return an empty fragment
    return <></>;
};

export default observer(PlaidLink);