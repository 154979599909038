import stores from '../stores';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { v4 as uuid } from 'uuid';
import { getItem, setItem } from '../utils/storage';

const appVersion = require('../../package.json').version;

const API_ROOT = process.env.REACT_APP_API_URL;
const appId = uuid();
const SHOPPING_BOSS_VERSION = '5.4';

const COMMON_HEADERS = {
    'X-App-Request-Id': `${appId}`,
    'X-App-Version': `${appVersion}`,
    'X-Version': SHOPPING_BOSS_VERSION,
} as AxiosRequestHeaders;

// The base Axios instance we'll use to configure our Service
const axiosInstance = axios.create({});

// TODO reconcile this with the auth store/auth service version
// Refresh the user's token automatically via the Axios interceptor
// Added to the base axiosInstance so all the service's functions utilize this
// custom functionality
axiosInstance.interceptors.response.use(response => response, error => {
    const { response, config } = error

    if (response.status !== 401) {
        return Promise.reject(error)
    }

    // Create a new axios instance so we don't get caught in an infinite loop
    return axios.post(API_ROOT + '/api/v1/session/refresh', {}, {
        headers: {
            'Authorization': `Bearer ${getItem('access_token')}`,
            'X-Authorization': `Bearer ${getItem('refresh_token')}`,
        }
    })
        .then((result) => {
            // Access token comes from the backend, but refresh token is here in the browser
            // Guaranteed not to be null if we're successful
            const accessToken = result.data.access_token;
            const refreshToken = getItem('refresh_token');

            stores.authStore.setToken(accessToken, refreshToken!);
            setItem('access_token', accessToken, 30);

            // Ensure the axios config has the new token
            config.headers['Authorization'] = `Bearer ${accessToken}`;
            config.headers['X-Authorization'] = `Bearer ${refreshToken}`;

            return axiosInstance(config);
        })
        .catch(() => {
            return Promise.reject(error)
        })
})

class BaseService {
    handleErrors(err: any) {
        if (err && err.response && err.response.status === 401) {
            stores.authStore.logout();
        }
        return err;
    }

    baseUrl: string;
    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    commonHeaders() {
        const headers = { ...COMMON_HEADERS }
        if (stores.authStore.token && stores.authStore.token.accessToken)
            headers[
                'Authorization'
            ] = `Bearer ${stores.authStore.token.accessToken}`;
        return { headers };
    }

    async del(url: string, body?: any) {
        try {
            const response = await axiosInstance.delete(url, {
                baseURL: API_ROOT,
                ...this.commonHeaders(),
                data: body,
            });
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }

    async get(url: string, params?: any) {
        try {
            const response = await axiosInstance.get(url, {
                params,
                baseURL: API_ROOT,
                ...this.commonHeaders(),
            });
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }

    async put(url: string, body: any, options?: AxiosRequestConfig) {
        try {
            const config = { ...this.commonHeaders(), ...options };
            if (!url.startsWith('http://') && !url.startsWith('https://'))
                config.baseURL = API_ROOT;
            const response = await axiosInstance.put(url, body, config);
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }
    async patch(url: string, body: any, options?: AxiosRequestConfig) {
        try {
            const config = { ...this.commonHeaders(), ...options };
            if (!url.startsWith('http://') && !url.startsWith('https://'))
                config.baseURL = API_ROOT;
            const response = await axiosInstance.patch(url, body, config);
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }

    async post(url: string, body?: any, options?: AxiosRequestConfig) {
        try {
            const config = {
                baseURL: API_ROOT,
                ...this.commonHeaders(),
                ...options,
            };
            const response = await axiosInstance.post(url, body, config);
            return response.data;
        } catch (e) {
            const notHandled = this.handleErrors(e);
            if (notHandled) throw notHandled;
        }
    }
}

export default BaseService;
