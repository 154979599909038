import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import QRCode from 'easyqrcodejs';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Alert, Backdrop, Box, Button, CircularProgress, Dialog, Input, Typography } from '@mui/material';
import { DEFAULT_QR_OPTIONS } from '..';
import { createAsyncPaymentListener } from './createAsyncPaymentListener';
import PurchasedCard from '../../../../stores/models/PurchasedCard';
import { useStores } from '../../../../hooks/use-stores';

const Wrapper = styled(Box)(({ theme }) => ({
    width: '100%',
}));

const InvoiceInput = styled(Input)`
    width: 100%;
    padding: 8px;
    cursor: pointer;
    border: 1px solid #222222;
    border-radius: 10px;
    text-align: left;
    letter-spacing: 0;
    font-family: monospace;
    font-size: 16px;
    line-height: 1.15;
    color: white;
    transition: opacity .5s ease 0s;
`

const _QrWrapper = React.forwardRef(({ className, width, children }: any, ref?: React.Ref<HTMLDivElement>) => (
    <div className={className} ref={ref}>
        {children}
    </div>
));

const QrWrapper = styled(_QrWrapper)(({ theme }) => ({
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    '& > canvas': {
        margin: '0 auto',
        marginTop: 10
    }
}));

const ActionButton = styled(LoadingButton)(({ theme }) => ({
    width: '100%',
    color: 'white',
    backgroundColor: '#393f3c',
    borderRadius: '14px',
    textTransform: 'none'
}))

const ModalWrapper = styled(Box)(({ theme }) => ({
    zIndex: 1000000,
    margin: '0 auto',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
}));

const ModalBody = styled(Box)(({ theme }) => ({
    maxWidth: '500px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '20px',
    borderRadius: '10px'
}));

const LoadingBackdrop = ({ loading }: { loading: boolean }) =>
    <Backdrop open={loading} sx={{ zIndex: 2000000000, }}>
        <CircularProgress />
    </Backdrop>

interface LightningInvoiceCopyProps {
    copyInvoiceId: () => void;
    invoice: string;
}
const LightningInvoiceCopy = (props: LightningInvoiceCopyProps) => {
    const { t } = useTranslation();
    return (
        <Box width="100%">
            <Typography variant="body2" sx={{ fontSize: '16px', marginBottom: '2px', }}>{t('giftCards:finalize.copyInvoice')}</Typography>
            <Box pl={2} pr={2} onClick={props.copyInvoiceId} sx={{ cursor: 'pointer' }}>
                <InvoiceInput disableUnderline readOnly value={props.invoice} sx={{ width: '100%' }} inputProps={{ sx: { cursor: 'pointer', backgroundColor: '#393f3c', padding: '4px' } }} />
            </Box>
        </Box>
    )
}

const Lightning = () => {
    const { t } = useTranslation();
    const { commonStore, authStore, giftcardStore, } = useStores();

    // Cap refreshes of invoice at 3
    const refreshTries = useRef(3);
    const manuallyReloadInvoice = useRef(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState('');

    const [invoice, setInvoice] = useState<string>('');
    const [invoiceId, setInvoiceId] = useState<string>('');
    const [manuallyLoadingInvoice, setManuallyLoadingInvoice] = useState<boolean>(false);
    const [paymentPending, setPaymentPending] = useState<boolean>(false);
    const [qrModalOpen, setQrModalOpen] = React.useState(false);
    const [copyModalOpen, setCopyModalOpen] = React.useState(false);

    let unsub: (() => void) | null = null;
    let lightningRefreshTimer: ReturnType<typeof setTimeout> | null = null;

    const navigate = useNavigate();

    const handleQrModalOpen = useCallback(() => setQrModalOpen(true), [qrModalOpen]);
    const handleQrModalClose = useCallback(() => setQrModalOpen(false), [qrModalOpen]);

    const handleCopyModalOpen = useCallback(() => setCopyModalOpen(true), [copyModalOpen]);
    const handleCopyModalClose = useCallback(() => setCopyModalOpen(false), [copyModalOpen]);

    const openWalletApp = useCallback(() => {
        if (giftcardStore.selectedPaymentMethod.openAppPrefix) {
            window.location.href = `${giftcardStore.selectedPaymentMethod.openAppPrefix}${invoice}`
            setTimeout(() => {
                // If the app doesn't have focus, then the app has not opened
                if (document.hasFocus()) {
                    commonStore.warn(t("giftCards:finalize.walletOpenError"))
                }
            }, 2500);
        } else {
            commonStore.warn(t("giftCards:finalize.walletOpenError"))
        }

    }, [giftcardStore.selectedPaymentMethod, invoice]);

    const initLightning = useCallback(async () => {
        try {
            await giftcardStore.lightningCheckoutStart();
            const asyncCheckoutInfo = giftcardStore.asyncCheckoutInfo?.paymentInfo[0];

            if (!asyncCheckoutInfo) {
                setError(t('global:invoiceFetchError'));
                return;
            }

            if (asyncCheckoutInfo?.invoice) {
                // Create QRCode Object to scan (and to allow user to copy on mobile)
                // If there's no invoice, then the user doesn't have to do anything
                // because their balance is covered or it was free
                setInvoice(asyncCheckoutInfo.invoice);
            }

            // Set lightning invoice id - this will stay the same always, so theres no need to udpate it
            // There is always an invoice id so we know what to look for in firebase
            setInvoiceId(asyncCheckoutInfo.invoiceId);

            // Start listening on collection
            startFirebaseListener(asyncCheckoutInfo.invoiceId);

            /*
              Start the refresh timer for the invoice

              We need this if the user is paying with a qr code or via a wallet
              If their balance is covered or zero, we won't get an invoice and the 
              payment will go through automatically without any user interaction. We'll 
              only get a invoice id, so we start the firebase listener to watch for the giftcard
              to be added to the collection. Without the invoice, there's no need to refresh it.
            */
            if (asyncCheckoutInfo?.invoice) {
                startInvoiceRefreshTimer(asyncCheckoutInfo.expiration);
            }
        } catch (e) {
            setError(t('giftCards:finalize.invoiceFetchError'));
        } finally {
            setLoading(false);
        }
    }, []);

    // Manually refresh the invoice
    const refreshInvoice = async () => {
        if (paymentPending)
            return;

        // After 3 tries, we want the user to manually update the invoice
        if (manuallyReloadInvoice.current) {
            setManuallyLoadingInvoice(true);
            manuallyReloadInvoice.current = false;
            // set the refresh tries to 1 so the user will always need to press the button after expiration
            refreshTries.current = 1;

            await giftcardStore.lightningCheckoutRefresh();
            const asyncCheckoutInfo = giftcardStore.asyncCheckoutInfo?.paymentInfo[0];

            if (!asyncCheckoutInfo?.invoice) {
                setError(t('giftCards:finalize.invoiceRefreshError'));
                return;
            }
            // Refresh QRCode Object
            setInvoice(asyncCheckoutInfo.invoice);
            startInvoiceRefreshTimer(asyncCheckoutInfo.expiration);
            setManuallyLoadingInvoice(false);
        }
    }

    const startInvoiceRefreshTimer = (expireTime: Date) => {
        // start interval for refreshing the qr code
        const expiration = moment(expireTime);
        const now = moment();
        const expirationTimer = expiration.diff(now);

        if (refreshTries.current-- > 0) {
            lightningRefreshTimer = setTimeout(async () => {
                // Only refresh the QR code if payment is not pending
                if (!paymentPending) {
                    await giftcardStore.lightningCheckoutRefresh();
                    const asyncCheckoutInfo = giftcardStore.asyncCheckoutInfo?.paymentInfo[0];

                    if (!asyncCheckoutInfo?.invoice) {
                        setError(t('giftCards:finalize.invoiceRefreshError'));
                        return;
                    }
                    // Create QRCode Object
                    setInvoice(asyncCheckoutInfo.invoice);

                    // Start refresh timer
                    startInvoiceRefreshTimer(asyncCheckoutInfo.expiration);
                }
            }, expirationTimer);
        } else {
            manuallyReloadInvoice.current = true;
        }
    }

    const onPaymentSuccess = useCallback((purchasedCard: PurchasedCard) => {
        // giftcard was paid successfully
        setPaymentPending(false);
        giftcardStore.purchasedCardInfo = purchasedCard;
        navigate('/giftcards/confirmation');
    }, []);

    const onPaymentPending = useCallback(() => {
        // The payment was sent, so start the spinner to show the user it's in progress
        setPaymentPending(true);
    }, [paymentPending]);

    const onPaymentFailed = useCallback(() => {
        // The payment failed for some reason
        setError(t('giftCards:finalize.paymentFailure'));
        setPaymentPending(false);
    }, []);

    const onPaymentError = useCallback((error?: any) => {
        setError(t('global:genericError'));
        setPaymentPending(false);
    }, []);

    const startFirebaseListener = (invoiceId?: string) => {
        if (!invoiceId || !authStore.firebaseUser?.uid) {
            return;
        }

        unsub = createAsyncPaymentListener({
            invoiceId,
            userId: authStore.firebaseUser.uid,
            onPaymentSuccess,
            onPaymentPending,
            onPaymentFailed,
            onPaymentError
        })
    }


    const modalRef = useCallback((modalRef: HTMLDivElement) => {
        if (modalRef && invoice) {
            const options = { ...DEFAULT_QR_OPTIONS, text: `lightning:${invoice}` }
            if (modalRef.children.length > 0) {
                modalRef.removeChild(modalRef.children[0]); //clear out any QR codes that may exist already
            }
            new QRCode(modalRef, options);
        }
    }, [invoice]);

    const copyInvoiceId = () => {
        navigator.clipboard.writeText(invoice).then(() => {
            commonStore.success(t('giftCards:lightning.copySuccess'));
        }, () => {
            commonStore.success(t('giftCards:lightning.copyFailure'));
        })
    }

    useEffect(() => {
        (async () => {
            await initLightning();
        })();

        return (() => {
            if (unsub) {
                unsub();
            }

            if (lightningRefreshTimer) {
                clearTimeout(lightningRefreshTimer);
            }
        })
    }, [])

    return (
        <Wrapper>
            {!loading && error && <Alert severity="error">{error}</Alert>}
            {loading &&
                <Box display="flex" width="100%" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            }
            {!loading && !error &&
                <Box sx={{ padding: '18px', margin: '0 auto', display: loading ? 'none' : 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                    <LoadingBackdrop loading={paymentPending} />
                    {
                        manuallyReloadInvoice.current ?
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Typography mb={1} fontWeight="bold" fontSize="20px">{t('giftCards:finalize.manuallyRefreshTitle')}</Typography>
                                <Typography variant="body2">{t('giftCards:finalize.manuallyRefreshWarning')}</Typography>
                                <Box margin="10px 0px">
                                    <Alert severity="warning">{t('giftCards:finalize.manuallyRefreshSentPaymentWarning')}</Alert>
                                </Box>
                                <ActionButton disabled={paymentPending || manuallyLoadingInvoice} loading={manuallyLoadingInvoice} onClick={refreshInvoice}>{t('giftCards:finalize.manuallyReload')}</ActionButton>
                            </Box>
                            :
                            <>
                                <Box mb={1} display={{ xs: 'none', sm: 'block', }}>
                                    <QrWrapper ref={modalRef} sx={{ minHeight: !!invoice ? '230px' : '0px' }}></QrWrapper>
                                </Box>
                                {
                                    invoice ?
                                        <>
                                            <Box display={{ xs: 'flex', sm: 'none' }} sx={{ width: '100%', flexDirection: 'column' }}>
                                                <LightningInvoiceCopy
                                                    copyInvoiceId={copyInvoiceId}
                                                    invoice={invoice}
                                                />
                                            </Box>
                                            <Box display={{ xs: 'flex', sm: 'none' }} justifyContent="center" marginTop="10px" width="100%">
                                                <ActionButton onClick={handleQrModalOpen}>{t('giftCards:finalize.showQr')}</ActionButton>
                                            </Box>
                                            {<Box justifyContent="center" marginTop="10px" width="100%">
                                                <ActionButton onClick={openWalletApp}>{t('giftCards:finalize.openWalletApp')}</ActionButton>
                                            </Box>
                                            }
                                            <Box display={{ xs: 'none', sm: 'flex' }} justifyContent="center" marginTop="18px" width="100%">
                                                <ActionButton onClick={handleCopyModalOpen}>{t('giftCards:finalize.showInvoice')}</ActionButton>
                                            </Box>
                                        </>
                                        :
                                        /* 
                                          If we're here, the user doesn't have anything to pay - there is no invoice 
                                          Their balance is covered, so we'll just have to wait for the backend to add the gift card to the firebase collection
                                        */
                                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" padding="10px 4px">
                                            {/* Don't show the progress indicator if paymentPending is triggered, which means the 
                                            payment was successful and we're waiting to be redirected. This is so we don't have double
                                            progress indicators */}
                                            <Box visibility={paymentPending ? 'hidden' : 'visible'}>
                                                <CircularProgress />
                                            </Box>
                                            <Typography fontWeight="bold" marginTop="10px">{t("giftCards:finalize.inProgress")}</Typography>
                                        </Box>
                                }
                            </>
                    }
                </Box>
            }
            <Dialog
                open={qrModalOpen && !paymentPending}
                onClose={handleQrModalClose}
                sx={{ width: '100%' }}
                PaperProps={{ sx: { width: '100%', background: 'common.darkgrey', borderRadius: '20px' } }}
            >
                <ModalWrapper>
                    <ModalBody>
                        <QrWrapper ref={modalRef} sx={{ minHeight: !!invoice ? '230px' : '0px' }}></QrWrapper>
                        <ActionButton sx={{ marginTop: '16px' }} onClick={handleQrModalClose}>{t('global:close')}</ActionButton>
                    </ModalBody>
                </ModalWrapper>
            </Dialog>
            <Dialog
                open={copyModalOpen && !paymentPending}
                onClose={handleCopyModalClose}
                PaperProps={{ sx: { width: '100%', backgroundImage: 'none', background: 'common.darkgrey', borderRadius: '20px' } }}
            >
                <ModalWrapper>
                    <ModalBody>
                        <LightningInvoiceCopy
                            copyInvoiceId={copyInvoiceId}
                            invoice={invoice}
                        />
                        <ActionButton sx={{ marginTop: '16px', backgroundColor: 'gray', color: 'white' }} onClick={handleCopyModalClose}>{t('global:close')}</ActionButton>
                    </ModalBody>
                </ModalWrapper>
            </Dialog>
        </Wrapper >
    )
}

export default observer(Lightning);
