import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../SparkFirebase";
import RestService from './RestService';

export default class MerchantService extends RestService {
    constructor() {
        super(`/api/v1/giftcards`);
    }

    // Merchants
    async getMerchants(isPublic: boolean = false, country = 'US') {
        return this.get(`${this.baseUrl}/merchants${isPublic ? '/public' : ''}?country=${country}`);
    }

    async getFeaturedMerchants() {
        const bannerMerchantsRef = await doc(db, 'giftcardsbanners', 'main');
        const snapshot = await getDoc(bannerMerchantsRef);
        if (snapshot.exists()) {
            return snapshot.data();
        }
        return null;
    }

    async getMerchantInfo(merchantId: string, isPublic: boolean = false) {
        return this.get(`${this.baseUrl}/merchant/${merchantId}${isPublic ? '/public' : ''}`);
    }

    // Checkout
    async getCheckoutToken(merchantID: string, eCodeID: string, amount: string, applySparks: boolean = false) {
        return this.post(`${this.baseUrl}/checkout-token`, {
            merchantID,
            eCodeID,
            amount,
            applySparks
        })
    }

    /* Lightning */
    async lightningCheckout(checkoutToken: string) {
        return this.post(`${this.baseUrl}/checkout/lightning`, { checkoutToken })
    }

    async lightningCheckoutRefresh(checkoutToken: string) {
        return this.post(`${this.baseUrl}/checkout/lightning/refresh`, { checkoutToken })
    }

    async getPaymentMethods() {
        const paymentMethodsRef = await getDocs(collection(db, "lightningwallets"));
        if (paymentMethodsRef.empty) {
            return [];
        }
        return paymentMethodsRef.docs.map(doc => doc.data());
    }

    /* Spark Cash Checkout */
    async sparkCashCheckout(checkoutToken: string) {
        return this.post(`${this.baseUrl}/checkout/sparkcash`, { checkoutToken })
    }

    /* Cash Checkout */
    async cashCheckout(checkoutToken: string) {
        return this.post(`${this.baseUrl}/checkout/cash`, { checkoutToken })
    }

    /* Gift Cards */
    async getMyGiftCards(status: string) {
        return this.get(`${this.baseUrl}/cards/${status}`);
    }

    async markCard(giftCardID: string, status: 'active' | 'archived') {
        return this.put(`${this.baseUrl}/cards/${status}/gift-id/${giftCardID}`, {});
    }
}
