import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, Navigate } from 'react-router';
import { useStores } from '../hooks/use-stores';
import { RESTRICTED_USER_ALLOWED_ROUTES } from '../stores/authStore';

function RequireAuth({
    children,
    notLoggedUrl,
}: {
    children: JSX.Element;
    notLoggedUrl?: string;
}) {
    const { authStore } = useStores();
    const location = useLocation();
    if (!notLoggedUrl) notLoggedUrl = authStore.loginUrl;

    // If we're on a valid parent path (used for redirects) or we match an allowed path for restricted users
    const isValidPathForRestrictedUser = location.pathname.replace(/\/+$/, '') == RESTRICTED_USER_ALLOWED_ROUTES.parent ||
        RESTRICTED_USER_ALLOWED_ROUTES.paths.some(route => location.pathname.includes(route));

    // We are a restricted user that attempts to access a route we have no permission for
    const restrictedUserHasNoPermissions =
        authStore.isRestrictedUser && !isValidPathForRestrictedUser

    // If the user is not logged in or they are a restricted user attempting to access a route they
    // cannot access, then we take them to the sign in page 
    if (!authStore.isAuthenticated || restrictedUserHasNoPermissions) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.

        return (
            <Navigate to={notLoggedUrl} state={{ from: location }} replace />
        );
    }

    return children;
}

export default observer(RequireAuth);
