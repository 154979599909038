import React, { useCallback, useState } from 'react';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import RoundTextField from './RoundTextField'

const ShowHidePasswordTextField = (props: TextFieldProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const togglePasswordVisibility = useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    return (
        <RoundTextField
            {...props}
            name="password"
            type={showPassword ? 'text' : "password"}
            InputProps={{
                endAdornment: <InputAdornment sx={{ cursor: 'pointer' }} position="end" onClick={togglePasswordVisibility}>
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>,
            }}
        />
    )
}

export default ShowHidePasswordTextField;