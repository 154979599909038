import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { styled } from '@mui/material/styles';
import { Avatar, Box, Checkbox, Container, Dialog, Divider, Grid, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GiftCardImage from '../components/GiftCardImage';
import PaymentMethods from './components/PaymentMethods';
import { useStores } from '../../../hooks/use-stores';
import { currencyFormatter } from '../../../utils/helpers';
import SparkButton from '../../../components/SparkButton';
import BackToMerchantsButton from '../components/BackToMerchantsButton';
import PaymentMethod from '../../../stores/models/PaymentMethod';
import { SPARK_CASH, SPARK_CASH_PAYMENT_METHOD, SPARK_WALLET_PAYMENT_METHOD } from '../../../constants';
import IOSSwitch from '../../../components/IOSSwitch';

const CheckoutWrapper = styled(Container)(({ theme }) => ({
    gridGap: '20px',
    margin: '0 auto',
    padding: '0px 14px',
    [theme.breakpoints.up('xs')]: {
        maxWidth: '500px',
    },
}));

const MerchantLogo = styled(Avatar)(({ theme }) => ({
    width: 24,
    height: 24,
    marginRight: 4,
    [theme.breakpoints.up('sm')]: {
        width: 36,
        height: 36
    },
}));

// Todo cleanup the boxes - they're a mess
const Checkout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { commonStore, giftcardStore, walletStore } = useStores();

    const {
        selectedMerchantInfo
    } = giftcardStore;

    let unsub: (() => void) | null = null;

    const [loading, setLoading] = useState<boolean>(true);
    const [paymentMethodsLoaded, setPaymentMethodsLoaded] = useState<boolean>(giftcardStore.paymentMethods.length > 0);
    const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState<boolean>(false);
    // const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);
    // const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    const goHome = useCallback(() => {
        navigate('/giftcards/shop');
    }, []);

    const paymentMethods = useMemo((): Array<PaymentMethod> => {
        return [
            // Hide Spark Cash for now
            // SPARK_CASH_PAYMENT_METHOD(walletStore.balances?.sparkCash || 0),
            SPARK_WALLET_PAYMENT_METHOD,
            ...giftcardStore.paymentMethods
        ].sort((a, b) => a.rank > b.rank ? 1 : (a.rank == b.rank ? 0 : -1))
    }, [giftcardStore.paymentMethods, walletStore.balances]);

    const handlePaymentModalOpen = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setPaymentMethodModalOpen(true);
    }, [paymentMethodModalOpen]);

    const handlePaymentModalClose = useCallback(() => {
        setPaymentMethodModalOpen(false);
    }, [paymentMethodModalOpen]);

    // const handleTermsModalOpen = useCallback((e: React.MouseEvent) => {
    //     e.preventDefault();
    //     setTermsModalOpen(true);
    // }, [termsModalOpen]);

    // const handlTermsModalClose = useCallback(() => {
    //     setTermsModalOpen(false);
    // }, [termsModalOpen]);

    const onSelectPaymentMethod = useCallback((name: PaymentMethod) => {
        giftcardStore.selectedPaymentMethod = name;

        handlePaymentModalClose()
    }, []);

    const finalOrderTotalString = () => {
        const { money, sparks } = giftcardStore.finalOrderTotals;

        const orderHasMoneyValue = money && money !== '0';
        const orderHasSparksValue = sparks && sparks !== '0';

        if (orderHasMoneyValue && orderHasSparksValue) {
            return t('giftCards:checkout.paySparksAndMoney', { sparks, money: currencyFormatter(money) });
        } else if (orderHasSparksValue) {
            return t('giftCards:checkout.paySparks', { sparks });
        } else {
            return t('giftCards:checkout.payMoney', { money: currencyFormatter(money) });
        }
    }

    const paymentMethodInfo = useMemo(() => {
        if (paymentMethods) {
            return paymentMethods.find((method: PaymentMethod) => method.name == giftcardStore.selectedPaymentMethod.name) || paymentMethods[0]
        }
    }, [paymentMethods, giftcardStore.selectedPaymentMethod]);

    const sendPayment = async () => {
        // Ensure that the user's Spark Cash balance is sufficient for purchasing a card
        if (giftcardStore.selectedPaymentMethod.name == SPARK_CASH) {
            const sparkCashTotal = walletStore.balances?.sparkCash || 0;
            const orderTotal = Number(giftcardStore.orderTotal);

            if (sparkCashTotal < orderTotal) {
                commonStore.warn(t('giftCards:checkout.insufficientBalance'));
                return;
            }
        }
        setLoading(true);
        await giftcardStore.getCheckoutToken();

        if (giftcardStore.error) {
            if (giftcardStore.error.includes('denomination')) {
                commonStore.warn(t('giftCards:checkout.invalidDenomination'))
            } else {
                commonStore.warn(t('global:networkError'))
            }
            setLoading(false);
        } else {
            navigate('/giftcards/finish');
        }
    }

    const load = async () => {
        let promises = [walletStore.loadBalances()];

        if (!paymentMethodsLoaded) {
            promises.push(giftcardStore.loadPaymentMethods());
        }

        await Promise.all(promises);

        if (!paymentMethodsLoaded) {
            setPaymentMethodsLoaded(true);
        }

        setLoading(false);
    }

    useEffect(() => {
        load();

        if (!giftcardStore.selectedMerchantId) {
            goHome();
        }

        return (() => {
            if (unsub) {
                unsub();
            }
        })
    }, [])

    if (!giftcardStore.selectedMerchantId) {
        return null;
    }

    return (
        <CheckoutWrapper>
            <Box>
                <Grid container alignItems={'center'}>
                    <Grid item xs={12}>
                        <BackToMerchantsButton />
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <GiftCardImage
                    backgroundColor={selectedMerchantInfo?.backgroundColor}
                    fontColor={selectedMerchantInfo?.fontColor}
                    logo={selectedMerchantInfo?.logoImageUrl || ''}
                    currency={giftcardStore.selectedMerchantCurrency}
                    amount={currencyFormatter(giftcardStore.orderTotal)}
                    useGiftCardNumber
                    giftCardNumber={''}
                />
                <Box display="flex" justifyContent="center" alignItems="center" marginTop="14px">
                    <Typography textTransform="uppercase" fontSize="16px" color="gray">{t('giftCards:transactionInfo.earn')}</Typography>
                    <Box borderRadius="10px" display="inline" border="2px solid green" padding="5px 10px" color="green" margin="0px 10px" fontSize="18px" fontWeight="bold">
                        {String(giftcardStore.calculateEarnings(Math.round(walletStore.balances?.sparks!)))}
                    </Box>
                    <Typography textTransform="uppercase" fontSize="16px" color="gray">{t('global:sparks')}</Typography>
                </Box>
            </Box>
            <Box>
                <Paper sx={{ padding: '20px', background: 'transparent', borderBottom: '1px solid #222222' }}>
                    <Box display="flex" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginRight: '14px' }}>
                                <Typography variant="body2" fontSize="16px" fontWeight="bolder">{t('giftCards:checkout.applyRewards')}</Typography>
                                <Typography variant="body2" fontSize="12px">{t('giftCards:checkout.sparksAvailable', { amount: walletStore.balances?.sparks ? Math.round(walletStore.balances?.sparks) : 0 })}</Typography>
                            </Box>
                        </Box>
                        <IOSSwitch disabled={walletStore.balances?.sparks == 0} value={giftcardStore.applySparks} onClick={() => giftcardStore.applySparks = !giftcardStore.applySparks} />
                    </Box>
                </Paper>
                <Paper sx={{ cursor: 'pointer', padding: '20px', background: 'transparent', borderBottom: '1px solid #222222' }}>
                    <Box display="flex" sx={{ alignItems: "center", justifyContent: "space-between" }} onClick={handlePaymentModalOpen}>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', marginRight: '14px' }}>
                                <Typography variant="body2" fontSize="16px" fontWeight="bolder">{t('giftCards:checkout.paymentMethod')}</Typography>
                                <a className="sparkLink" style={{ fontSize: "14px", fontWeight: "bold" }}>{t('giftCards:checkout.change')}</a>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            {paymentMethodsLoaded ?
                                <>
                                    <MerchantLogo src={paymentMethodInfo?.logo} />
                                    <Typography fontWeight="bold">{paymentMethodInfo?.name}</Typography>
                                </> : <span>...</span>
                            }
                        </Box>
                    </Box>
                </Paper>
                <Paper sx={{ padding: '20px', background: 'transparent' }}>
                    <Box display="flex" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                        <Typography variant="body2" fontSize="16px" fontWeight="bolder">{t('giftCards:checkout.total')}</Typography>
                        <Box sx={{ background: "#101419", padding: '9px', borderRadius: '10px' }}>
                            <Typography variant="body2" fontSize="18px" fontWeight="bold">{currencyFormatter(giftcardStore.finalOrderTotals.money)}</Typography>
                        </Box>
                    </Box>
                </Paper>
            </Box>
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center', marginTop: '10px' }}>
                <Box display="flex" alignItems="center" pl={1} pr={1}>
                    <Box sx={{ paddingBottom: '1px' }}>
                        <Checkbox value={termsAccepted} onClick={() => setTermsAccepted(!termsAccepted)} />
                    </Box>
                    <Box width="100%">
                        <Trans i18nKey="giftCards:checkout.agreement">
                            <Typography fontSize="14px" display="inline" variant="body2" sx={{ color: 'gray' }}>By proceeding, you agree to these</Typography> <a style={{ fontSize: '14px' }} className="sparkLink" href="#" onClick={handleTermsModalOpen}>Terms and Conditions</a>
                        </Trans>
                    </Box>
                </Box>
            </Box> */}
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '32px', gap: .5 }}>
                <Typography textAlign="center" fontSize="14px" fontWeight="bold" color="red">{t('giftCards:checkout.refundWarning')}</Typography>
                <SparkButton loading={loading} sx={{ alignSelf: 'flex-end' }} disabled={!giftcardStore.selectedPaymentMethod || loading} onClick={sendPayment}>
                    {finalOrderTotalString()}
                </SparkButton>
            </Box>
            <Dialog
                open={paymentMethodModalOpen}
                onClose={handlePaymentModalClose}
                sx={{ maxHeight: 'calc(100% - 75px)' }}
                PaperProps={{
                    sx: {
                        paddingBottom: '10px',
                        width: '100%',
                        backgroundColor: 'common.darkgrey',
                        height: 'min-content',
                        borderRadius: '16px',
                        backgroundImage: 'none'
                    }
                }}
            >
                <Toolbar sx={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Typography sx={{ ml: 0, flex: 1, fontWeight: 'bold' }} fontSize={"16px"} component="div">
                        {t('giftCards:checkout.selectPaymentMethod')}
                    </Typography>
                    <IconButton
                        sx={{ marginLeft: 1 }}
                        edge="start"
                        color="inherit"
                        onClick={handlePaymentModalClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <PaymentMethods
                    paymentMethods={paymentMethods}
                    onSelectPaymentMethod={onSelectPaymentMethod}
                />
            </Dialog>
            {/* <Dialog
                open={termsModalOpen}
                onClose={handlTermsModalClose}
                PaperProps={{
                    sx: {
                        backgroundColor: 'common.darkgrey',
                        paddingBottom: '10px',
                        borderRadius: '16px',
                        backgroundImage: 'none'
                    }
                }}
            >
                <Toolbar sx={{ padding: 0, paddingTop: 2, }}>
                    <Typography sx={{ ml: 2, flex: 1, fontWeight: 'bold' }} variant="h5" component="div">
                        {t('global:termsAndConditions')}
                    </Typography>
                    <IconButton
                        sx={{ marginRight: 2, marginLeft: 1 }}
                        edge="start"
                        color="inherit"
                        onClick={handlTermsModalClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <Box
                    sx={{ padding: 2 }}
                >
                    TBD LOREM IPSUM TERMS AND CONDITIONS
                </Box>
            </Dialog> */}
        </CheckoutWrapper >
    );
}

export default observer(Checkout);