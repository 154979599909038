/* What is returned by the Merchants api - the list of merchants */
export class Merchant {
    public name: string;
    public slug: string;
    public categories: string[];
    public id: string;
    public backgroundColor: string;

    public fontColor: string;
    public cardImageUrl: string;
    public logoImageUrl: string;
    public currencyCode: string;
    public availableCountries: string[];
    public outOfStock: boolean;
    public vendor: string;
    public testVendor?: string;
    public cashbackPercentage: string;

    constructor(props: any) {
        this.name = props.name;
        this.slug = props.slug;
        this.categories = props.categories;
        this.id = props.id;
        this.backgroundColor = props.backgroundColor;
        this.fontColor = props.fontColor;
        this.cardImageUrl = props.cardImageUrl;
        this.logoImageUrl = props.logoImageUrl;
        this.currencyCode = props.currencyCode;
        this.availableCountries = props.availableCountries;
        this.outOfStock = props.outOfStock;
        this.vendor = props.vendor;
        this.testVendor = props.testVendor;
        this.cashbackPercentage = props.cashbackPercentage;
    }
}

export class ECodeInfo {
    public id: string;
    public cashbackPercentage: string;
    public hasRange: boolean;
    public staticPrices: string[];
    public maximumValue: string;
    public minimumValue: string;
    public description: string;
    public termsAndConditionsHtml: string;
    public redeemInstructionsHtml: string;

    constructor(props: any) {
        this.id = props.id;
        this.cashbackPercentage = props.cashbackPercentage;
        this.hasRange = props.hasRange;;
        this.staticPrices = props.staticPrices;
        this.maximumValue = props.maximumValue;
        this.minimumValue = props.minimumValue;
        this.description = props.description;
        this.termsAndConditionsHtml = props.termsAndConditionsHtml;
        this.redeemInstructionsHtml = props.redeemInstructionsHtml;
    }
}

/* Part of what's returned by the merchant/id API - specific info about the merchant */
export class MerchantInfo {
    public name: string;
    public slug: string
    public categories: string[];
    public id: string;
    public backgroundColor: string;
    public fontColor: string;
    public cardImageUrl: string
    public logoImageUrl: string;
    public countryCode: string;
    public availableCountries: string[];
    public outOfStock: boolean;

    public eCodeInfo: ECodeInfo;

    constructor(merchantInfo: any, eCodeInfo: any) {
        this.id = merchantInfo.id;

        this.name = merchantInfo.name;

        this.backgroundColor = merchantInfo.backgroundColor;
        this.fontColor = merchantInfo.fontColor;

        this.slug = merchantInfo.slug;
        this.categories = merchantInfo.categories;
        this.cardImageUrl = merchantInfo.cardImageUrl;
        this.logoImageUrl = merchantInfo.logoImageUrl;

        this.countryCode = merchantInfo.countryCode;
        this.availableCountries = merchantInfo.availableCountries;
        this.outOfStock = merchantInfo.outOfStock;

        this.eCodeInfo = eCodeInfo;
    }
}

