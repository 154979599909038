import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from '../../../../SparkFirebase'
import PurchasedCard from '../../../../stores/models/PurchasedCard'

const PAYMENT_COLLECTION = 'giftcards';

interface AsyncPaymentParams {
    invoiceId: string;
    userId: string;
    onPaymentSuccess: (purchasedCard: PurchasedCard) => void;
    onPaymentPending: () => void;
    onPaymentFailed: () => void;
    onPaymentError: (error?: any) => void;
}

const createAsyncPaymentListener = (paymentParams: AsyncPaymentParams) => {
    const { invoiceId, userId, onPaymentSuccess, onPaymentPending, onPaymentFailed, onPaymentError } = paymentParams;

    if (!invoiceId || !userId || !onPaymentSuccess || !onPaymentPending || !onPaymentFailed || !onPaymentError) {
        return null;
    }

    const q = query(
        collection(db, PAYMENT_COLLECTION),
        where('__name__', "==", invoiceId),
        where("userID", "==", userId)
    );

    return onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((d) => {
            const documentData = d.data() as PurchasedCard;

            if (documentData) {
                const documentData = d.data() as PurchasedCard;
                if (documentData.status === 'PAID') {
                    onPaymentSuccess(documentData as PurchasedCard)
                } else if (documentData.status === 'PENDING') {
                    // The payment was sent, so start the spinner to show the user it's in progress
                    onPaymentPending();
                } else if (documentData.status === 'FAILED' || documentData.status === 'CANCELLED') {
                    onPaymentFailed();
                }
            }
        })
    }, (error) => {
        console.error(error);
        onPaymentError(error);
    });
}

export { createAsyncPaymentListener }