import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ArrowBackIosOutlined, } from '@mui/icons-material';
import { Alert, Box, Container, IconButton, Typography, useTheme } from '@mui/material';
import SparkButton, { BlueSparkButton } from './SparkButton';
import ThirdPartyAuthentication from './ThirdPartyAuthentication';
import { isValidEmail } from '../utils/helpers';
import ShowHidePasswordTextField from './ShowHidePasswordTextfield';
import RoundTextField from './RoundTextField';
import { PASSWORD_MIN_LENGTH } from '../constants';
import FullPageLoader from '../pages/Giftcards/components/FullPageLoader';
import { BottomContainer } from './TopBottomLayout';
import { useStores } from '../hooks/use-stores';

interface SigninFormProps {
    onSuccessfulAuth: () => void;
    onClose?: () => void;
}

const NewSigninForm = (props: SigninFormProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { authStore } = useStores();
    const navigate = useNavigate();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);

    const signInEmailPassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let canContinue = true;

        if (!email || !isValidEmail(email)) {
            setEmailError(true);
            canContinue = false;
        }

        if (!password || password.length < PASSWORD_MIN_LENGTH) {
            setPasswordError(true);
            canContinue = false;
        }

        if (canContinue) {
            const success = await authStore.signinWithEmailPassword(email, password);

            if (success && authStore.isAccountComplete) {
                props.onSuccessfulAuth();
            }
        }
    }

    const onChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (emailError) {
            setEmailError(false);
        }

        setEmail(event.target.value);
    }, [emailError, email])

    const onChangePassword = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (passwordError) {
            setPasswordError(false);
        }

        setPassword(event.target.value);
    }, [passwordError, password])

    const goToAccountCreation = useCallback(() => {
        navigate('/createAccount');
    }, []);

    const errorMessage = useMemo(() => {
        // Firebase errors start with auth/
        if (authStore.signinError?.includes('auth/')) {
            return t('auth:login.invalidCredentials');
        }
        return t('global:genericError');
    }, [authStore.signinError]);

    return (
        (authStore.creatingAccount) ? <FullPageLoader /> :
            <Container sx={{ pl: 3, pr: 3, height: '100%' }} maxWidth="sm" disableGutters>
                <Box
                    sx={{
                        minHeight: '750px',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative'
                    }}
                >
                    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="75px">
                        <Box position="absolute" left={0}>
                            {props.onClose &&
                                <IconButton color="inherit" onClick={props.onClose}>
                                    <ArrowBackIosOutlined sx={{ fontSize: '14px' }} />
                                </IconButton>
                            }
                        </Box>
                        <Box>
                            <Typography component="h1" variant="body1" fontWeight="bold" textTransform="uppercase" color="white">
                                {t('createAccount:login')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ visibility: authStore.signinError ? 'visible' : 'hidden' }} mb={1}>
                        <Alert severity="error">{errorMessage || ' '}</Alert>
                    </Box>
                    <Box component="form" onSubmit={signInEmailPassword} noValidate sx={{ mt: 1 }}>
                        <RoundTextField
                            autoFocus
                            size="small"
                            sx={{ margin: '4px 0px' }}
                            value={email}
                            onChange={onChangeEmail}
                            margin="none"
                            required
                            fullWidth
                            label={t('createAccount:email')}
                            autoComplete="email"
                            error={emailError}
                            helperText={emailError ? t('createAccount:emailRequired') : ' '}
                        />
                        <ShowHidePasswordTextField
                            size="small"
                            sx={{ margin: '4px 0px' }}
                            value={password}
                            onChange={onChangePassword}
                            margin="none"
                            required
                            fullWidth
                            label={t('createAccount:password')}
                            autoComplete="current-password"
                            error={passwordError}
                            helperText={passwordError ? t('createAccount:passwordRequired') : ' '}
                        />
                        <Link to="/forgotpassword" style={{ color: theme.palette.primary.main, fontSize: '15px', fontWeight: 'bold', textDecoration: 'none' }}>
                            {t('createAccount:forgotPassword')}
                        </Link>
                        <SparkButton
                            type="submit"
                            sx={{ mt: 3, mb: 2 }}
                            loading={authStore.signingIn}
                        >
                            <Typography fontWeight="bold">{t('createAccount:login')}</Typography>
                        </SparkButton>
                        <ThirdPartyAuthentication onSuccessfulAuth={props.onSuccessfulAuth} signIn />
                        <BottomContainer>
                            <Typography textAlign="center" variant="body2" mb={1} color="white">{t('createAccount:newUser')}</Typography>
                            <BlueSparkButton onClick={goToAccountCreation}>{t('createAccount:createNewAccount')}</BlueSparkButton>
                        </BottomContainer>
                    </Box>
                </Box>
            </Container>
    )
}

export default observer(NewSigninForm);