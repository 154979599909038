import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Theme, Typography, useTheme } from '@mui/material';
import SparkButton from '../../components/SparkButton';
import { useNavigate } from 'react-router-dom';
import { BottomContainer, MainContainer } from '../../components/TopBottomLayout';
import HeaderText from './components/HeaderText';
import RoundTextField from '../../components/RoundTextField';
import ShowHidePasswordTextField from '../../components/ShowHidePasswordTextfield';
import { isValidEmail } from '../../utils/helpers';
import { PASSWORD_MIN_LENGTH } from '../../constants';
import { useStores } from '../../hooks/use-stores';
import { useMemo } from 'react';

enum PasswordStrength {
    WEAK,
    FAIR,
    GOOD,
    STRONG
}

const PasswordData = (theme: Theme) => {
    return {
        [PasswordStrength.WEAK]: {
            message: 'createAccount:passwordStrength.weak',
            color: theme.palette.error.main
        },
        [PasswordStrength.FAIR]: {
            message: 'createAccount:passwordStrength.fair',
            color: theme.palette.warning.main
        },
        [PasswordStrength.GOOD]: {
            message: 'createAccount:passwordStrength.good',
            color: theme.palette.success.main
        },
        [PasswordStrength.STRONG]: {
            message: 'createAccount:passwordStrength.strong',
            color: theme.palette.success.main
        },
    }
}

const EmailSignup = () => {
    const { authStore, commonStore } = useStores();

    const theme = useTheme();
    const { t } = useTranslation();

    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [termsAcceptedError, setTermsAcceptedError] = useState<boolean>(false);
    const [passwordStrength, setPasswordStrength] = useState<PasswordStrength>(PasswordStrength.WEAK);

    const updateTermsAcceptance = useCallback(() => {
        if (termsAcceptedError) {
            setTermsAcceptedError(false);
        }

        setTermsAccepted(!termsAccepted)
    }, [termsAccepted, termsAcceptedError])

    const handleEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (emailError) {
            setEmailError(false);
        }

        setEmail(e.target.value);
    }, [email, emailError])

    const handlePasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (passwordError) {
            setPasswordError(false);
        }

        setPassword(e.target.value);
    }, [password, passwordError])

    const submitForm = async () => {
        let canContinue = true;

        if (!email || !isValidEmail(email)) {
            setEmailError(true);
            canContinue = false;
        }
        if (!password || password.length < PASSWORD_MIN_LENGTH) {
            setPasswordError(true)
            canContinue = false;
        }
        if (!termsAccepted) {
            setTermsAcceptedError(true);
            canContinue = false;
        }
        if (canContinue) {
            // User has passed all checks - sign them up with email and password
            // This can fail if the user has already signed up or their email address
            // is invalid. Setting of error states are handled in the store
            await authStore.signupWithEmailAndPassword(email, password);
        }
    }

    useEffect(() => {
        import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn').then(zxcvbn => {
            //@ts-ignore
            let strength = zxcvbn.default(password);
            let score = strength.score;

            let passStrength;
            switch (score) {
                case 2:
                    passStrength = PasswordStrength.FAIR;
                    break;
                case 3:
                    passStrength = PasswordStrength.GOOD;
                    break;
                case 4:
                    passStrength = PasswordStrength.STRONG;
                    break;
                case 0:
                case 1:
                default:
                    passStrength = PasswordStrength.WEAK;
            }

            setPasswordStrength(passStrength);
        });
    }, [password]);

    useEffect(() => {
        if (authStore.signupError) {
            let warningString = t('createAccount:genericFailure');
            if (authStore.signupError.includes('auth/email-already-in-use')) {
                warningString = t('createAccount:emailExists');
            } else if (authStore.signupError.includes('auth/invalid-password')) {
                warningString = t('createAccount:invalidPassword');
            } else if (authStore.signupError == 'invalidemail') {
                warningString = t('createAccount:invalidEmail');
            }

            commonStore.showError(warningString);
        }
    }, [authStore.signupError])

    useEffect(() => {
        return () => {
            authStore.signupError = null;
        }
    }, [])

    return (
        <MainContainer minHeight="550px">
            <HeaderText
                title={t('createAccount:emailSignup.title')}
                description={t('createAccount:emailSignup.description')}
            />
            <Box sx={{ mt: 1 }}>
                <RoundTextField
                    size="small"
                    InputProps={{ sx: { fontWeight: 'bold' } }}
                    value={email}
                    onChange={handleEmailChange}
                    margin="normal"
                    required
                    fullWidth
                    label={t('createAccount:email')}
                    type="email"
                    autoComplete="email"
                    error={emailError}
                />
                <ShowHidePasswordTextField
                    size="small"
                    InputProps={{ sx: { color: "#7c8b9e" } }}
                    value={password}
                    onChange={handlePasswordChange}
                    margin="normal"
                    required
                    fullWidth
                    label={t('createAccount:password')}
                    autoComplete="off"
                    error={passwordError}
                    helperText={<Trans i18nKey="userMessagesUnread">
                        <Typography variant="caption" color="common.bluegray">
                            {t('createAccount:emailSignup.passwordStrength')}{' '}
                            <Box component="span" display="inline" color={PasswordData(theme)[passwordStrength].color}>
                                <b>{t(PasswordData(theme)[passwordStrength].message)}</b>
                            </Box>
                        </Typography>
                    </Trans>
                    }
                />
            </Box>
            <BottomContainer>
                <FormControl
                    required
                    error={termsAcceptedError}
                    component="fieldset"
                    variant="standard"
                    sx={{ mb: 1 }}
                >
                    <FormGroup>
                        <FormControlLabel
                            sx={{
                                pl: 2,
                                pr: 2,
                                mr: 0
                            }}
                            control={
                                <Checkbox value={termsAccepted} onClick={updateTermsAcceptance} />
                            }
                            label={
                                <Box width="100%">
                                    <Typography color="common.bluegray" fontSize="14px" dangerouslySetInnerHTML={{ __html: t('createAccount:emailSignup.acceptTerms') }} />
                                </Box>
                            }
                        />
                    </FormGroup>
                    <FormHelperText sx={{ textAlign: 'center', visibility: !termsAcceptedError ? 'hidden' : 'initial' }}>
                        {t('createAccount:agreeRequirement')}
                    </FormHelperText>
                </FormControl>
                <SparkButton onClick={submitForm} disabled={authStore.signingIn || authStore.creatingAccount} loading={authStore.signingIn || authStore.creatingAccount}>
                    {t('createAccount:emailSignup.createAccount')}
                </SparkButton>
            </BottomContainer>
        </MainContainer >
    )
}

export default observer(EmailSignup);