import React, { ChangeEvent, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { NumericFormat } from "react-number-format";
import { Box, InputAdornment, InputBaseComponentProps, TextField } from "@mui/material";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    max: string;
    name: string;
}

const SimpleCurrency = React.forwardRef<InputBaseComponentProps | undefined, CustomProps>(
    function SimpleCurrency(props, ref) {
        const { max, onChange, ...other } = props;

        return (
            <NumericFormat
                max={max}
                min={0}
                {...other}
                getInputRef={ref}
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator={true}
                onFocus={(event: any) => {
                    // If we're clicking this the first time or when the value is 0, clear out the current
                    // value so we don't have to fudge with deleting it manually
                    if (event.target.value == '0.00' || event.target.value == '0') {
                        event.target.value = '';
                    }
                    // need click or weird things happen with tab index
                    event.target.click();
                }}
                onValueChange={value => {
                    onChange({
                        target: {
                            name: props.name,
                            value: value.value
                        }
                    });
                }}
                isAllowed={(values: any) => {
                    const { value } = values;
                    return value <= max;
                }}
            />
        );
    });

interface FormInputProps {
    value: string;
    max?: number;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
const FormInput = (props: FormInputProps) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '25px',
            marginBottom: '25px'
        }}>
            <TextField
                {...props}
                variant="outlined"
                sx={{ width: '100%' }}
                InputProps={{
                    inputComponent: SimpleCurrency as any,
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                inputProps={{ 
                    style: { textAlign: 'center', fontSize: '20px' }, 
                    inputMode: 'decimal',
                    max: props.max
                 }}
            />
        </Box>
    )
}

export default observer(FormInput);