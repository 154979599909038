import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyCUThJATfzI4-fyQkRLuwQ75LvXKtrIWvE',
    authDomain: 'getsparkwallet.firebaseapp.com',
    projectId: 'getsparkwallet',
    storageBucket: 'getsparkwallet.appspot.com',
    messagingSenderId: '598658886124',
    appId: '1:598658886124:web:5b000eb1fa9d8ff8c138c9',
    measurementId: 'G-FKGYE263MV',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const auth = getAuth(app);

export { db, auth };
