import React from 'react';
import { Box, Typography } from "@mui/material";
import MerchantImage from '../../shared/MerchantImage';

interface MerchantInfoProps {
    logo: string;
    backgroundColor: string;
    name: string;
    disableImagePadding?: boolean;
    rightSide?: () => JSX.Element;
}

const MerchantInfo = (props: MerchantInfoProps) => {
    return (
        <Box sx={{
            marginBottom: '12px'
        }}>
            <Box display="flex" alignItems="center">
                <MerchantImage
                    disableImagePadding={props.disableImagePadding}
                    imageUrl={props.logo}
                    backgroundColor={props.backgroundColor}
                />
                <Box display="flex" flexDirection="column" sx={{ paddingLeft: '6px', width: '100%' }}>
                    <>
                        <Typography variant="h6" noWrap sx={{
                            fontWeight: 'bold',
                            textAlign: 'left',
                            margin: 0,
                            fontSize: '.9rem',
                            paddingLeft: props.disableImagePadding ? '4px' : 'initial'
                        }}>{props.name}</Typography>
                        {props.rightSide ? props.rightSide() : null}
                    </>
                </Box>
            </Box>
        </Box>
    );
};

export default MerchantInfo;
