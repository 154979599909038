import IdLessRestService from './IdLessRestService';
import RestService from './RestService';
import BaseService from './BaseService';

class PlaidAccountService extends RestService {
    constructor(parentService: BaseService) {
        if (parentService) {
            super(`${parentService.baseUrl}/accounts`);
        } else throw new Error('Can be only nested service');
    }
}

export default class PlaidService extends IdLessRestService {
    constructor() {
        super(`/api/v1/plaid`);
    }

    fetchUpdateToken(updateAccount: string) {
        return this.get(`${this.baseUrl}/getLinkUpdateToken?accountId=${updateAccount}`)
    }

    attachAccounts(publicToken: string, metadata: any) {
        return this.post(`${this.baseUrl}/attachAccounts`, {
            publicToken,
            metadata,
        });
    }

    processPayment(accountId: string, amount: number) {
        return this.post(`${this.baseUrl}/processPayment`, {
            accountId,
            amount,
        });
    }

    unlinkAccount(accountId: string) {
        return this.del(`${this.baseUrl}/unlink/${accountId}`);
    }

    loadTransactions(status: string = 'all') {
        return this.get(`${this.baseUrl}/ach/transactions/${status}`)
    }

    Accounts() {
        return new PlaidAccountService(this);
    }
}
