import { styled, Typography } from '@mui/material';

interface GradientTextProps {
    gradientColor?: string;
    component?: any
}

const GradientText = styled(Typography, {
    shouldForwardProp: (props) => props != 'gradientColor'
})<GradientTextProps>(({ theme, gradientColor }) => ({
    background: gradientColor ? `-webkit-linear-gradient(${gradientColor})` : '-webkit-linear-gradient(45deg, #0077ea, #AFEEEE)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
}));

export default GradientText